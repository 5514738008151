import styled from "styled-components";
import { FLEX_ROW } from "ui-kit";

export const CardWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.primary.main};
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
`;
