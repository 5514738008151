export enum ModalVariants {
  discardChanges = "discardChanges",
  createOrganization = "createOrganization",
  createStructuralUnit = "createStructuralUnit",
  createStaffUnit = "createStaffUnit",
  createStaffMember = "createStaffMember",
  viewStaffMember = "viewStaffMember",
  viewStructuralUnit = "viewStructuralUnit",
  viewStaffUnit = "viewStaffUnit",
  confirmDisableStructuralUnit = "confirmDisableStructuralUnit",
  confirmDisableStaffUnit = "confirmDisableStaffUnit",
  confirmDisableStaffMember = "confirmDisableStaffMember",
  createStandardRoute = "createStandardRoute",
  changeDefaultRoute = "changeDefaultRoute",
  viewStandardRoute = "viewStandardRoute",
  confirmApprovalRoute = "confirmApprovalRoute",
  addApprover = "addApprover",
  contractApprovalActions = "contractApprovalActions",
  permissionsSet = "permissionsSet",
  editRole = "editRole",
  removePermissionsSet = "removePermissionsSet",
  removeRole = "removeRole",
  removeExecutor = "removeExecutor",
  removeUserRole = "removeUserRole",
  editUserRole = "editUserRole",
  attachLink = "attachLink",
  rejectExecution = "rejectExecution",
  sendForRevision = "sendForRevision",
  abortExecution = "abortExecution",
  confirmExecution = "confirmExecution",
  frameworkContract = "frameworkContract",
  removeContract = "removeContract",
  completeExecution = "completeExecution",
  removeTask = "removeTask",
  removeFile = "removeFile",
  removeOrganizationEmployee = "removeOrganizationEmployee",
  sendToResolution = "sendToResolution",
  addFileToIncomingDocument = "addFileToIncomingDocument",
  invalidateEmployee = "invalidateEmployee",
  acceptExecution = "acceptExecution",
  sendToArchive = "sendToArchive",
  addAgreementRouteApprover = "addAgreementRouteApprover",
  changeApprovalRoute = "changeApprovalRoute",
  existingContractWarning = "existingContractWarning",
  removeCorrespondence = "removeCorrespondence",
  contractSetUpApproval = "contractSetUpApproval",
  removeCorrespondenceFile = "removeCorrespondenceFile",
  taskEmployee = "taskEmployee",
  deleteDocument = "deleteDocument",
  deleteMessage = "deleteMessage",
  projectParticipantsContent = "projectParticipantsContent",
  projectChartsContent = "projectChartsContent",
  addFileToRandomArchiveDocument = "addFileToRandomArchiveDocument",
  addFileToGanttChart = "addFileToGanttChart",
  createProjectArchiveDocumentNoParticipantWarning = "createProjectArchiveDocumentNoParticipantWarning",
  plannedDateWarningPopUp = "plannedDateWarningPopUp",
  generateOutgoingDocumentFile = "generateOutgoingDocumentFile",
  previewBlankTemplate = "previewBlankTemplate",
  chartPlannedDate = "chartPlannedDate",
  chartActualDate = "chartActualDate",
  rejectTechSupportRequest = "rejectTechSupportRequest",
  clarifyTechSupportRequest = "clarifyTechSupportRequest",
  leaveCommentForTechSupportTask = "leaveCommentForTechSupportTask",
  sendToResolutionServiceNotes = "sendToResolutionServiceNotes",
  transferForSending = "transferForSending",
}

export enum DrawerVariantsEnum {
  contract = "contract",
  sa = "sa",
  message = "message",
  messageInfo = "messageInfo",
  viewNotification = "viewNotification",
  permissionSet = "permissionSet",
  role = "role",
  assigningRole = "assigningRole",
  supplementaryAgreements = "supplementaryAgreements",
  createContract = "createContract",
  editContract = "editContract",
  addApprover = "addApprover",
  listOfApprovals = "listOfApprovals",
  changeTypicalRoute = "changeTypicalRoute",
  createOrganization = "createOrganization",
  task = "task",
  editTask = "editTask",
  taskPreview = "taskPreview",
  attachFileInTask = "attachFileInTask",
  attachLinkInTask = "attachLinkInTask",
  attachFileInCorrespondence = "attachFileInCorrespondence",
  attachLinkInCorrespondence = "attachLinkInCorrespondence",
  attachFileInOrganization = "attachFileInOrganization",
  createIncomingDocument = "createIncomingDocument",
  editCorrespondence = "editCorrespondence",
  generateFileForOutgoingDocument = "generateFileForOutgoingDocument",
  createStaffUnit = "createStaffUnit",
  createOutgoingDocument = "createOutgoingDocument",
  createServiceNote = "createServiceNote",
  createRequestInSBM = "createRequestInSBM",
  editContractStatus = "editContractStatus",
  adjustAgreementRoute = "adjustAgreementRoute",
  contractSetUpApproval = "contractSetUpApproval",
  createInnerArchiveDocument = "createInnerArchiveDocument",
  attachFileInRandomDocument = "attachFileInRandomDocument",
  attachLinkInRandomDocument = "attachLinkInRandomDocument",
  filePreview = "filePreview",
  registerTechSupportRequest = "registerTechSupportRequest",
  sendMail = "sendMail",
}

export enum DrawerWithDataVariantsEnum {
  permissionEditSet = "permissionEditSet",
  roleEditSet = "roleEditSet",
  userRoleEdit = "userRoleEdit",
  editOrganization = "editOrganization",
  editContractAttachment = "editContractAttachment",
  editSAAttachment = "editSAAttachment",
  createStructuralUnit = "createStructuralUnit",
  editStructuralUnit = "editStructuralUnit",
  previewStructuralUnit = "previewStructuralUnit",
  viewStaffUnitDrawer = "viewStaffUnitDrawer",
  editStaffUnit = "editStaffUnit",
  addOrEditEmployee = "addOrEditEmployee",
  editEmployee = "editEmployee",
  editTaskAttachment = "editTaskAttachment",
  organizationStaffAllocationDetails = "organizationStaffAllocationDetails",
  assignRegistrationNumber = "assignRegistrationNumber",
  assignResponsiblePerson = "assignResponsiblePerson",
  editCorrespondenceAttachment = "editCorrespondenceAttachment",
  createChart = "createChart",
  assignmentPlannedDates = "assignmentPlannedDates",
  createGanttChartRow = "createGanttChartRow",
  ganttChartProgress = "ganttChartProgress",
  assignmentActualDates = "assignmentActualDates",
}
