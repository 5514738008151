import { axiosService } from "app";

import { getFilterParams } from "@sbm/fe-utils";
import { QueryFunctionContext } from "@tanstack/react-query";
import {
  ICreateChart,
  IProjectForm,
  IShareDocument,
  IFileAttachments,
  ILinkAttachments,
  IGenerateChartGroupNumber,
  ICreateRowGanttChart,
  IFactDates,
  ICreateProgressInfo,
} from "@types";

export const fetchProjectById = async (
  constructionComplexId: string | undefined
) => {
  if (!constructionComplexId) return null;

  const { data } = await axiosService({
    endpoint: `/construction-complex/${constructionComplexId}`,
  });

  return data;
};

export const fetchArchiveProjectById = async (id: string | undefined) => {
  if (!id) return null;

  const { data } = await axiosService({
    endpoint: `/item-of-outer-project-archive/${id}`,
  });

  return data;
};

export const fetchProjectArchiveDocument = async (
  { pageParam }: QueryFunctionContext,
  sortBy = "",
  search = "",
  filter: (string | undefined)[] = [],
  type: "inner" | "outer",
  constructionComplexId: number
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const dateFilters = ["filter.registrationDate", "filter.createdAt"];

  const filterParams = getFilterParams(filter, dateFilters);

  const params = {
    search,
    sortBy,
    limit,
    page,
    ...filterParams,
  };

  const path =
    type === "inner"
      ? "project-inner-archive-documents"
      : "project-outer-archive-documents";

  const { data } = await axiosService({
    endpoint: `/construction-complex/${constructionComplexId}/${path}`,
    body: params,
  });

  return data;
};

export const fetchProjectStructure = async (
  constructionComplexId: number | undefined
) => {
  if (!constructionComplexId) return null;

  const { data } = await axiosService({
    endpoint: `/project-stakeholder/construction-complexes/${constructionComplexId}`,
  });

  return data;
};

export const fetchWidgetStatsArray = async (
  widgetIds: number[] | undefined
) => {
  if (!widgetIds) return null;
  if (!widgetIds || widgetIds.length === 0) return null;

  const queryParams = widgetIds
    .map((id, index) => `widgetIds[${index}]=${id}`)
    .join("&");

  const { data } = await axiosService({
    endpoint: `/stats/widgets?${queryParams}`,
  });

  return data;
};

export const fetchWidgetStats = async (widgetIds: number | undefined) => {
  if (!widgetIds) return null;

  const { data } = await axiosService({
    endpoint: `/stats/widgets?widgetIds[0]=${widgetIds}`,
  });

  return data;
};

export const fetchProjectParticipants = async (
  constructionComplexId: number | undefined
) => {
  if (!constructionComplexId) return null;

  const { data } = await axiosService({
    endpoint: `/participant-of-project-stakeholders/${constructionComplexId}`,
  });

  return data;
};

export const fetchShareDocument = async (body: IShareDocument) => {
  const { data } = await axiosService({
    endpoint: "/item-of-outer-project-archive",
    method: "POST",
    body,
  });

  return data;
};

export const createChart = async (body: ICreateChart) => {
  const { data } = await axiosService({
    endpoint: `/gantt-charts`,
    method: "POST",
    body,
  });

  return data;
};

export const fetchRandomArchiveDocumentById = async (
  randomArchiveDocumentId: number | undefined
) => {
  if (!randomArchiveDocumentId) return;

  const { data } = await axiosService({
    endpoint: `/random-archive-document/${randomArchiveDocumentId}`,
  });

  return data;
};

export const fetchRandomArchiveFiles = async (
  randomArchiveDocumentId: string | undefined
) => {
  if (!randomArchiveDocumentId) return;

  const { data } = await axiosService({
    endpoint: `/random-archive-document/${randomArchiveDocumentId}/files`,
  });

  return data;
};

export const fetchGanttChartList = async (
  { pageParam }: QueryFunctionContext,
  constructionComplexId: number | undefined
) => {
  if (!constructionComplexId) return;
  const page = pageParam ?? 1;
  const limit = 20;

  const params = {
    limit,
    page,
  };
  const { data } = await axiosService({
    endpoint: `/gantt-charts/construction-complex/${constructionComplexId}`,
    body: params,
  });

  return data;
};

export const fetchRandomDocumentFile = async (
  randomArchiveDocumentId: number,
  body: {
    fileAttachments: IFileAttachments[];
    descriptionForAttachment?: string;
  }
) => {
  const { data } = await axiosService({
    endpoint: `/random-archive-document/${randomArchiveDocumentId}/files`,
    method: "PUT",
    body,
  });

  return data;
};

export const createRandomArchiveDocument = async (
  body: Partial<IProjectForm>
) => {
  const { data } = await axiosService({
    endpoint: "/random-archive-document",
    method: "POST",
    body,
  });

  return data;
};

export const fetchRandomDocumentLink = async (
  randomArchiveDocumentId: number,
  body: ILinkAttachments
) => {
  const { data } = await axiosService({
    endpoint: `/random-archive-document/${randomArchiveDocumentId}/links`,
    method: "PUT",
    body: body,
  });

  return data;
};

export const getRandomDocumentFiles = async (
  randomArchiveDocumentId: number | undefined
) => {
  if (!randomArchiveDocumentId) return null;

  const { data } = await axiosService({
    endpoint: `/random-archive-document/${randomArchiveDocumentId}/files`,
  });

  return data;
};

export const getOuterChapterFiles = async (
  archiveDocumentId: number | undefined
) => {
  if (!archiveDocumentId) return null;

  const { data } = await axiosService({
    endpoint: `/item-of-outer-project-archive/${archiveDocumentId}/files`,
  });

  return data;
};

export const getRandomDocumentLinks = async (
  randomArchiveDocumentId: number | undefined
) => {
  if (!randomArchiveDocumentId) return null;

  const { data } = await axiosService({
    endpoint: `/random-archive-document/${randomArchiveDocumentId}/links`,
  });

  return data;
};

export const setPlannedDate = async (body: {
  id: number;
  requestBody: {
    plannedStartDate: Date | string;
    plannedCompletionDate: Date | string;
  };
}) => {
  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/${body.id}/planned-dates`,
    method: "PUT",
    body: body.requestBody,
  });
};

export const setPlannedDateValidate = async (body: {
  id: number;
  requestBody: {
    plannedStartDate: Date | string;
    plannedCompletionDate: Date | string;
  };
}) => {
  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/${body.id}/planned-dates/validate`,
    method: "POST",
    body: body.requestBody,
  });
  return data;
};

export const generateChartGroupNumber = async (
  body: IGenerateChartGroupNumber
) => {
  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/generate-group-number`,
    method: "POST",
    body,
  });

  return data;
};

export const getGanttChartLines = async (id: number | undefined) => {
  if (!id) return;

  const { data } = await axiosService({
    endpoint: `/gantt-charts/${id}/lines`,
  });

  return data;
};

export const getGanttChartPlannedDate = async (id: number | undefined) => {
  if (!id) return;

  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/${id}/planned-dates`,
  });

  return data;
};

export const getGanttChartLineData = async (id: number | undefined) => {
  if (!id) return;

  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/${id}`,
  });

  return data;
};

export const createGanttChartlines = async (body: ICreateRowGanttChart) => {
  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines`,
    method: "POST",
    body,
  });

  return data;
};

export const createProgressInfo = async (body: {
  id: number;
  requestBody: ICreateProgressInfo;
}) => {
  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/${body.id}/progress-info`,
    method: "POST",
    body: body.requestBody,
  });

  return data;
};

export const getGanttChartActualDate = async (id: number | undefined) => {
  if (!id) return;

  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/${id}`,
  });

  return data;
};

export const setActualDate = async (body: {
  id: number;
  requestBody: Partial<IFactDates>;
}) => {
  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/${body.id}/fact-dates`,
    method: "PUT",
    body: body.requestBody,
  });

  return data;
};

export const ganttChartLineAttentionDemanding = async (body: {
  id: number;
  attentionDemanding: boolean;
}) => {
  const { data } = await axiosService({
    endpoint: `/gantt-chart-lines/${body.id}`,
    method: "PUT",
    body: { attentionDemanding: body.attentionDemanding },
  });

  return data;
};
