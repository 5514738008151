import React from "react";
import { useTranslation } from "react-i18next";

import { useSetPlannedDate } from "api";
import { Modal } from "ui-kit";

import { Button } from "@sbm/ui-components";

export const PlannedDateWarningPopUp = ({
  open,
  onClose,
  data: { data },
}: {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      requestBody: {
        plannedStartDate: Date | string;
        plannedCompletionDate: Date | string;
      };
      chartId: number;
      closeDrawer: () => void;
    };
  };
}) => {
  const { t } = useTranslation("project");

  const handleConfirmTransitionSuccess = () => {
    data.closeDrawer();
    onClose();
  };
  const handleConfirmTransitionError = () => {
    onClose();
  };

  const { mutate: assignmentPlannedDate, isLoading } = useSetPlannedDate(
    handleConfirmTransitionSuccess,
    handleConfirmTransitionError
  );

  const content = {
    title: t("planned_date_popup_warning_test"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        color="secondary"
        size="large"
        loading={isLoading}
        onClick={() => {
          assignmentPlannedDate({
            id: data.chartId as number,
            requestBody: data.requestBody,
          });
        }}
      >
        {t("ok")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
