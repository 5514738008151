import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";

import { CardIMG, SectionWrapper, TitleWrapper, Wrapper } from "./styles";

interface Props {
  totalProgress: number | undefined;
  startDate: Date | string | null | undefined;
  endDate: Date | string | null | undefined;
  name: string;
  isCompleted: boolean;
}

export const VisualizationCard = ({
  totalProgress,
  startDate,
  endDate,
  name,
  isCompleted,
}: Props) => {
  const { t } = useTranslation("project");

  return (
    <SectionWrapper>
      <Wrapper>
        <Grid container>
          <Grid item xs={12}>
            <Stack maxHeight={48} mb={6}>
              <TitleWrapper>
                <Tooltip title={name} arrow disableInteractive placement="top">
                  <Typography
                    fontWeight={700}
                    fontSize={20}
                    color="primary.light"
                  >
                    {name}
                  </Typography>
                </Tooltip>
              </TitleWrapper>
            </Stack>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12} mb={2}>
                  <Stack display="flex" flexDirection="column">
                    <Typography
                      variant="h3"
                      fontWeight={700}
                      color={isCompleted ? "success.main" : "secondary.main"}
                    >
                      {`${totalProgress}%`}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={700}
                    color="primary.light"
                  >
                    {t("actual_date")}
                  </Typography>
                </Grid>
                <Grid xs={9}>
                  <Stack
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Stack display="flex" flexDirection="column">
                      <Typography
                        variant="h9_semiBold"
                        fontWeight={600}
                        color="text.primary"
                      >
                        {t("starting")}
                      </Typography>
                      <Typography
                        variant="body3"
                        color="text.secondary"
                        sx={{ opacity: 0.7 }}
                      >
                        {getLocaleDateFormat(startDate)}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="h6"
                      color={isCompleted ? "success.main" : "secondary.main"}
                      sx={{ opacity: 0.7 }}
                    >
                      /
                    </Typography>
                    <Stack display="flex" flexDirection="column">
                      <Typography
                        variant="h9_semiBold"
                        fontWeight={600}
                        color="text.primary"
                      >
                        {t("completion")}
                      </Typography>
                      <Typography
                        variant="body3"
                        color="text.secondary"
                        sx={{ opacity: 0.7 }}
                      >
                        {getLocaleDateFormat(endDate)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6}>
              <CardIMG $isCompleted={isCompleted} />
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </SectionWrapper>
  );
};
