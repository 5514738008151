import React, { useState } from "react";
import {
  FieldErrors,
  Control,
  useWatch,
  UseFormClearErrors,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { axiosService } from "app";

import { Card, CardContent, Typography } from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import { FormAutocomplete } from "@sbm/ui-components";
import { IEmployeeInHolding, IRegisterSupportRequestPayload } from "@types";

interface Props {
  control: Control<IRegisterSupportRequestPayload>;
  errors: FieldErrors<IRegisterSupportRequestPayload>;
  setValue: UseFormSetValue<IRegisterSupportRequestPayload>;
  clearErrors: UseFormClearErrors<IRegisterSupportRequestPayload>;
}

export const RequestExecutorAutocomplete: React.FC<Props> = ({
  errors,
  control,
  setValue,
  clearErrors,
}) => {
  const { t } = useTranslation("tasks");

  const { employeeId, externalSubstitutionId } = useWatch({ control });

  const [inputEmployeeValue, setInputEmployeeValue] = useState("");
  const [employeeOptions, setEmployeeOptions] = useState<
    {
      title: string;
      employeeId?: number;
      externalSubstitutionId?: number;
    }[]
  >([]);

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return;

      const employee = employeeOptions.find(
        (i) => i.employeeId === id || i.externalSubstitutionId === id
      );

      if (!employee) return;

      return employee.title;
    },
    [employeeOptions]
  );

  const fetchEmployees = React.useCallback(async (search: string) => {
    const { data } = await axiosService({
      endpoint: `employees/tech-support`,
      body: { limit: 200, page: 1, search },
    });

    return data?.items
      .map((i: IEmployeeInHolding) => ({
        title: getPersonalDataName(i.personalData),
        employeeId: i.employeeId,
        externalSubstitutionId: i.externalSubstitutionId,
      }))
      .filter((i: { title: string | undefined }) => Boolean(i.title));
  }, []);

  const handleEmployeeInputChange = (val: string, reason: string) => {
    if (val === inputEmployeeValue) return;

    setInputEmployeeValue(val);

    // Delete action
    if (reason === "clear") {
      clearErrors("employeeId");
      clearErrors("externalSubstitutionId");
      setValue("externalSubstitutionId", undefined, { shouldDirty: true });
      setValue("employeeId", undefined, { shouldDirty: true });

      return;
    }

    // Action is select from the list
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;

      if (option.externalSubstitutionId) {
        setValue("externalSubstitutionId", option.externalSubstitutionId, {
          shouldDirty: true,
        });
      } else {
        setValue("employeeId", option.employeeId, { shouldDirty: true });
      }
    }
  };

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
        <Typography variant="h9_semiBold" color="text.disabled">
          {t("executor").toUpperCase()}
        </Typography>

        <FormAutocomplete
          required
          inputValue={inputEmployeeValue}
          freeSolo={false}
          label={t("executor")}
          fetchData={fetchEmployees}
          defaultSelected={getEmployeeDefaultValue(
            employeeId || externalSubstitutionId
          )}
          onFetchDataSuccess={setEmployeeOptions}
          onInputChange={handleEmployeeInputChange}
          error={!!errors.employeeId}
        />
      </CardContent>
    </Card>
  );
};
