import React, { useEffect, useState, useRef } from "react";
import { Box, CircularProgress, ButtonProps as MuiButtonProps } from "@mui/material";
import { LoadingLine, StyledButton } from "./styles";
import { useTranslation } from "react-i18next";

interface DelayedButtonProps extends MuiButtonProps {
  onClick?: () => void;
  loading?: boolean;
}

export const DelayedButton: React.FC<DelayedButtonProps> = (props) => {
  const { variant = "contained", color = "secondary", loading, onClick, ...rest } = props;

  const [delayedLoading, setDelayedLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [timePassed, setTimePassed] = useState(0);
  const { t } = useTranslation();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hasClickedRef = useRef(false);
  const hasCanceledRef = useRef(false);

  useEffect(() => {
    if (delayedLoading && timePassed < 2000) {
      timeoutRef.current = setTimeout(() => {
        const newPassedTime = timePassed + 100;
        setTimePassed(newPassedTime);

        if (newPassedTime >= 2000) {
          setDelayedLoading(false);
          if (!hasClickedRef.current && !hasCanceledRef.current && onClick) {
            hasClickedRef.current = true; // Prevent double trigger
            onClick();
          }
        }
      }, 100);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [delayedLoading, timePassed, onClick]);

  // Effect to handle hover actions
  useEffect(() => {
    if (isHovered && delayedLoading) {
      clearTimeout(timeoutRef.current as NodeJS.Timeout);
    } else if (!isHovered && delayedLoading) {
      timeoutRef.current = setTimeout(() => {
        const newPassedTime = timePassed + 100;
        setTimePassed(newPassedTime);

        if (newPassedTime >= 2000 && !hasClickedRef.current && !hasCanceledRef.current) {
          setDelayedLoading(false);
          hasClickedRef.current = true;
          if (onClick) {
            onClick();
          }
        }
      }, 100);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isHovered, delayedLoading, timePassed, onClick]);

  // If still loading on unmount and not canceled, trigger the onClick action
  useEffect(() => {
    return () => {
      if (delayedLoading && !hasCanceledRef.current) {
        if (onClick && !hasClickedRef.current) {
          onClick();
        }
      }
    };
  }, [delayedLoading, onClick]);

  const handleClick = () => {
    if (delayedLoading) {
      setDelayedLoading(false);
      hasCanceledRef.current = true;
    } else {
      setDelayedLoading(true);
      setTimePassed(0);
      hasClickedRef.current = false;
      hasCanceledRef.current = false;
    }
  };

  return (
    <Box
      position="relative"
      display="inline-block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledButton variant={variant} color={delayedLoading ? "primary" : color} onClick={handleClick} {...rest}>
        {loading ? (
          <CircularProgress size={props.size === "medium" ? 30 : 24} color="inherit" />
        ) : delayedLoading ? (
          t("cancel")
        ) : (
          props.children
        )}
        {delayedLoading && <LoadingLine loading={delayedLoading} isHovered={isHovered} />}
      </StyledButton>
    </Box>
  );
};
