import toast from "react-hot-toast";

import {
  createChart,
  createRandomArchiveDocument,
  fetchGanttChartList,
  fetchProjectArchiveDocument,
  fetchProjectById,
  fetchProjectParticipants,
  fetchProjectStructure,
  fetchRandomArchiveDocumentById,
  fetchShareDocument,
  fetchRandomDocumentFile,
  fetchRandomDocumentLink,
  getRandomDocumentFiles,
  getRandomDocumentLinks,
  setPlannedDate,
  fetchWidgetStats,
  generateChartGroupNumber,
  getGanttChartLines,
  createGanttChartlines,
  getGanttChartPlannedDate,
  setPlannedDateValidate,
  getGanttChartActualDate,
  setActualDate,
  getGanttChartLineData,
  ganttChartLineAttentionDemanding,
  createProgressInfo,
  fetchRandomArchiveFiles,
  fetchArchiveProjectById,
  getOuterChapterFiles,
} from "api";
import { closeDrawer, useAppDispatch } from "app";
import { AxiosError } from "axios";

import {
  getAPIErrorMessage,
  getFiltersQueryCacheKey,
  SnackbarMessages,
} from "@sbm/fe-utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IFileAttachments,
  ICreateChart,
  IGanttChart,
  IMeta,
  IProjectArchive,
  IProjectById,
  IProjectForm,
  IProjectParticipant,
  IProjectStructure,
  IRandomArchiveDocument,
  IShareDocument,
  ILinkAttachments,
  IRandomDocumentFiles,
  IRandomDocumentLinks,
  IGenerateChartGroupNumber,
  IGenerateChartData,
  ICreateRowGanttChart,
  IGanttChartLines,
  IGanttChartPlannedDate,
  IChartDataTypeA,
  IChartDataTypeD,
  IChartDataTypeB,
  IChartDataTypeE,
  IChartDataTypeC,
  IGanttChartActualDate,
  IGanttChartLineData,
  ICreateProgressInfo,
  IRandomArchiveDocumentFiles,
  ViewOuterProjectArchiveDocument_Contract,
  TypeOfTheDocument,
  ViewProjectArchiveDocument_RandomArchiveDocument,
  ViewItemOfOuterProjectArchiveDocument_Correspondence,
  IFiles,
} from "@types";

export const useGetProjectById = (
  id: string | undefined,
  options: Record<string, unknown>
) => {
  return useQuery<IProjectById>(
    ["get_project_by_id", id],
    async () => await fetchProjectById(id),
    {
      ...options,
      retry: false,
    }
  );
};
export const useGetArchiveProjectById = (id: string | undefined) => {
  return useQuery<{
    id: number;
    item:
      | ViewOuterProjectArchiveDocument_Contract
      | ViewProjectArchiveDocument_RandomArchiveDocument
      | ViewItemOfOuterProjectArchiveDocument_Correspondence;
    typeOfTheDocument: TypeOfTheDocument;
  }>(["get_project_by_id", id], async () => await fetchArchiveProjectById(id), {
    retry: false,
  });
};

export const useGetProjectArchiveDocument = (
  type: "inner" | "outer",
  constructionComplexId: number,
  sortBy = "",
  search = "",
  filter: (string | undefined)[] = []
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{
    items: IProjectArchive[];
    meta: IMeta;
  }>(
    ["get_project_archive_document", search, ...queryKeyFilter, sortBy],
    (params) =>
      fetchProjectArchiveDocument(
        params,
        sortBy,
        search,
        filter,
        type,
        constructionComplexId
      ),
    {
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetProjectStructure = (
  constructionComplexId: number | undefined
) => {
  return useQuery<IProjectStructure[]>(
    ["get_project_structure", constructionComplexId],
    async () => await fetchProjectStructure(constructionComplexId),
    {
      retry: false,
    }
  );
};

export const useGetWidgetStats = (widgetIds: number | undefined) => {
  return useQuery<
    | IChartDataTypeA
    | IChartDataTypeD
    | IChartDataTypeB
    | IChartDataTypeE
    | IChartDataTypeC
  >(
    ["get_widget_stats", widgetIds],
    async () => await fetchWidgetStats(widgetIds),
    {
      retry: false,
      enabled: !!widgetIds,
    }
  );
};

export const useGetProjectParticipants = (
  constructionComplexId: number | undefined
) => {
  return useQuery<IProjectParticipant[]>(
    ["get_project_participants", constructionComplexId],
    async () => {
      return await fetchProjectParticipants(constructionComplexId);
    },
    {
      retry: false,
      refetchInterval: 15000,
    }
  );
};
export const useShareDocumentMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IShareDocument) => {
      return await fetchShareDocument(body);
    },
    {
      onSuccess: async () => {
        onSuccess?.();
        toast.success(SnackbarMessages.success);
        await queryClient.invalidateQueries(["get_project_archive_document"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCreateProject = (
  onSuccess: () => void,
  onError: () => void
) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: ICreateChart) => {
      await createChart(body);
    },
    {
      onSuccess: async () => {
        onSuccess();
        await queryClient.invalidateQueries(["get_gantt_charts"]);
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};

export const useGetRandomArchiveDocumentById = (
  randomArchiveDocumentId: number | undefined
) => {
  return useQuery<IRandomArchiveDocument>(
    ["get_random_archive_document_by_id", randomArchiveDocumentId],
    async () => await fetchRandomArchiveDocumentById(randomArchiveDocumentId),
    {
      retry: false,
    }
  );
};

export const useGetRandomArchiveDocumentFiles = (
  randomArchiveDocumentId: string | undefined
) => {
  return useQuery<IRandomArchiveDocumentFiles>(
    ["get_random_archive_files", randomArchiveDocumentId],
    async () => await fetchRandomArchiveFiles(randomArchiveDocumentId),
    {
      retry: false,
      enabled: !!randomArchiveDocumentId,
    }
  );
};

export const useUploadFileToRandomDocument = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      randomArchiveDocumentId,
      body,
    }: {
      randomArchiveDocumentId: number;
      body: {
        fileAttachments: IFileAttachments[];
        descriptionForAttachment?: string;
      };
    }) => {
      await fetchRandomDocumentFile(randomArchiveDocumentId, body);
    },
    {
      onSuccess: async () => {
        onSuccess();
        await queryClient.invalidateQueries(["get_random_document_files"]);
        toast.success(SnackbarMessages.success);
      },
    }
  );
};

export const useGetGanttChartList = (
  constructionComplexId: number | undefined
) => {
  return useInfiniteQuery<{
    items: IGanttChart[];
    meta: IMeta;
  }>(
    ["get_gantt_charts", constructionComplexId],
    (params) => fetchGanttChartList(params, constructionComplexId),
    {
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useCreateRandomArchiveDocument = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: Partial<IProjectForm>) => {
      return await createRandomArchiveDocument(body);
    },
    {
      onSuccess: async () => {
        onSuccess?.();
        toast.success(SnackbarMessages.success);
        await queryClient.invalidateQueries(["get_project_archive_document"]);
        dispatch(closeDrawer());
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useUploadLinkToRandomDocument = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      randomArchiveDocumentId,
      body,
    }: {
      randomArchiveDocumentId: number;
      body: ILinkAttachments;
    }) => {
      await fetchRandomDocumentLink(randomArchiveDocumentId, body);
    },
    {
      onSuccess: async () => {
        onSuccess();
        await queryClient.invalidateQueries(["get_random_document_links"]);
        toast.success(SnackbarMessages.success);
      },
    }
  );
};

export const useGetRandomDocumentFiles = (
  randomArchiveDocumentId: number | undefined
) => {
  return useQuery<IRandomDocumentFiles>(
    ["get_random_document_files", randomArchiveDocumentId],
    async () => await getRandomDocumentFiles(randomArchiveDocumentId)
  );
};

export const useGetOuterChapterFiles = (
  archiveDocumentId: number | undefined
) => {
  return useQuery<IFiles[]>(
    ["get_outer_chapter_document_files", archiveDocumentId],
    async () => await getOuterChapterFiles(archiveDocumentId)
  );
};

export const useGetRandomDocumentLinks = (
  randomArchiveDocumentId: number | undefined
) => {
  return useQuery<IRandomDocumentLinks>(
    ["get_random_document_links", randomArchiveDocumentId],
    async () => await getRandomDocumentLinks(randomArchiveDocumentId)
  );
};

export const useSetPlannedDate = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(setPlannedDate, {
    onSuccess: async () => {
      toast.success(SnackbarMessages.success);
      onSuccess();
      await queryClient.invalidateQueries([
        "get_gantt_chart_lines_actual_date",
      ]);
    },
    onError: (error) => {
      const errorMessage = getAPIErrorMessage(error);
      toast.error(errorMessage);
      onError();
    },
  });
};

export const useSetPlannedDateValidation = (
  onSuccess: (res: boolean) => void
) => {
  return useMutation(
    async (body: {
      id: number;
      requestBody: {
        plannedStartDate: Date | string;
        plannedCompletionDate: Date | string;
      };
    }) => {
      return await setPlannedDateValidate(body);
    },
    {
      onSuccess: async (res) => {
        onSuccess(res);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGenerateChartGroupNumber = (
  onSuccess: (data: IGenerateChartData) => void
) => {
  return useMutation(
    async (body: IGenerateChartGroupNumber) => {
      return await generateChartGroupNumber(body);
    },
    {
      onSuccess: async (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetGanttChartLines = (id: number | undefined) => {
  return useQuery<IGanttChartLines[]>(
    ["get_gantt_chart_lines", id],
    async () => await getGanttChartLines(id)
  );
};

export const useGetGanttCharPlannedDates = (id: number | undefined) => {
  return useQuery<IGanttChartPlannedDate>(
    ["get_gantt_chart_lines_planned_date", id],
    async () => await getGanttChartPlannedDate(id)
  );
};

export const useGetGanttChartLineData = (id: number | undefined) => {
  return useQuery<IGanttChartLineData>(
    ["get_gantt_chart_lines_data", id],
    async () => await getGanttChartLineData(id)
  );
};

export const useCreateGanttChartLines = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (body: ICreateRowGanttChart) => {
      return await createGanttChartlines(body);
    },
    {
      onSuccess: async () => {
        toast.success(SnackbarMessages.success);
        onSuccess();
        await queryClient.invalidateQueries(["get_gantt_chart_lines"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCreateProgressInfo = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (body: { id: number; requestBody: ICreateProgressInfo }) => {
      return await createProgressInfo(body);
    },
    {
      onMutate: async (body) => {
        return { id: body.id, requestBody: body.requestBody };
      },
      onSuccess: async (_, variables) => {
        toast.success(SnackbarMessages.success);
        onSuccess();

        queryClient.setQueryData(
          ["get_gantt_chart_lines_data", variables.id],
          (old?: IGanttChartLineData) => {
            if (!old) return undefined;
            return {
              ...old,
              totalProgress:
                old.totalProgress + variables.requestBody.progressIncrement,
            };
          }
        );

        await queryClient.invalidateQueries(["get_gantt_chart_lines_data"]);
        await queryClient.invalidateQueries(["get_gantt_chart_lines"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useMutateGanttChartLineAttentionDemanding = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (body: { id: number; attentionDemanding: boolean }) => {
      return await ganttChartLineAttentionDemanding(body);
    },
    {
      onMutate: async (body) => {
        return { id: body.id, attentionDemanding: body.attentionDemanding };
      },
      onSuccess: async (_, variables) => {
        queryClient.setQueryData(
          ["get_gantt_chart_lines_data", variables.id],
          (old?: IGanttChartLineData) => {
            if (!old) return undefined;
            return {
              ...old,
              attentionDemanding: variables.attentionDemanding,
            };
          }
        );
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetGanttCharActualDates = (id: number | undefined) => {
  return useQuery<IGanttChartActualDate>(
    ["get_gantt_chart_lines_actual_date", id],
    async () => await getGanttChartActualDate(id)
  );
};

export const useSetActualDate = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(setActualDate, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get_gantt_chart_lines"]);
      toast.success(SnackbarMessages.success);
      onSuccess();
    },
    onError: (error) => {
      const errorMessage = getAPIErrorMessage(error);
      toast.error(errorMessage);
    },
  });
};
