import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { IChartDataTypeEUpdated } from "@types";

import { NumberBoxWrapper, ImageWrapper } from "./styles";

export const TypeE1WidgetChart = ({
  data,
  imageUrl,
}: {
  data: IChartDataTypeEUpdated;
  imageUrl?: string;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [animatedValues, setAnimatedValues] = useState({
    project: 0,
    construction: 0,
  });

  useEffect(() => {
    const animateValue = (
      key: "project" | "construction",
      targetValue: number
    ) => {
      let current = 0;
      const step = Math.ceil(targetValue / 50);
      const interval = setInterval(() => {
        current += step;
        if (current >= targetValue) {
          current = targetValue;
          clearInterval(interval);
        }
        setAnimatedValues((prev) => ({ ...prev, [key]: current }));
      }, 20);
    };

    animateValue(
      "project",
      data?.generalProgressForProject ? data.generalProgressForProject : 0
    );
    animateValue(
      "construction",
      data?.generalProgressForConstruction
        ? data.generalProgressForConstruction
        : 0
    );
  }, [data]);

  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      gap={2}
      width="100%"
    >
      <Grid container>
        <Grid item xs={7}>
          <Stack
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid container>
              <Grid item xs={6} height="100%">
                <Stack
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <NumberBoxWrapper
                    $bgColor={
                      animatedValues.project > 0
                        ? theme.palette.secondary.main
                        : hexToRgba(theme.palette.primary.main, 0.12)
                    }
                  >
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      color={
                        animatedValues.project > 0
                          ? "common.white"
                          : "text.secondary"
                      }
                    >
                      {`${animatedValues.project}%`}
                    </Typography>
                  </NumberBoxWrapper>
                  <Typography
                    variant="caption"
                    fontWeight={600}
                    color="text.secondary"
                  >
                    {t("design")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <NumberBoxWrapper
                    $bgColor={
                      animatedValues.construction > 0
                        ? theme.palette.secondary.main
                        : hexToRgba(theme.palette.primary.main, 0.12)
                    }
                  >
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      color={
                        animatedValues.construction > 0
                          ? "common.white"
                          : "text.secondary"
                      }
                    >
                      {`${animatedValues.construction}%`}
                    </Typography>
                  </NumberBoxWrapper>
                  <Typography
                    variant="caption"
                    fontWeight={600}
                    color="text.secondary"
                  >
                    {t("construction")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <ImageWrapper>
            <img
              src={imageUrl}
              draggable={false}
              height="100%"
              width="auto"
              alt="project_img"
            />
          </ImageWrapper>
        </Grid>
      </Grid>
    </Stack>
  );
};
