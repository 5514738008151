import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDebounce, useEffectOnce, useQueryParams } from "app";

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { getFilterOptionName, getUrlParam } from "@sbm/fe-utils";
import { Input } from "@sbm/ui-components";

import { IOption } from "../../types";
import { ActionsContainer, InputWrapper } from "./styles";

interface FilterFieldsProps {
  filterableFields: IOption[];
  onClose: () => void;
}

export const FilterFields: React.FC<FilterFieldsProps> = ({
  filterableFields,
  onClose,
}) => {
  const { t } = useTranslation();

  const { get, append, remove } = useQueryParams();

  const filterFromUrl = get("filter", true) as string[];

  const [search, setSearch] = useState("");
  // List for showing options and filter them through search
  const [fields, setFields] = useState<IOption[]>([]);
  // List for showing selected filter options
  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const debouncedSearch = useDebounce(search, 300);

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    setSearch(newValue);
  };

  const handleChange = (filterField?: string) => {
    if (!filterField) return;

    setSelectedFields((prevState) => {
      if (prevState.includes(filterField)) {
        return prevState.filter((i) => i !== filterField);
      }

      return [...prevState, filterField];
    });
  };

  const handleApply = () => {
    const searchFromUrl = getUrlParam("search") as string;

    remove("filter");
    const filtersFromUrl = get("filter", true) as string[];

    if (selectedFields) {
      selectedFields.forEach((filterBy) => {
        const value = `${filterBy}=`;
        const prevFilter = filtersFromUrl.find((i) => i.includes(value));
        const valueToSet = prevFilter || value;

        append("filter", valueToSet);
      });

      if (searchFromUrl) {
        append("search", searchFromUrl);
      }
    }

    onClose();
  };

  const handleReset = () => {
    setSearch("");
    setSelectedFields([]);
  };

  useEffectOnce(() => {
    const filterFromUrl = get("filter", true) as string[];

    if (filterFromUrl && filterFromUrl.length > 0 && !selectedFields.length) {
      const selectedFromUrl = filterFromUrl.map((i) => {
        return i.split("=")[0];
      });

      setSelectedFields(selectedFromUrl);
    }
  });

  useEffect(() => {
    const filteredFields = fields.filter((i) =>
      i.title.toLowerCase().includes(debouncedSearch.toLowerCase())
    );

    if (debouncedSearch) {
      setFields(filteredFields);
    }

    if (debouncedSearch === "" && fields.length !== filterableFields.length) {
      setFields(filterableFields);
    }
  }, [debouncedSearch, fields, filterableFields]);

  useEffect(() => {
    if (filterableFields.length > 0) {
      setFields(filterableFields);
    }
  }, [filterableFields]);

  return (
    <>
      <InputWrapper>
        <Input
          size="small"
          label={t("search")}
          value={search}
          onChange={handleSearch}
        />
      </InputWrapper>

      <Stack
        sx={{
          height: "calc(432px - 55px - 72px)", // 432 - popup height, 55 - footer, 72 - header
          overflowY: "auto",
          px: 4,
          py: 2,
        }}
      >
        {fields.map((option, index) => {
          const name = getFilterOptionName(option.title);

          return (
            option.optionType !== "documentsFor" && (
              <FormControlLabel
                key={name + index}
                label={name}
                sx={{
                  alignItems: "flex-start",
                  ".MuiTypography-root": { mt: 2 },
                }}
                control={
                  <Checkbox
                    checked={selectedFields.includes(option.field || "")}
                    value={option.value}
                    color="secondary"
                    size="medium"
                    onChange={() => handleChange(option.field)}
                  />
                }
              />
            )
          );
        })}
      </Stack>

      <Divider />
      <ActionsContainer>
        <Button size="small" color="secondary" onClick={handleReset}>
          {t("reset")}
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          disabled={!selectedFields.length && !filterFromUrl?.length}
          onClick={handleApply}
        >
          {t("apply")}
        </Button>
      </ActionsContainer>
    </>
  );
};
