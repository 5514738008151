import { DocumentTypeOfTheDocumentEnum } from "./messages";

export interface INotification {
  id: number;
  messageText: string;
  topic: string;
  messageDate: string | null;
  viewingDate: string | null;
  messageNumber: number;
  messageAuthor: {
    typeOfAuthor: "user" | "system";
    authorName?: string;
  };
  messageViewed: boolean;
  isImportant: boolean;
  additionalInformation: string | null;
}

export type TAutocompleteOption = {
  title: string;
  id: number;
};

export interface ILinkedEntity {
  typeOfTheDocument: DocumentTypeOfTheDocumentEnum;
  messageId: number | null;
  taskId: number | null;
  regularTaskId: number | null;
  contractId: number | null;
  supplementaryAgreementId: number | null;
  correspondenceId: number | null;
  oadId: number | null;
  serviceNoteId: number | null;
  workplanId: number | null;
  minutesInternalMeetingId: number | null;
  powerOfAttorneyId: number | null;
  manualId: number | null;
  externalLinkId: number | null;
}

export interface IFilterOption {
  title: string;
  value: string | string[];
  field?: string;
  options?: any[];
  optionType?: string;
  multiSelect?: boolean;
  type?: string;
  variant?: string;
}

export interface IMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
  unreadCount?: number;
  activeCount?: number;
  completedCount?: number;
  inTheSpotlightCount?: number;
  requiresActionCount?: number;
}

export interface IWorkingDay {
  id: number;
  date: string;
  workingDay: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum DrawerTypes {
  previewOrganizationStructerDrawer = "previewOrganizationStructerDrawer",
  messageDrawer = "messageDrawer",
  messageView = "message_view",
  createOrgDrawer = "createOrgDrawer",
  createLetterDrawer = "createLetterDrawer",
  createOrganizationStructerDrawer = "createOrganizationStructerDrawer",
  viewStaffUnitDrawer = "viewStaffUnitDrawer",
  staffAllocationDetails = "staffAllocationDetails",
}

export interface IAttachedDocumentLink {
  id: number;
  internalNumber: number;
  sequenceNumber: number;
  longNameOfTheDocument: string;
  createdAt: string;
  documentNumber: string | null;
  context: string | null;
  contextOfTheDocument: string | null;
  documentDate: string | null;
  documentName: string;
}

export enum MetricsEnum {
  TASK_WIDGET = "task_widget",
  MESSAGE_COUNT = "message_count",
  APPROVAL_COUNT = "approval_count",
  TASK_COUNT = "task_count",
  APPROVALS_WIDGET = "approvals_widget",
  MY_DOCUMENT_WIDGET = "my_document_widget",
}

export enum WidgetModuleEnum {
  documents = "Documents",
  materials = "Materials",
  organizations = "Organizations",
  settings = "Settings",
  projects = "Projects",
}

export interface IMetrics {
  messageCount: number;
  approvalCount: number;
  taskCount: number;
  taskWidgetCounts: {
    incomingCompleted: number;
    incomingInTheSpotlight: number;
    incomingRequiresAction: number;
    incomingUnreadCount: number;
    outgoingCompleted: number;
    outgoingInTheSpotlight: number;
    outgoingRequiresAction: number;
    outgoingUnreadCount: number;
  };
  approvalsWidgetCounts: {
    totalCount: number;
    underConsiderationCount: number;
    approvedCount: number;
    rejectedCount: number;
    unreadCount: number;
  };
  myDocumentWidgetCounts: {
    incomingCount: string | number;
    outgoingCount: string | number;
    otherCount: string | number;
    unreadCount: string | number;
    totalCount: string | number;
  };
}

export enum TypeOfTheWidgetEnum {
  service = "service",
  organization = "organization",
  project = "project",
  mainWidgetForModule = "main_widget_for_module",
  groupOfWidgets = "group_of_widgets",
}

export interface IWidget {
  id: number;
  typeOfTheWidget: TypeOfTheWidgetEnum;
  templateNumberForWidget: TemplateNumberForWidgetEnum;
  widgetName: string;
  imageUrl: string;
  parentWidgetId: number | null;
  organizationId: number | null;
  constructionComplexId: number | null;
  serviceId: WidgetServiceEnum | null;
  serialNumber: number | null;
  switcherOn: boolean;
}

export enum ChapterEnum {
  tool = "tool",
  project = "project",
}

export enum TemplateNumberForWidgetEnum {
  A1 = "A1",
  A2 = "A2",
  F = "F",
  A3 = "A3",
  G = "G",
  D1 = "D1",
  B1 = "B1",
  C1 = "C1",
  H = "H",
  E1 = "E1",
}

export interface IWidgetForHomepage {
  chapter: ChapterEnum;
  iconName: string;
  id: number;
  widgetId: number;
  imageUrl: string;
  module: WidgetModuleEnum;
  serialNumberInTheChapter: number | null;
  templateNumberForWidget: TemplateNumberForWidgetEnum;
  typeOfTheWidget: TypeOfTheWidgetEnum;
  widgetName: string;
  widgetOrganizationId: number | null;
  widgetConstructionComplexId: number | null;
  widgetServiceId: WidgetServiceEnum | null;
}

export enum WidgetServiceEnum {
  Contracts = 1, // Договоры
  ORD = 2, // ОРД
  Correspondence = 3, // Корреспонденция
  Protocols = 4, // Протоколы
  WorkPlans = 5, // Планы работ
  PowersOfAttorney = 6, // Доверенности
  Instructions = 7, // Инструкции
  Organizations = 8, // Организации
  ApprovalRoutes = 9, // Маршруты согласования
  Main = 10, // Главная
  Users = 11, // Пользователи
  Access = 12, // Доступы
  Tasks = 13, // Поручения
  Nomenclature = 14, // Номенклатура
  Applications = 15, // Заявки
  Limits = 16, // Лимиты
  Payment = 17, // Оплата
  Supply = 18, // Снабжение
  Warehouses = 19, // Склады
  Support = 20, // Обращения в Tехподдержку
  ConstructionComplex = 21, // Строительный комплекс
}

export enum StyleForStatusEnum {
  default = "default",
  primary = "primary",
  secondary = "secondary",
  success = "success",
  error = "error",
  initial = "initial",
}
