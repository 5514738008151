import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import * as echarts from "echarts";

import { Stack, Typography, useTheme } from "@mui/material";
import { IChartDataTypeA } from "@types";

export const TreeMapChart = ({
  uniqueId,
  data,
}: {
  uniqueId: number | string;
  data: IChartDataTypeA;
}) => {
  const theme = useTheme();
  const { t } = useTranslation("project");

  useEffect(() => {
    const chartDom = document.getElementById(`treemapChart${uniqueId}`);
    if (!chartDom) return;

    const chart = echarts.init(chartDom);

    const option: echarts.EChartsOption = {
      tooltip: {
        show: true,
        confine: true,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        textStyle: {
          color: theme.palette.text.primary,
          fontSize: 14,
        },
        formatter: (params: any) => {
          return `<div style="display: flex; flex-direction: row; align-items: center; gap: 8px;
                  min-width: 150px; background: ${theme.palette.primary.main};
                  border-radius: 8px; white-space: normal; word-break: break-word;">
              <div style="color: #fff; flex: 1;">${params.name}</div>
              <div style="background: #fff; height: 20px; padding: 4px 8px;
                          border-radius: 8px; display: flex; align-items: center; justify-content: center;">
                  <strong style="color: #1B2C41">${params.value}</strong>
              </div>
            </div>`;
        },
      },
      series: [
        {
          type: "treemap",
          data: data?.leftSubParameters,
          label: {
            show: true,
            color: theme.palette.common.white,
            fontWeight: 400,
            fontSize: 20,
            align: "center",
            verticalAlign: "middle",
            formatter: (params: any) => {
              return params.data?.value;
            },
          },

          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
            color: theme.palette.warning.main,
            borderRadius: 4,
          },
          levels: [
            {
              itemStyle: {
                color: theme.palette.secondary.main,
              },
            },
          ],
          breadcrumb: {
            show: false,
          },
          labelLayout: {
            hideOverlap: false,
            moveOverlap: "shiftY",
          },
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [uniqueId, theme, data]);

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      <div
        id={`treemapChart${uniqueId}`}
        style={{
          width: "200px",
          height: "200px",
        }}
      />
      {data?.leftParameter.name && (
        <Typography
          variant="body2"
          fontWeight={600}
          color={theme.palette.text.secondary}
          sx={{ opacity: 0.7, borderRadius: "8px" }}
        >
          {t(`charts_status.${data.leftParameter?.name}`)}
        </Typography>
      )}
    </Stack>
  );
};
