import { useGetGanttCharActualDates } from "api";
import { useAppSelector } from "app";

import { DrawerWithDataVariantsEnum } from "@types";

import { AssignmentActualDatesForm } from "./AssignmentActualDatesForm";

export const AssignmentActualDatesContainer = () => {
  const { drawerWithData } = useAppSelector((state) => state.global);
  const ganttChartLineId = drawerWithData?.content?.data?.ganttChartLineId;
  const { data } = useGetGanttCharActualDates(Number(ganttChartLineId));

  if (
    drawerWithData?.variant !== DrawerWithDataVariantsEnum.assignmentActualDates
  )
    return null;

  return (
    <AssignmentActualDatesForm
      data={data}
      ganttChartLineId={ganttChartLineId}
    />
  );
};
