import React from "react";

import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress, useTheme } from "@mui/material";

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { palette } = useTheme();
  const { loading, variant = "contained", ...rest } = props;

  return (
    <MuiButton variant={variant} {...rest}>
      {loading ? (
        <CircularProgress
          size={props.size === "medium" ? 30 : 24}
          color={props.color || "inherit"}
          sx={{
            color:
              props.color === "secondary" && variant === "contained"
                ? palette.secondary.contrastText
                : props.color || "inherit",
          }}
        />
      ) : (
        props.children
      )}
    </MuiButton>
  );
};
