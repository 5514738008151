import { TFunction } from "i18next";

import { StatusForCorrespondenceEnum } from "@types";

export const getLabel = (
  documentStatus: StatusForCorrespondenceEnum,
  t: TFunction<"correspondence", undefined, "correspondence">
) => {
  switch (documentStatus) {
    case StatusForCorrespondenceEnum.draft:
      return t("documentStatus.draft");
    case StatusForCorrespondenceEnum.onResolution:
      return t("documentStatus.on_resolution");
    case StatusForCorrespondenceEnum.onExecution:
      return t("documentStatus.on_execution");
    case StatusForCorrespondenceEnum.completedWithoutResolutions:
      return t("documentStatus.completed_without_resolutions");
    case StatusForCorrespondenceEnum.executed:
      return t("documentStatus.executed");
    case StatusForCorrespondenceEnum.toBeAgreed:
      return t("documentStatus.to_be_agreed");
    case StatusForCorrespondenceEnum.atSigning:
      return t("documentStatus.at_signing");
    case StatusForCorrespondenceEnum.dispatch:
      return t("documentStatus.dispatch");
    case StatusForCorrespondenceEnum.sentToRecipient:
      return t("documentStatus.sent_to_recipient");
    case StatusForCorrespondenceEnum.canceled:
      return t("documentStatus.canceled");
    case StatusForCorrespondenceEnum.inProgress:
      return t("documentStatus.in_progress");
    case StatusForCorrespondenceEnum.completed:
      return t("documentStatus.completed");
  }
};
