import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetGanttChartLines, useGetProjectParticipants } from "api";
import {
  AssignmentPlannedDatesContainer,
  setModal,
  useAppDispatch,
  useAppSelector,
  openDrawerWithData,
  GanttChartProgressContainer,
  AssignmentActualDatesContainer,
} from "app";
import { GanttChart } from "ui-kit";

import {
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { getFormattedTreeData, TreeNode } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { DrawerWithDataVariantsEnum, ModalVariants } from "@types";
import { TaskOrEmpty, ViewMode } from "@wamra/gantt-task-react";

import { ChartCard } from "./ChartCard";
import { getColumns } from "./columns";
import { getActualChartRows, getPlannedChartRows } from "./helpers";

enum GanttTypeEnum {
  actual = "actual",
  planned = "planned",
}

const GanttViewModes: (keyof typeof ViewMode)[] = [
  ViewMode.Day,
  ViewMode.Week,
  ViewMode.Month,
  ViewMode.Year,
];

const GanttTypeMode: (keyof typeof GanttTypeEnum)[] = [
  GanttTypeEnum.actual,
  GanttTypeEnum.planned,
];

export const ViewGanttChartContainer = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("project");
  const { id: constructionComplexId, chartId } = useParams();

  const { drawerWithData } = useAppSelector((state) => state.global);

  const [isColumnsHidden, setIsColumnsHidden] = useState(false);

  const [ganttTypeMode, setGanttTypeMode] = useState<GanttTypeEnum>(
    GanttTypeEnum.actual
  );
  const [ganttViewMode, setGanttViewMode] = useState<ViewMode>(ViewMode.Day);
  const [ganttSelectedDate, setGanttSelectedDate] = React.useState<Date>(
    new Date()
  );

  const { data: userProjectParticipants } = useGetProjectParticipants(
    Number(constructionComplexId)
  );

  const { data: dataForLines = [] } = useGetGanttChartLines(Number(chartId));

  const handleDrawer = () => {
    if (userProjectParticipants && userProjectParticipants.length === 0) {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.projectChartsContent,
        })
      );
    } else if (userProjectParticipants && userProjectParticipants.length) {
      dispatch(
        openDrawerWithData({
          variant: DrawerWithDataVariantsEnum.createGanttChartRow,
        })
      );
    }
  };

  const handleSwitcher = () => {
    setIsColumnsHidden(!isColumnsHidden);
  };

  const handleToday = () => {
    const dateToSet = new Date();
    dateToSet.setDate(dateToSet.getDate() - 1);

    setGanttSelectedDate(dateToSet);
  };

  const handleSelectViewMode = (
    _: React.MouseEvent<HTMLElement>,
    value: ViewMode
  ) => {
    setGanttViewMode(value);
    handleToday();
  };

  const handleSelectTypeMode = (
    _: React.MouseEvent<HTMLElement>,
    value: GanttTypeEnum
  ) => {
    setGanttTypeMode(value);
    handleToday();
  };

  const ganttTableColumns = React.useMemo(
    () => getColumns(t, isColumnsHidden),
    // eslint-disable-next-line
    [t, isColumnsHidden, dataForLines, ganttTypeMode]
  );

  const ganttChartData = React.useMemo(() => {
    let result: (TaskOrEmpty & { data?: any })[] = [];

    const formattedTreeData = getFormattedTreeData(
      dataForLines as unknown as { nodes: TreeNode[] }
    );

    if (!formattedTreeData || !formattedTreeData.length) {
      result = [];
    } else {
      for (let i = 0; i < formattedTreeData.length; i++) {
        const item = formattedTreeData[i];

        if (ganttTypeMode === GanttTypeEnum.planned) {
          const row = getPlannedChartRows(item, t, theme);
          if (row) {
            result.push(row);
          }
        } else {
          const row = getActualChartRows(item, t, theme);
          if (row) {
            result.push(row);
          }
        }
      }
    }

    return result;
    // eslint-disable-next-line
  }, [dataForLines, ganttTypeMode, isColumnsHidden]);

  return (
    <Stack direction="column" gap={6}>
      <ChartCard />

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" color={theme.palette.text.secondary}>
          {t("schedule_name")}
        </Typography>
        <Button color="secondary" onClick={handleDrawer}>
          {t("add")}
        </Button>
      </Stack>

      <Grid item>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <FormControlLabel
              label={t("show_columns")}
              control={
                <Switch
                  color="secondary"
                  defaultChecked={isColumnsHidden}
                  onChange={handleSwitcher}
                />
              }
            />
            <Stack>
              <ToggleButtonGroup
                exclusive
                color="secondary"
                size="large"
                value={ganttTypeMode}
                onChange={handleSelectTypeMode}
              >
                {GanttTypeMode.map((mode) => {
                  return (
                    <ToggleButton key={mode} value={mode}>
                      <Typography variant="body3" fontWeight={600}>
                        {t(`gantt_type_modes.${mode.toLowerCase()}`)}
                      </Typography>
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Stack>

            <Stack flexDirection="row" alignItems="center">
              <Button size="large" color="secondary" onClick={handleToday}>
                {t("today")}
              </Button>
            </Stack>
          </Stack>

          <Stack>
            <ToggleButtonGroup
              exclusive
              color="secondary"
              size="large"
              value={ganttViewMode}
              onChange={handleSelectViewMode}
            >
              {GanttViewModes.map((mode) => {
                return (
                  <ToggleButton key={mode} value={mode}>
                    <Typography variant="body3" fontWeight={600}>
                      {t(`gantt_view_modes.${mode.toLowerCase()}`)}
                    </Typography>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Stack>
        </Stack>
      </Grid>

      <GanttChart
        viewMode={ganttViewMode}
        viewDate={ganttSelectedDate}
        tasks={ganttChartData}
        columns={ganttTableColumns}
      />

      {drawerWithData?.variant ===
      DrawerWithDataVariantsEnum.assignmentPlannedDates ? (
        <AssignmentPlannedDatesContainer />
      ) : null}

      {drawerWithData?.variant ===
        DrawerWithDataVariantsEnum.ganttChartProgress &&
      ganttTypeMode !== GanttTypeEnum.planned ? (
        <GanttChartProgressContainer />
      ) : null}

      {drawerWithData?.variant ===
      DrawerWithDataVariantsEnum.assignmentActualDates ? (
        <AssignmentActualDatesContainer />
      ) : null}
    </Stack>
  );
};
