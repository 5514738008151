import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "app";
import {
  axiosService,
  getUserEmployeeOrganizationsAndPositions,
  getUserExternalSubstitutionOrganizationsAndPositions,
  useAppSelector,
} from "app";

import { Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import { FormAutocomplete, FormRadio } from "@sbm/ui-components";
import { IEmployeeInHolding, TypeOfCorrespondenceResponsible } from "@types";

import { DetailsStepProps } from "../DetailsStep";

export const DetailsStepAdditionalInfo: React.FC<
  Pick<DetailsStepProps, "control" | "setValue"> & {
    isEditMode: boolean;
    responsibleId?: number;
    senderOrganizationId?: number;
  }
> = ({
  control,
  setValue,
  isEditMode = false,
  responsibleId,
  senderOrganizationId,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation("correspondence");

  const { userProfile } = useAppSelector((state) => state.auth);

  const [inputEmployeeValue, setInputEmployeeValue] = useState("");
  const [employeeOptions, setEmployeeOptions] = useState<
    {
      title: string;
      responsibleId: number;
      typeOfResponsible: TypeOfCorrespondenceResponsible;
    }[]
  >([]);

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return;

      const employee = employeeOptions.find((i) => i.responsibleId === id);

      if (!employee) return;

      return employee.title;
    },
    [employeeOptions]
  );

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      if (!senderOrganizationId) return [];

      const { data } = await axiosService({
        endpoint: `employees/organization/${senderOrganizationId}/active-user-employee`,
        body: { limit: 200, page: 1, search },
      });

      return data?.items
        .map(
          (i: IEmployeeInHolding) =>
            ({
              title: getPersonalDataName(i.personalData),
              responsibleId: i.externalSubstitutionId || i.employeeId,
              typeOfResponsible: i.externalSubstitutionId
                ? TypeOfCorrespondenceResponsible.externalSubstitution
                : TypeOfCorrespondenceResponsible.employee,
              displayLabel: (
                <Stack>
                  <Typography variant="body2" fontWeight={600}>
                    {getPersonalDataName(i.personalData)}
                  </Typography>
                  <Typography variant="body3" color="text.secondary">
                    {i.structuralUnit?.positionName}
                  </Typography>
                </Stack>
              ),
            } as {
              title: string;
              responsibleId: number;
              typeOfResponsible: TypeOfCorrespondenceResponsible;
            })
        )
        .filter((i: { title: string | undefined }) => Boolean(i.title));
    },
    [senderOrganizationId]
  );

  const handleEmployeeInputChange = (
    _: React.SyntheticEvent,
    val: {
      responsibleId: number;
      title: string;
      typeOfResponsible: TypeOfCorrespondenceResponsible;
    } | null,
    reason: string
  ) => {
    if (!val || reason === "clear") {
      setValue("responsibleId", undefined);
      setValue("typeOfResponsible", undefined, { shouldDirty: true });
      setInputEmployeeValue("");

      return;
    }

    if (reason === "selectOption") {
      const option = employeeOptions.find(
        (i) => i.title === val.title && i.responsibleId === val.responsibleId
      );
      if (!option) return;

      setValue("responsibleId", option.responsibleId);
      setValue("typeOfResponsible", option.typeOfResponsible, {
        shouldDirty: true,
      });
      setInputEmployeeValue(!senderOrganizationId ? "" : val.title);
    }
  };

  const userEmployeeOrganizationsAndPositions =
    getUserEmployeeOrganizationsAndPositions(userProfile?.personalData);

  const userEmployeeOrganizationsAndPositionsIds =
    userEmployeeOrganizationsAndPositions.map((i) => i.id);

  const userExternalSubstitutionOrganizationsAndPositions =
    getUserExternalSubstitutionOrganizationsAndPositions(
      userProfile?.personalData
    );

  const userExternalSubstitutionOrganizationsAndPositionsIds =
    userExternalSubstitutionOrganizationsAndPositions.map((i) => i.id);

  const isEmployeeAndExternalSubInSameOrganization =
    senderOrganizationId &&
    userEmployeeOrganizationsAndPositionsIds.includes(senderOrganizationId) &&
    userExternalSubstitutionOrganizationsAndPositionsIds.includes(
      senderOrganizationId
    );

  const employeeAndExternalSubPositionOptions = React.useMemo(() => {
    if (!senderOrganizationId || !isEmployeeAndExternalSubInSameOrganization)
      return [];

    const employeePosition = userEmployeeOrganizationsAndPositions.find(
      (i) => i.id === senderOrganizationId
    )?.position;

    const externalPosition =
      userExternalSubstitutionOrganizationsAndPositions.find(
        (i) => i.id === senderOrganizationId
      )?.position;

    if (!externalPosition || !employeePosition) return [];

    // find repeated org id and show position for employee
    const employeeOption = {
      option: employeePosition,
      value: "employee",
    };
    // find repeated org id and show position for external sub
    const externalSubOption = {
      option: externalPosition,
      value: "external",
    };

    return [employeeOption, externalSubOption];
  }, [
    isEmployeeAndExternalSubInSameOrganization,
    senderOrganizationId,
    userEmployeeOrganizationsAndPositions,
    userExternalSubstitutionOrganizationsAndPositions,
  ]);

  useEffect(() => {
    setValue("creatorPosition", undefined);
  }, [senderOrganizationId, setValue]);

  useEffect(() => {
    if (!responsibleId) {
      setInputEmployeeValue("");
    }
  }, [responsibleId]);

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.additional_info.title").toUpperCase()}
        </Typography>

        <FormAutocomplete
          required
          disabled={!senderOrganizationId}
          inputValue={inputEmployeeValue}
          freeSolo={false}
          label={t(
            "createLetterDrawer.additional_info.responsible_for_sending"
          )}
          fetchData={fetchEmployees}
          defaultSelected={getEmployeeDefaultValue(responsibleId)}
          onFetchDataSuccess={setEmployeeOptions}
          onChange={handleEmployeeInputChange}
        />

        {isEmployeeAndExternalSubInSameOrganization &&
          employeeAndExternalSubPositionOptions.length > 0 &&
          !isEditMode && (
            <Stack>
              <FormRadio
                required
                labelPropsSx={{
                  fontWeight: 700,
                  color: palette.primary.main,
                  mb: 2,
                }}
                label={t(
                  "createLetterDrawer.additional_info.position_checkbox_label"
                )}
                name="creatorPosition"
                control={control}
                values={employeeAndExternalSubPositionOptions}
              />
            </Stack>
          )}
      </CardContent>
    </Card>
  );
};
