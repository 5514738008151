import React from "react";
import {
  Controller,
  Control,
  useWatch,
  UseFormSetValue,
} from "react-hook-form";

import { Stack, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { IGanttChartProgress } from "@types";

const PrettoSlider = styled(Slider, {
  shouldForwardProp: (prop) => prop !== "attentionDemanding",
})<{ attentionDemanding: boolean }>(({ theme, attentionDemanding }) => ({
  color: attentionDemanding
    ? theme.palette.error.main
    : theme.palette.secondary.main,
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: attentionDemanding
      ? theme.palette.error.main
      : theme.palette.secondary.main,
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: attentionDemanding
      ? theme.palette.error.main
      : theme.palette.secondary.main,
    border: `2px solid ${
      attentionDemanding
        ? theme.palette.error.main
        : theme.palette.secondary.main
    }`,
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 20px ${
        attentionDemanding
          ? theme.palette.error.main
          : theme.palette.secondary.main
      }`,
    },
    "&.Mui-active": {
      boxShadow: `0px 0px 12px ${
        attentionDemanding
          ? theme.palette.error.main
          : theme.palette.secondary.main
      }`,
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      fontWeight: 700,
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: attentionDemanding
        ? theme.palette.error.main
        : theme.palette.secondary.main,
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&::before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  },
}));
interface Props {
  control: Control<IGanttChartProgress>;
  setValue: UseFormSetValue<IGanttChartProgress>;
  totalProgress: number;
  attentionDemanding: boolean;
}

export const ProgressSlider = ({
  control,
  totalProgress,
  attentionDemanding,
  setValue,
}: Props) => {
  const fields = useWatch({ control });
  const { progressIncrement } = fields;

  return (
    <Stack direction="column" alignItems="center">
      <Controller
        name="progress"
        control={control}
        render={({ field }) => (
          <PrettoSlider
            {...field}
            value={totalProgress + Number(progressIncrement)}
            min={0}
            max={100}
            step={1}
            valueLabelDisplay="auto"
            attentionDemanding={attentionDemanding}
            onChange={(_, value) => {
              if (value >= Number(totalProgress || 0)) {
                setValue("progressIncrement", Number(value) - totalProgress);
                setValue("totalValue", Number(value));
              }
            }}
            sx={{ width: "100%" }}
          />
        )}
      />
      <Stack
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1" color="text.secondary" fontWeight="bold">
          0%
        </Typography>
        <Typography variant="body1" color="text.secondary" fontWeight="bold">
          100%
        </Typography>
      </Stack>
    </Stack>
  );
};
