import React from "react";

import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { IGanttChartLineData } from "@types";

import {
  ProcessInfoCommentSection,
  ProcessInfoCommentText,
  ProcessInfoItem,
  ProcessInfoWrapper,
} from "./styles";

interface Props {
  data: IGanttChartLineData;
  isCompleted: boolean;
}

export const ProgressInfo = ({ data, isCompleted }: Props) => {
  const arrayModifier = React.useMemo(() => {
    const array = data.progressInfos ? data.progressInfos : [];
    let accumulatedProgress = 0;

    const newArr = array.map((item) => {
      accumulatedProgress += item.progressIncrement;
      return {
        ...item,
        currentProcess: accumulatedProgress,
        attentionDemanding: data.attentionDemanding,
      };
    });
    return [...newArr].reverse();
  }, [data]);

  return (
    <ProcessInfoWrapper>
      {arrayModifier
        ? arrayModifier.map((item, index) => {
            return (
              <ProcessInfoItem
                key={index}
                $attentionDemanding={item.attentionDemanding}
                $isCompleted={isCompleted}
              >
                <Grid container>
                  <Grid item xs={2} className="progressIncrement">
                    <Typography
                      variant="body1"
                      fontWeight={700}
                      color="primary.contrastText"
                      sx={{ borderBottom: "1px solid #fff" }}
                    >
                      {item.currentProcess}%
                    </Typography>
                    <Typography variant="body3" color="primary.contrastText">
                      {item.progressIncrement}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <ProcessInfoCommentSection>
                      <ProcessInfoCommentText>
                        <Tooltip
                          arrow
                          placement="top"
                          disableInteractive
                          title={item.comment}
                        >
                          <Typography variant="body3" fontWeight={600}>
                            {item.comment}
                          </Typography>
                        </Tooltip>
                      </ProcessInfoCommentText>
                      <Stack textAlign="end">
                        <Typography variant="body4" color="text.secondary">
                          {getLocaleDateFormat(item.createdAt, "hh:mm")}
                        </Typography>
                      </Stack>
                    </ProcessInfoCommentSection>
                  </Grid>
                </Grid>
              </ProcessInfoItem>
            );
          })
        : null}
    </ProcessInfoWrapper>
  );
};
