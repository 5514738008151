import React, { useEffect, useState } from "react";

import { useQueryParams } from "app";

import { Stack } from "@mui/material";
import { getUrlParam } from "@sbm/fe-utils";

import { TableProps } from "../../types";
import { FilterButton } from "./FilterButton";

export const SelectedFiltersButton: React.FC<
  Pick<TableProps, "filterableFields">
> = ({ filterableFields }) => {
  const { get, append, set, remove } = useQueryParams();

  const [activeFilter, setActiveFilter] = React.useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [filterQueries, setFilterQueries] = useState<string[]>([]);

  const handleClear = (field: string) => {
    const searchFromUrl = getUrlParam("search") as string | null;

    const filterQueriesFromUrl = get("filter", true) as string[];
    const newFiltersToSet = filterQueriesFromUrl?.filter(
      (i) => !i.includes(field)
    );

    remove("filter");

    setFilterQueries(newFiltersToSet);
    newFiltersToSet.forEach((filterBy) => {
      append("filter", filterBy);
    });

    if (searchFromUrl) {
      set("search", searchFromUrl);
    } else {
      remove("search");
    }
  };

  const handleOpenSelection = (elem: HTMLDivElement | null, field: string) => {
    setAnchorEl(elem);
    setActiveFilter(field);
  };

  const formatQuery = (query: string) => {
    const queryParts = query.split("=");

    const filterField = queryParts[0];
    const filterBy = queryParts[1];

    const filterData = filterableFields?.find((i) => i.field === filterField);

    return {
      title: filterData?.title || "",
      value: filterBy,
      field: filterData?.field || "",
    };
  };

  useEffect(() => {
    const filterQueriesFromUrl = get("filter", true) as string[];

    if (filterQueriesFromUrl) {
      setFilterQueries(filterQueriesFromUrl);
    }
  }, [get]);

  if (!filterQueries?.length) return null;

  return (
    <Stack flexDirection="row" alignItems="center" flexWrap="wrap" gap={2}>
      {filterQueries
        .filter((item) => {
          const filterKey = item.split("=")[0];
          const filterableFieldsKeys = filterableFields?.map((i) => i.field);
          return filterableFieldsKeys?.includes(filterKey);
        })
        .sort((a, b) => {
          const { title: titleA } = formatQuery(a);
          const { title: titleB } = formatQuery(b);
          return titleA.toLowerCase().localeCompare(titleB.toLowerCase());
        })
        .map((query, index) => {
          const { title, value, field } = formatQuery(query);

          return (
            <FilterButton
              key={title + index}
              title={title}
              value={value}
              field={field}
              onClear={handleClear}
              onOpen={handleOpenSelection}
              anchorEl={anchorEl}
              activeFilter={activeFilter}
              filterableFields={filterableFields || []}
            />
          );
        })}
    </Stack>
  );
};
