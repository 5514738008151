import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

import ContractCard_image_en from "../../../../../../../../../assets/backgrounds/contract/ContractCard_en.svg";
import ContractCard_image_ru from "../../../../../../../../../assets/backgrounds/contract/ContractCard_outer_chapter_ru.svg";

export const CCWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    91deg,
    ${({ theme }) => hexToRgba(theme.palette.primary.main, 0.12)} 0%,
    ${({ theme }) => hexToRgba(theme.palette.primary.main, 0.01)} 100%
  );
  border-bottom: 4px solid ${({ theme }) => theme.palette.primary.main};

  color: ${({ theme }) => theme.palette.text.secondary};
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;
export const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const CCContent = styled.div`
  ${FLEX_COLUMN};

  .title {
    min-height: 22px;
  }

  .subtitle {
    min-height: 24px;
    max-width: 100px;
  }

  .title-text {
    ${FLEX_COLUMN};
  }

  position: relative;
  z-index: 9;
`;

export const CardImageWrapper = styled.div<{ $isEnglish: boolean }>`
  background-image: ${({ $isEnglish }) =>
    $isEnglish
      ? `url(${ContractCard_image_en})`
      : `url(${ContractCard_image_ru})`};
  background-position: center;
  height: 219px;
  width: 444px;
  background-repeat: no-repeat;
`;

export const ImageWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: end;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;

export const CountWrapper = styled.div`
  width: 40px;
  margin-left: ${({ theme }) => theme.spacing()};
`;
