import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import * as echarts from "echarts";

import { Stack, Typography, useTheme } from "@mui/material";
import { IChartDataTypeA } from "@types";

export const BarChart = ({
  uniqueId,
  data,
}: {
  uniqueId?: number | string;
  data: IChartDataTypeA;
}) => {
  const theme = useTheme();
  const { t } = useTranslation("project");

  useEffect(() => {
    const chartDom = document.getElementById(`treemapChart${uniqueId}`);
    if (!chartDom) return;

    const chart = echarts.init(chartDom);

    const option: echarts.EChartsOption = {
      tooltip: {
        show: true,
        confine: true,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        textStyle: {
          color: theme.palette.text.primary,
          fontSize: 14,
        },
        position: (point, params, dom, rect, size) => {
          const x = point[0];
          const y = point[1];
          return [x, y - 40];
        },
        formatter: (params: any) => {
          return `<div style="display: flex; flex-direction: row; align-items: center; gap: 8px;
                  min-width: 150px; background: ${theme.palette.primary.main};
                  border-radius: 8px; white-space: normal; word-break: break-word;">
              <div style="color: #fff; flex: 1;">${params.name}</div>
              <div style="background: #fff; height: 20px; padding: 4px 8px;
                          border-radius: 8px; display: flex; align-items: center; justify-content: center;">
                  <strong style="color: #1B2C41">${params.value}</strong>
              </div>
            </div>`;
        },
      },
      xAxis: {
        type: "category",
        data: data?.leftSubParameters?.map((item) => item.name),
        show: false,
      },
      yAxis: {
        type: "value",
        show: false,
        max: data?.leftParameter.value,
      },
      series: [
        {
          type: "bar",
          barWidth: 50,
          data: data?.leftSubParameters?.map((item) => ({
            value: item.value === 0 ? 0.1 : item.value,
            itemStyle: {
              color: theme.palette.secondary.main,
              borderRadius: [8, 8, 0, 0],
            },
            label: {
              show: true,
              position: "top",
              color: theme.palette.secondary.main,
              fontSize: 14,
              fontWeight: 600,
              formatter: item.value === 0 ? "0" : "{c}",
            },
          })),
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [uniqueId, theme, data]);

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      <div
        id={`treemapChart${uniqueId}`}
        style={{
          width: "300px",
          height: "240px",
          marginBottom: "-50px",
        }}
      />
      {data?.leftParameter.name && (
        <Typography
          variant="body2"
          fontWeight={600}
          color={theme.palette.text.secondary}
          sx={{ opacity: 0.7, marginTop: "-12px" }}
        >
          {t(`charts_status.${data.leftParameter?.name}`)}
        </Typography>
      )}
    </Stack>
  );
};
