import React from "react";

import { useGetGanttChartLineData } from "api";
import { useAppSelector } from "app";

import { DrawerWithDataVariantsEnum } from "@types";

import { GanttChartProgressForm } from "./GanttChartProgressForm";

export const GanttChartProgressContainer = () => {
  const { drawerWithData } = useAppSelector((state) => state.global);
  const lineId = drawerWithData?.content?.lineId;

  const { data } = useGetGanttChartLineData(lineId);

  if (
    drawerWithData?.variant !== DrawerWithDataVariantsEnum.ganttChartProgress ||
    !data
  ) {
    return null;
  }

  return <GanttChartProgressForm lineId={lineId} data={data} />;
};
