import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetProjectParticipants } from "api";
import { openDrawerWithData, setModal, useAppDispatch } from "app";
import { Icon } from "ui-kit";

import {
  ListItemIcon,
  useTheme,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { DrawerWithDataVariantsEnum, ModalVariants } from "@types";

export const GanttChartTableActions = ({
  ganttChartLineId,
}: {
  ganttChartLineId: number;
}) => {
  const theme = useTheme();
  const { t } = useTranslation("project");
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const { id: constructionComplexId } = useParams();

  const { data: userProjectParticipants } = useGetProjectParticipants(
    Number(constructionComplexId)
  );

  const hasUserParticipants = useMemo(() => {
    return Boolean(userProjectParticipants?.length);
  }, [userProjectParticipants]);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetPlannedDate = () => {
    handleClose();
    if (!hasUserParticipants) {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.chartPlannedDate,
        })
      );
    } else {
      dispatch(
        openDrawerWithData({
          open: true,
          variant: DrawerWithDataVariantsEnum.assignmentPlannedDates,
          content: {
            data: {
              chartId: ganttChartLineId,
            },
          },
        })
      );
    }
  };

  const handleSetActualDate = () => {
    handleClose();
    if (!hasUserParticipants) {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.chartActualDate,
        })
      );
    } else {
      dispatch(
        openDrawerWithData({
          variant: DrawerWithDataVariantsEnum.assignmentActualDates,
          content: {
            data: {
              ganttChartLineId,
            },
          },
        })
      );
    }
  };

  const handleOpenDrawer = () => {
    dispatch(
      openDrawerWithData({
        variant: DrawerWithDataVariantsEnum.createGanttChartRow,
        content: { ganttChartLineId },
      })
    );
    handleClose();
  };

  return (
    <div>
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.primary.main}
      />

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem sx={{ px: 4, py: 2 }} onClick={handleOpenDrawer}>
          <ListItemIcon>
            <Icon name="Plus" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">{t("add_row")}</Typography>
        </MenuItem>
        <MenuItem sx={{ px: 4, py: 2 }}>
          <ListItemIcon>
            <Icon name="CopyIcon" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">{t("edit_row")}</Typography>
        </MenuItem>
        <MenuItem sx={{ px: 4, py: 3 }} onClick={handleSetActualDate}>
          <ListItemIcon>
            <Icon name="Calendar" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">
            {t("actual_dates.set_actual_dates")}
          </Typography>
        </MenuItem>
        {/*<MenuItem sx={{ px: 4, py: 3 }} onClick={handleSetPlannedDate}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Icon name="Calendar" color={theme.palette.primary.main} />*/}
        {/*  </ListItemIcon>*/}
        {/*  <Typography variant="body1">*/}
        {/*    {t("assignmentDate.set_planned_dates")}*/}
        {/*  </Typography>*/}
        {/*</MenuItem>*/}
      </Menu>
    </div>
  );
};
