import { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  closeDrawer,
  setChangeLetterCreationStep,
  TransitionPrompt,
  UI_CONFIG,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import { Stack, useTheme } from "@mui/material";
import { Button } from "@sbm/ui-components";
import {
  DrawerTypes,
  DrawerVariantsEnum,
  IMailData,
  IRecipients,
  MethodOfSendingEnum,
} from "@types";

import { MailSendingStepper, MethodStep, SendingStep } from "./components";
import { InitialMessageFields } from "./constants";

interface SendMailContainerProps {
  recipients: IRecipients[];
  canSendViaOrganizationEmail?: boolean;
}

export const SendMailContainer: React.FC<SendMailContainerProps> = ({
  recipients,
  canSendViaOrganizationEmail,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("correspondence");
  const { drawer } = useAppSelector((state) => state.global);
  const { letterCreationStep } = useAppSelector(
    (state) => state.correspondence
  );

  const [showPrompt, setShowPrompt] = useState(false);

  const defaultMessages = useMemo(
    () =>
      recipients?.map((item) => ({
        ...InitialMessageFields,
        personalDataLongName: item.personalDataLongName,
        briefTextOrganizationName: item.briefTextOrganizationName,
        sendFromSystem: !!(
          item.externalSubstitutionId ||
          item.employeeId ||
          canSendViaOrganizationEmail
        ),
        ...((item.externalSubstitutionId || item.employeeId) &&
        canSendViaOrganizationEmail
          ? { methodOfSending: MethodOfSendingEnum.viaOrganizationEmail }
          : {}),
        organizationId: item.organizationId,
        personalDataId: item.personalDataId,
      })),
    [recipients, canSendViaOrganizationEmail]
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { dirtyFields },
  } = useForm<IMailData>({
    defaultValues: {
      messages: defaultMessages,
    },
  });

  const { messages } = useWatch({ control });

  const isNextDisabled = messages?.some(
    (item) =>
      !item.methodOfSending || (!item.sendFromSystem && !item.sendFromService)
  );

  const handleClose = () => {
    reset();
    dispatch(closeDrawer());
    handleClosePrompt();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (Object.values(dirtyFields).length > 0) {
      setShowPrompt(true);
      return;
    }

    handleClose();
  };

  const handleNextStep = () => {
    dispatch(setChangeLetterCreationStep(1));
  };

  const handleBackStep = () => {
    dispatch(setChangeLetterCreationStep(-1));
  };

  const renderFormContent = () => {
    switch (letterCreationStep) {
      case 0:
        return (
          <MethodStep
            messages={messages}
            register={register}
            control={control}
          />
        );
      case 1:
        return (
          <SendingStep
            messages={messages}
            control={control}
            setValue={setValue}
            register={register}
          />
        );
      default:
        return null;
    }
  };

  if (drawer !== DrawerVariantsEnum.sendMail) return null;

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleClose}
        />
      )}

      <Drawer
        anchor="right"
        open={Boolean(drawer)}
        onClose={handleCancel}
        resizable={false}
        width={UI_CONFIG.rightDrawerWidth}
        type={DrawerTypes.createLetterDrawer}
        title={t("send_mail")}
        actions={
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              {!letterCreationStep ? null : (
                <Button
                  variant="text"
                  size="medium"
                  color="secondary"
                  sx={{ fontSize: "15px" }}
                  onClick={handleBackStep}
                  startIcon={
                    <Icon
                      name="ArrowLeft"
                      color={theme.palette.secondary.main}
                    />
                  }
                >
                  {t("back")}
                </Button>
              )}
            </Stack>
            <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={handleCancel}
              >
                {t("cancel")}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={isNextDisabled}
                onClick={letterCreationStep === 1 ? undefined : handleNextStep}
              >
                {letterCreationStep === 1 ? t("save") : t("next")}
              </Button>
            </Stack>
          </Stack>
        }
      >
        <Stack position="relative">
          <MailSendingStepper />

          <form noValidate style={{ marginTop: "90px" }}>
            {renderFormContent()}
          </form>
        </Stack>
      </Drawer>
    </>
  );
};
