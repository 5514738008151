import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

export const AbbreviationWrapper = styled.div`
  ${FLEX_ROW_FULL};
  border: 1px solid ${({ theme }) => theme.palette.text.primary};
  height: 25px;
  min-width: 35px;
  border-radius: ${({ theme }) => theme.spacing()};
  gap: ${({ theme }) => theme.spacing(2)};
`;
