import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";
import {
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
} from "@types";

import { FormContent } from "./FormContent";

export const AddFileToIncomingDocumentPopup = ({
  open,
  onClose,
  onAddFile,
  isServiceNote = false,
  disabledFileStatus = false,
}: {
  open: boolean;
  onClose: () => void;
  onAddFile: (
    file: File[],
    typeOfAttachment: FileAttachedToCorrespondenceTypeOfAttachmentEnum,
    fileStatusAttachment: FileAttachedToCorrespondenceFileStatusEnum
  ) => void;
  isServiceNote?: boolean;
  disabledFileStatus?: boolean;
}) => {
  const { t } = useTranslation("attachments");

  const [selectedFile, setSelectedFile] = React.useState<File[] | null>(null);
  const [typeOfAttachment, setTypeOfAttachment] = React.useState<
    FileAttachedToCorrespondenceTypeOfAttachmentEnum | undefined
  >(undefined);
  const [fileStatusAttachment, setFileStatusAttachment] = React.useState<
    FileAttachedToCorrespondenceFileStatusEnum | undefined
  >(undefined);

  const handleSelectFile = (
    file: File[],
    typeOfAttachment: FileAttachedToCorrespondenceTypeOfAttachmentEnum,
    fileStatusAttachment: FileAttachedToCorrespondenceFileStatusEnum
  ) => {
    setSelectedFile(file);
    setTypeOfAttachment(typeOfAttachment);
    setFileStatusAttachment(fileStatusAttachment);
  };

  const content = {
    body: (
      <FormContent
        file={selectedFile}
        onClose={onClose}
        onAddFile={handleSelectFile}
        isServiceNote={isServiceNote}
        disabledFileStatus={disabledFileStatus}
      />
    ),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        disabled={
          !selectedFile ||
          !selectedFile.length ||
          !typeOfAttachment ||
          (!disabledFileStatus && !fileStatusAttachment)
        }
        onClick={() => {
          onClose();
          onAddFile(selectedFile!, typeOfAttachment!, fileStatusAttachment!);
        }}
      >
        {t("add")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
