import styled from "styled-components";
import { FLEX_BETWEEN } from "ui-kit";

import { Card } from "@mui/material";

export const ProgressSliderWrapper = styled(Card)`
  padding: ${({ theme }) => theme.spacing(1, 4, 4, 4)};
  position: relative;
  width: 100%;
  overflow: visible;
  z-index: 5;
`;

export const SwitcherWrapper = styled.div<{ $isCompleted: boolean }>`
  ${FLEX_BETWEEN};
  justify-content: ${({ $isCompleted }) =>
    $isCompleted ? "end" : "space-between"};
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(4, 0, 2, 0)};
`;

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.spacing(0, 0, 2, 2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;
