import { TFunction } from "i18next";

import { Theme } from "@mui/material";
import { TaskOrEmpty } from "@wamra/gantt-task-react";

const EmptyTask: TaskOrEmpty = {
  id: `${Math.random()}`,
  name: "",
  type: "empty",
};

export const getPlannedChartRows = (
  item: any,
  t: TFunction,
  theme: Theme
): TaskOrEmpty => {
  if (!item) return EmptyTask;

  const {
    id,
    plannedStartDate,
    plannedCompletionDate,
    headerOfChapter,
    nameOfLine,
  } = item;

  const projectStyles: Partial<TaskOrEmpty["styles"]> = {
    projectProgressColor: "transparent",
    projectProgressSelectedColor: "transparent",
    projectBackgroundColor: theme.palette.primary.light,
    projectBackgroundSelectedColor: theme.palette.primary.light,
  };

  const commonData: Partial<TaskOrEmpty> & {
    context: { typeOfDate: string; item: any };
  } = {
    id: `${id}`,
    progress: 100, //headerOfChapter ? 100 : totalProgress ?? 100,
    type: headerOfChapter ? "project" : "task",
    isDisabled: true,
    name: nameOfLine,
    context: {
      typeOfDate: `${t("gantt_chart_tooltip.planned_period")}`,
      ...item,
    },
  };

  if (plannedStartDate && plannedCompletionDate) {
    return {
      ...commonData,
      start: new Date(plannedStartDate),
      end: new Date(plannedCompletionDate),
      styles: {
        ...projectStyles,
        barProgressColor: "#CED1D6",
        barProgressSelectedColor: "#9FA2A4FF",
      },
    } as TaskOrEmpty;
  } else {
    return {
      id: `${id}`,
      name: nameOfLine,
      type: "empty",
    };
  }
};

export const getActualChartRows = (
  item: any,
  t: TFunction,
  theme: Theme
): TaskOrEmpty => {
  if (!item) return EmptyTask;

  const {
    id,
    headerOfChapter,
    expectedStartDate,
    expectedCompletionDate,
    actualStartDate,
    actualCompletionDate,
    nameOfLine,
    totalProgress,
  } = item;

  const projectStyles = {
    projectProgressColor: "white",
    projectProgressSelectedColor: "white",
    projectBackgroundColor: theme.palette.primary.light,
    projectBackgroundSelectedColor: theme.palette.primary.light,
  };

  const commonData: Partial<TaskOrEmpty> & {
    context: { typeOfDate: string; item: any };
  } = {
    id: `${id}`,
    progress: headerOfChapter ? 0 : totalProgress ?? 100,
    type: headerOfChapter ? "project" : "task",
    isDisabled: true,
    name: nameOfLine,
    context: {
      typeOfDate: `${t("gantt_chart_tooltip.actual_period")}`,
      ...item,
    },
  };

  // Case 1 - Expected dates
  if (expectedStartDate && expectedCompletionDate) {
    return {
      ...commonData,
      start: new Date(expectedStartDate),
      end: new Date(expectedCompletionDate),
      styles: {
        ...projectStyles,
        // @ts-ignore
        barProgressColor: theme.palette.primary["8p"],
        // @ts-ignore
        barProgressSelectedColor: theme.palette.primary["8p"],
      },
    } as TaskOrEmpty;
  }
  // Case 2 - Actual Start & Expected End
  else if (actualStartDate && expectedCompletionDate) {
    return {
      ...commonData,
      start: new Date(actualStartDate),
      end: new Date(expectedCompletionDate),
      styles: {
        ...projectStyles,
        barProgressColor: theme.palette.secondary.main,
        barProgressSelectedColor: theme.palette.secondary.main,
        // @ts-ignore
        barBackgroundColor: theme.palette.secondary["30p"],
        // @ts-ignore
        barBackgroundSelectedColor: theme.palette.secondary["12p"],
      },
    } as TaskOrEmpty;
  }
  // Case 3 - Actual dates
  else if (actualStartDate && actualCompletionDate) {
    return {
      ...commonData,
      start: new Date(actualStartDate),
      end: new Date(actualCompletionDate),
      styles: {
        ...projectStyles,
        barProgressColor: theme.palette.success.main,
        barProgressSelectedColor: theme.palette.success.dark,
      },
    } as TaskOrEmpty;
  }
  // Case 4 - Empty task
  return {
    id: `${id}`,
    name: nameOfLine,
    type: "empty",
  };
};
