import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import * as echarts from "echarts";

import { Stack, Typography, useTheme } from "@mui/material";
import { IChartDataTypeA } from "@types";

export const SingleBarChart = ({
  uniqueId,
  data,
}: {
  uniqueId?: number | string;
  data: IChartDataTypeA;
}) => {
  const theme = useTheme();
  const { t } = useTranslation("project");

  const dataAdapter = useMemo(() => {
    if (!data) {
      return [];
    }
    const arr = [];
    arr.push(data.rightParameter);
    return arr;
  }, [data]);

  useEffect(() => {
    const chartDom = document.getElementById(`treemapChart${uniqueId}`);
    if (!chartDom) return;

    const chart = echarts.init(chartDom);

    const option: echarts.EChartsOption = {
      xAxis: {
        type: "category",
        data: dataAdapter,
        show: false,
      },
      yAxis: {
        type: "value",
        show: false,
        max: data?.rightParameter.value,
      },
      series: [
        {
          type: "bar",
          data: dataAdapter,
          barWidth: 50,
          itemStyle: {
            color: theme.palette.text.disabled,
            borderRadius: [8, 8, 0, 0],
          },
          label: {
            show: true,
            position: "top",
            color: theme.palette.text.disabled,
            fontSize: 14,
            fontWeight: 600,
          },
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [dataAdapter, uniqueId, theme, data]);

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      <div
        id={`treemapChart${uniqueId}`}
        style={{
          width: "140px",
          height: "240px",
          marginBottom: "-50px",
        }}
      />
      {data?.rightParameter.name && (
        <Typography
          variant="body2"
          fontWeight={600}
          color={theme.palette.text.secondary}
          sx={{ opacity: 0.7, borderRadius: "8px", marginTop: "-12px" }}
        >
          {t(`charts_status.${data.rightParameter?.name}`)}
        </Typography>
      )}
    </Stack>
  );
};
