import React, { useMemo } from "react";

import { useGetWidgets } from "api";
import { ChartTypesWrapper, DotsLoader, getIconName } from "ui-kit";

import { Grid } from "@mui/material";
import {
  TemplateNumberForWidgetEnum,
  TIconNames,
  WidgetModuleEnum,
} from "@types";

import { getDescription, getHref, getMaintanenceMode } from "./helpers";

export const DocumentsContainer = React.memo(() => {
  const { data: widgets = [], isLoading } = useGetWidgets(
    WidgetModuleEnum.documents
  );

  const memoizedWidgets = useMemo(() => widgets, [widgets]);

  const widgetsToShow = useMemo(() => {
    return memoizedWidgets
      .sort((a, b) => (a.serialNumber || 1) - (b.serialNumber || 1))
      .map((w) => {
        const serviceId = w.serviceId!;

        return {
          id: w.id,
          switcherOn: w.switcherOn,
          title: w.widgetName,
          maintenanceMode: getMaintanenceMode(serviceId),
          icon: getIconName(serviceId),
          href: getHref(serviceId),
          description: getDescription(serviceId),
          templateNumberForWidget: w.templateNumberForWidget,
        };
      }) as {
      title: string;
      id: number;
      switcherOn: boolean;
      maintenanceMode: boolean | undefined;
      icon: string | undefined;
      href: string | undefined;
      description: JSX.Element | null;
      templateNumberForWidget: TemplateNumberForWidgetEnum;
    }[];
  }, [memoizedWidgets]);

  if (isLoading) return <DotsLoader />;

  return (
    <Grid container spacing={5}>
      {widgetsToShow.map(
        ({ title, icon, href, templateNumberForWidget, id, switcherOn }) => {
          return (
            <Grid key={id} item xs={12} sm={12} md={6} xl={4}>
              <ChartTypesWrapper
                title={title}
                href={href}
                id={id}
                icon={icon as TIconNames | null}
                switcherOn={switcherOn}
                templateNumberForWidget={templateNumberForWidget}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
});
