import styled from "styled-components";
import { FLEX_COLUMN_FULL, FLEX_ROW_FULL } from "ui-kit";

import { keyframes } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

export const ImageWrapper = styled.div`
  ${FLEX_ROW_FULL};
  background-color: ${({ theme }) =>
    hexToRgba(theme.palette.primary.main, 0.08)};
  border-radius: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing()};
  height: 130px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  height: 100%;
`;

const expandWidth = keyframes`
  from {
    width: 10%;
  }
  to {
    width: 100%;
  }
`;

export const VerticalDivider = styled.div`
  ${FLEX_COLUMN_FULL};
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  margin: ${({ theme }) => theme.spacing(4, 0, 4, 0)};
  animation: ${expandWidth} 2s ease-in-out forwards;
`;
