import { TFunction } from "i18next";

import { IFactDates, IGanttChartActualDate } from "@types";

export const getStartDateConfig = (
  data: IGanttChartActualDate | undefined,
  t: TFunction
): { title: string; value: Date | null | undefined } => {
  if (data?.expectedStartDate) {
    return {
      title: t("actual_dates.expected_start_date"),
      value: data?.expectedStartDate,
    };
  } else if (data?.actualStartDate) {
    return {
      title: t("actual_dates.actual_start_date"),
      value: data?.actualStartDate,
    };
  } else {
    return {
      title: t("actual_dates.planned_start_date"),
      value: data?.plannedStartDate,
    };
  }
};

export const getCompletionDateConfig = (
  data: IGanttChartActualDate | undefined,
  t: TFunction
): { title: string; value: Date | null | undefined } => {
  if (data?.expectedCompletionDate) {
    return {
      title: t("actual_dates.expected_completion_date"),
      value: data?.expectedCompletionDate,
    };
  } else if (data?.actualCompletionDate) {
    return {
      title: t("actual_dates.actual_competion_date"),
      value: data?.actualCompletionDate,
    };
  } else {
    return {
      title: t("actual_dates.planned_completion_date"),
      value: data?.plannedCompletionDate,
    };
  }
};

export const getStartDateOptionConfig = (
  data: IGanttChartActualDate | undefined
): {
  selected: "expected" | "actual";
  actualDateDisabled: boolean;
  expectedDateDisabled: boolean;
} => {
  if (!data?.expectedStartDate && !data?.actualStartDate) {
    return {
      selected: "expected",
      actualDateDisabled: false,
      expectedDateDisabled: false,
    };
  } else if (data?.expectedStartDate && !data?.actualStartDate) {
    return {
      selected: "expected",
      actualDateDisabled: false,
      expectedDateDisabled: false,
    };
  } else if (data?.actualStartDate) {
    return {
      selected: "actual",
      actualDateDisabled: false,
      expectedDateDisabled: true,
    };
  } else {
    return {
      selected: "expected",
      actualDateDisabled: false,
      expectedDateDisabled: false,
    };
  }
};

export const getCompletionDateOptionConfig = (
  data: IGanttChartActualDate | undefined,
  startDateOption: "expected" | "actual" | undefined
) => {
  if (
    !data?.expectedCompletionDate &&
    !data?.actualCompletionDate &&
    startDateOption === "expected"
  ) {
    return {
      actualDateDisabled: true,
      expectedDateDisabled: false,
    };
  } else if (
    !data?.expectedCompletionDate &&
    !data?.actualCompletionDate &&
    startDateOption === "actual"
  ) {
    return {
      actualDateDisabled: false,
      expectedDateDisabled: false,
    };
  } else if (
    data?.expectedCompletionDate &&
    !data?.actualCompletionDate &&
    startDateOption === "expected"
  ) {
    return {
      actualDateDisabled: true,
      expectedDateDisabled: false,
    };
  } else if (
    data?.expectedCompletionDate &&
    !data?.actualCompletionDate &&
    startDateOption === "actual"
  ) {
    return {
      actualDateDisabled: false,
      expectedDateDisabled: false,
    };
  } else if (data?.actualCompletionDate && startDateOption === "actual") {
    return {
      actualDateDisabled: false,
      expectedDateDisabled: true,
    };
  }
};

export const getStructuredPayload = (
  data: IFactDates & {
    startDateOption: "expected" | "actual";
    completionDateOption: "expected" | "actual";
  }
): Partial<IFactDates> => {
  const result: Partial<IFactDates> = {};

  const startDateKey =
    data.startDateOption === "expected"
      ? "expectedStartDate"
      : "actualStartDate";
  if (data[startDateKey]) {
    result[startDateKey] = data[startDateKey];
  }

  const completionDateKey =
    data.completionDateOption === "expected"
      ? "expectedCompletionDate"
      : "actualCompletionDate";
  if (data[completionDateKey]) {
    result[completionDateKey] = data[completionDateKey];
  }

  return result;
};

const getIsRequiredFieldDirty = (
  dirtyFields: Partial<
    Readonly<{
      actualStartDate?: boolean | undefined;
      actualCompletionDate?: boolean | undefined;
      expectedStartDate?: boolean | undefined;
      expectedCompletionDate?: boolean | undefined;
      startDateOption?: boolean | undefined;
      completionDateOption?: boolean | undefined;
    }>
  >
): boolean => {
  return Object.keys(dirtyFields).some(
    (key) => key !== "startDateOption" && key !== "completionDateOption"
  );
};

export const getIsSaveDisabled = (
  values: IFactDates & {
    startDateOption: "expected" | "actual";
    completionDateOption: "expected" | "actual";
  },
  dirtyFields: Partial<
    Readonly<{
      actualStartDate?: boolean | undefined;
      actualCompletionDate?: boolean | undefined;
      expectedStartDate?: boolean | undefined;
      expectedCompletionDate?: boolean | undefined;
      startDateOption?: boolean | undefined;
      completionDateOption?: boolean | undefined;
    }>
  >
) => {
  const {
    startDateOption,
    completionDateOption,
    actualStartDate,
    expectedStartDate,
    actualCompletionDate,
    expectedCompletionDate,
  } = values;

  const isStartDateValid =
    (startDateOption === "expected" && !!expectedStartDate) ||
    (startDateOption === "actual" && !!actualStartDate);

  const isCompletionDateValid =
    (completionDateOption === "expected" && !!expectedCompletionDate) ||
    (completionDateOption === "actual" && !!actualCompletionDate);

  const isRequiredFieldDirty = getIsRequiredFieldDirty(dirtyFields);

  return isStartDateValid && isCompletionDateValid && isRequiredFieldDirty;
};
