import { ILetterMessages } from "@types";

export const InitialMessageFields: ILetterMessages = {
  sendDate: undefined,
  fileId: undefined,
  note: undefined,
  methodOfSending: undefined,
  senderEmailId: undefined,
  recipientEmailId: undefined,
  courierServiceName: undefined,
  sendFromSystem: false,
  sendFromService: false,
};
