import React, { useEffect, useState } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { fetchWorkingDays } from "api";
import { useDebounce } from "app";
import { format, set } from "date-fns";
import { DatePicker, TimePicker } from "ui-kit";

import { Grid } from "@mui/material";
import { Input } from "@sbm/ui-components";
import { IRegisterSupportRequestPayload } from "@types";

interface IWorkingDateData {
  date: string;
  endDate: string;
  startDate: string;
  workingDays: number;
  calendarDays: number;
}
interface Props {
  control: Control<IRegisterSupportRequestPayload>;
  setValue: UseFormSetValue<IRegisterSupportRequestPayload>;
}

const getDate = (date: string) => {
  if (!date) return new Date();

  const [day, month, year] = date.split(".").map(Number);
  return new Date(year, month - 1, day); // Month is 0-based
};

export const RequestDateScheduler: React.FC<Props> = ({
  control,
  setValue,
}) => {
  const { t } = useTranslation("tasks");
  const {
    calendarDaysForExecution,
    workingDaysForExecution,
    controlDate,
    controlTime,
  } = useWatch({
    control,
  });

  const [time, setTime] = useState(controlTime);
  const [date, setDate] = useState(getDate(controlDate as string));

  const [workingDays, setWorkingDays] = useState<string | number>(
    workingDaysForExecution || ""
  );
  const [calendarDays, setCalendarDays] = useState<string | number>(
    calendarDaysForExecution || ""
  );

  const debouncedWorkingDays = useDebounce(workingDays, 1000);
  const debouncedCalendarDays = useDebounce(calendarDays, 1000);

  const joinDateAndTime = (time: string, date?: Date): Date | undefined => {
    if (!date) {
      return date;
    }
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return set(date, { hours, minutes, seconds });
  };

  const setTimeToMidnightUTC = (date: Date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    ).toISOString();
  };

  const handleWorkingDateChanged = (data: IWorkingDateData) => {
    setValue("workingDaysForExecution", data.workingDays);
    setValue("calendarDaysForExecution", data.calendarDays);
  };

  useEffect(() => {
    if (Number(debouncedCalendarDays) !== calendarDays && controlDate) {
      const requestParams: Record<string, any> = {
        calendarDays: +debouncedCalendarDays,
      };

      fetchWorkingDays(requestParams).then((result) => {
        handleWorkingDateChanged(result);
        setWorkingDays(result.workingDays);
        setValue("controlDate", setTimeToMidnightUTC(new Date(result.date)));
        setDate(new Date(result.date));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCalendarDays]);

  useEffect(() => {
    if (Number(debouncedWorkingDays) !== workingDays && controlDate) {
      const requestParams: Record<string, any> = {
        workingDays: +debouncedWorkingDays,
      };

      fetchWorkingDays(requestParams).then((result) => {
        handleWorkingDateChanged(result);
        setCalendarDays(result.calendarDays);
        setValue("controlDate", setTimeToMidnightUTC(new Date(result.date)));
        setDate(new Date(result.date));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedWorkingDays]);

  useEffect(() => {
    if (calendarDays) {
      setValue("calendarDaysForExecution", Number(calendarDays));
      setCalendarDays(`${calendarDays}`);
    }
    if (workingDays) {
      setValue("workingDaysForExecution", Number(workingDays));
      setWorkingDays(`${workingDays}`);
    }
  }, [calendarDays, workingDays, setValue]);

  const handleDateChanged = async (newDate: Date) => {
    const requestParams: Record<string, any> = {};
    setValue("controlDate", setTimeToMidnightUTC(newDate), {
      shouldDirty: true,
    });
    requestParams.date = newDate.toISOString();

    const result = await fetchWorkingDays(requestParams);
    setValue("calendarDaysForExecution", result.calendarDays);
    setValue("workingDaysForExecution", result.workingDays);
    setCalendarDays(result.calendarDays);
    setWorkingDays(result.workingDays);
  };

  const handleTimeChanged = (newDate: Date) => {
    setTime(format(newDate, "HH:mm:ss"));
    setValue("controlTime", format(newDate, "HH:mm:ss"));
  };

  return (
    <Grid container columns={20} spacing={2}>
      <Grid item xs={7}>
        <DatePicker
          label={t("scheduled_date")}
          variant="outlined"
          size="medium"
          disablePast
          onDateChange={handleDateChanged}
          value={date}
          maxDate={null}
        />
      </Grid>
      <Grid item xs={5}>
        <TimePicker
          date={joinDateAndTime(time as string, date)}
          currentDate={date}
          onDateChange={handleTimeChanged}
          label={t("time")}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label={t("working_days")}
          variant="outlined"
          type="number"
          value={workingDays}
          required
          size="medium"
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 0) {
              setWorkingDays(e.target.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label={t("calendar_days")}
          variant="outlined"
          type="number"
          value={calendarDays}
          required
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 0) {
              setCalendarDays(e.target.value);
            }
          }}
          size="medium"
        />
      </Grid>
    </Grid>
  );
};
