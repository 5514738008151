import { useTranslation } from "react-i18next";

import { useMedia } from "app";
import { useTheme } from "styled-components";
import { Icon } from "ui-kit";

import { Stack, Tooltip, Typography } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

import chart_card_image from "../../../../../../src/assets/backgrounds/chart_card.svg";
import { CardImage, CardWrapper, EllipsisTextWrapper } from "./styles";

export const ChartCard = () => {
  const [{ lgDown, tabletDown }] = useMedia();
  const theme = useTheme();
  const { t } = useTranslation("project");

  return (
    <CardWrapper>
      <Stack width="100%" maxWidth={lgDown || tabletDown ? "100%" : "70%"}>
        <Stack flexDirection="row" alignItems="center" gap={4}>
          <Icon name={"ChartArea"} size={48} />
          <Stack gap={1}>
            <Typography
              variant="h8_bold"
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t("schedule_name")}
            </Typography>
            <Typography
              variant="body3"
              sx={{
                mb: 4,
                color: hexToRgba(theme.palette.primary.contrastText, 0.7),
              }}
            >
              №1849 от 20.02.2024
            </Typography>
          </Stack>
        </Stack>

        <Stack mt={14}>
          <Typography
            variant="subtitle2"
            fontWeight={600}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {t("content")}
          </Typography>
          <Typography
            variant="body3"
            sx={{
              mb: 4,
              color: hexToRgba(theme.palette.primary.contrastText, 0.7),
            }}
          >
            Lorem Ipsum-часто используемый в печати и вэб-дизайне
          </Typography>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t("planned_start_date")}
            </Typography>
            <Tooltip arrow placement="top" title={"sss"} disableInteractive>
              <EllipsisTextWrapper $height={17}>
                <Typography
                  variant="body3"
                  color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
                >
                  20.02.2024
                </Typography>
              </EllipsisTextWrapper>
            </Tooltip>
          </Stack>
          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t("actual_start_date")}
            </Typography>
            <Typography
              variant="body3"
              sx={{
                color: hexToRgba(theme.palette.primary.contrastText, 0.7),
              }}
            >
              20.02.2024
            </Typography>
          </Stack>
          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t("planned_completion_date")}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography
                variant="body3"
                sx={{
                  color: hexToRgba(theme.palette.primary.contrastText, 0.7),
                }}
              >
                20.02.2024
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t("actual_completion_date")}
            </Typography>

            <Stack direction="row" gap={1}>
              <Typography
                variant="body3"
                sx={{
                  color: hexToRgba(theme.palette.primary.contrastText, 0.7),
                }}
              >
                20.02.2024
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <CardImage src={chart_card_image} alt="chart card icon" />
      </Stack>
    </CardWrapper>
  );
};
