import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fetchWorkingDays } from "api";
import { useDebounce } from "app";
import { parseISO, set, format } from "date-fns";
import { TimePicker, DatePicker } from "ui-kit";

import { Grid } from "@mui/material";
import { Input } from "@sbm/ui-components";
import { TExecutionRequirement, TWorkingDaysEditMode } from "@types";

import { reformatDate } from "../../../../helpers";

export interface IWorkingDateData {
  date: string;
  time: string;
  workingDays: number;
  calendarDays: number;
}
interface Props {
  scheduledDateOfExecution: Date | string | undefined;
  scheduledTimeOfExecution?: string | undefined;
  workingDaysForExecution: number;
  calendarDaysForExecution?: number;
  onChange: (res: IWorkingDateData) => void;
  onChangeTime: (res: string) => void;
  requirements?: TExecutionRequirement;
  editMode?: TWorkingDaysEditMode;
  isResolutionForTheDocument?: boolean;
  disable?: boolean;
}

let cachedRes: null | IWorkingDateData = null;

export const WorkingDay: React.FC<Props> = ({
  scheduledDateOfExecution,
  workingDaysForExecution,
  scheduledTimeOfExecution,
  calendarDaysForExecution,
  onChange,
  onChangeTime,
  requirements,
  editMode,
  disable,
}) => {
  const [time, setTime] = useState(
    scheduledTimeOfExecution || requirements?.controlTime
  );

  const [date, setDate] = useState(
    scheduledDateOfExecution
      ? requirements?.controlDate
        ? new Date(`${reformatDate(requirements?.controlDate)}T00:00:00.000Z`)
        : new Date(scheduledDateOfExecution)
      : undefined
  );
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [workingDays, setWorkingDays] = useState<string>(
    workingDaysForExecution?.toString()
  );
  const [calendarDays, setCalendarDays] = useState<string>(
    calendarDaysForExecution ? calendarDaysForExecution?.toString() : ""
  );

  const joinDateAndTime = (time: string, date?: Date): Date | undefined => {
    if (!date) {
      return date;
    }
    const [hours, minutes, seconds] = time.split(":").map(Number);
    const updatedDate = set(date, { hours, minutes, seconds });
    return updatedDate;
  };

  const { t } = useTranslation("tasks");

  const debouncedWorkingDays = useDebounce(workingDays, 1000);
  const debouncedCalendarDays = useDebounce(calendarDays, 1000);

  useEffect(() => {
    if (!cachedRes || !debouncedCalendarDays || isFirstRender) {
      return;
    }
    if (cachedRes.calendarDays !== Number(debouncedCalendarDays)) {
      fetchWorkingDays({
        calendarDays: +debouncedCalendarDays,
      }).then((result) => {
        cachedRes = result;
        onChange(result);
        setWorkingDays(result.workingDays);
        const parsedDate = parseISO(result.date);
        const [hours, minutes, seconds] = result.time.split(":").map(Number);
        const dateTime = set(parsedDate, { hours, minutes, seconds });

        setDate(dateTime);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCalendarDays]);

  useEffect(() => {
    if (!cachedRes || !debouncedWorkingDays || isFirstRender) {
      return;
    }
    if (cachedRes.workingDays !== Number(debouncedWorkingDays)) {
      fetchWorkingDays({
        workingDays: +debouncedWorkingDays,
      }).then((result) => {
        cachedRes = result;
        setCalendarDays(result.calendarDays);
        setTime(result.time);
        const parsedDate = parseISO(result.date);
        const [hours, minutes, seconds] = result.time.split(":").map(Number);
        const dateTime = set(parsedDate, { hours, minutes, seconds });

        setDate(dateTime);
        onChange(result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedWorkingDays]);

  useLayoutEffect(() => {
    setIsFirstRender(false);

    if (
      (calendarDaysForExecution || calendarDaysForExecution === 0) &&
      (workingDaysForExecution || workingDaysForExecution === 0) &&
      scheduledDateOfExecution
    ) {
      return;
    }
    const fetchData = async () => {
      const res = await fetchWorkingDays({
        workingDays: workingDaysForExecution,
      });

      cachedRes = res;

      setCalendarDays(res.calendarDays);
      setTime(res.time);
      const parsedDate = parseISO(res.date);
      const [hours, minutes, seconds] = res.time.split(":").map(Number);
      const dateTime = set(parsedDate, { hours, minutes, seconds });

      setDate(dateTime);
      onChange(res);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChanged = async (newDate: Date) => {
    const dateAtMidnight = new Date(newDate);
    dateAtMidnight.setUTCHours(0, 0, 0, 0);

    const formattedDate = dateAtMidnight.toISOString().split("T")[0];

    const res = await fetchWorkingDays({
      date: `${formattedDate}T${time}.000Z`,
    });

    cachedRes = res;

    const parsedDate = parseISO(res.date);
    const [hours, minutes, seconds] = res.time.split(":").map(Number);
    const dateTime = set(parsedDate, { hours, minutes, seconds });

    setDate(dateTime);
    setCalendarDays(res.calendarDays);
    setTime(res.time);
    setWorkingDays(res.workingDays);
    onChange(res);
  };

  const handleTimeChanged = (newDate: Date) => {
    onChangeTime(format(newDate, "HH:mm:ss"));
    setTime(format(newDate, "HH:mm:ss"));
  };

  return (
    <Grid container my={6} columns={20} spacing={2}>
      <Grid item xs={7}>
        <DatePicker
          label={t("scheduled_date")}
          variant="outlined"
          size="medium"
          disablePast
          disable={disable}
          onDateChange={handleDateChanged}
          disabled={editMode?.scheduledDateDisableState}
          value={date}
          maxDate={null}
        />
      </Grid>
      <Grid item xs={5}>
        <TimePicker
          date={joinDateAndTime(time as string, date)}
          currentDate={date}
          disabled={disable}
          onDateChange={handleTimeChanged}
          label={t("time")}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label={t("working_days")}
          variant="outlined"
          type="number"
          defaultValue={workingDaysForExecution}
          disabled={editMode?.workingDaysDisableState || disable}
          required
          size="medium"
          value={workingDays}
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 0) {
              setWorkingDays(e.target.value);
            }
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label={t("calendar_days")}
          variant="outlined"
          type="number"
          disabled={editMode?.calendarDaysDisableState || disable}
          required
          value={calendarDays || 0}
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 0) {
              setCalendarDays(e.target.value);
            }
          }}
          size="medium"
        />
      </Grid>
    </Grid>
  );
};
