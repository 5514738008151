import { axiosService } from "app";

import { getFilterParams } from "@sbm/fe-utils";
import { QueryFunctionContext } from "@tanstack/react-query";
import {
  IAddApproverForm,
  IApprovalRoute,
  IAttachmentForm,
  IConfirmRoute,
  IContractFileEditing,
  IContractStatusChanging,
  IGenerateRegistrationNumber,
  IResponsiblePersonForm,
  ISAAttachmentForm,
  IUpdateStatus,
  IViewApproval,
  TypeOfObjectInternalApprovalEnum,
} from "@types";

export const getApprovalRoutesSelectForContract = async (
  id: number | undefined,
  type: TypeOfObjectInternalApprovalEnum
) => {
  const getRoute = (id: number | undefined) => {
    switch (type) {
      case TypeOfObjectInternalApprovalEnum.contract:
        return `/typical-approval-route-for-contracts/select-for-contract/${id}`;
      case TypeOfObjectInternalApprovalEnum.supplementaryAgreement:
        return `/typical-approval-route-for-contracts/select-for-supplementary-agreement/${id}`;
      case TypeOfObjectInternalApprovalEnum.outgoingDocument:
      case TypeOfObjectInternalApprovalEnum.serviceNote:
        return `/correspondence/${id}/default-approval-route`;
      default:
        return "";
    }
  };

  const { data } = await axiosService({
    endpoint: getRoute(id),
  });

  const dataToReturn =
    type === TypeOfObjectInternalApprovalEnum.outgoingDocument ||
    type === TypeOfObjectInternalApprovalEnum.serviceNote
      ? [
          {
            ...data,
            routeByDefault: true,
          },
        ]
      : data;

  return dataToReturn as IApprovalRoute[];
};

export const getConfirmedApprovalRoutes = async (
  id: number | undefined,
  type: TypeOfObjectInternalApprovalEnum
) => {
  const getRoute = () => {
    switch (type) {
      case TypeOfObjectInternalApprovalEnum.contract:
        return "get-by-contract-id/";
      case TypeOfObjectInternalApprovalEnum.supplementaryAgreement:
        return "get-by-supplementary-agreement-id/";
      case TypeOfObjectInternalApprovalEnum.outgoingDocument:
      case TypeOfObjectInternalApprovalEnum.serviceNote:
        return "get-by-correspondence-id/";
      default:
        return "";
    }
  };

  const { data } = await axiosService({
    endpoint: `/object-internal-approval-process/${getRoute()}${id}`,
  });

  return data as IApprovalRoute;
};

export const getContractHistory = async (id: number | undefined) => {
  const { data } = await axiosService({
    endpoint: `/event-for-document/contract/${id}`,
  });

  return data as any;
};

export const viewApproval = async (body: IViewApproval) => {
  const { data } = await axiosService({
    endpoint: `/single-approvals/${body.id}/viewed`,
    method: "PUT",
    body: { singleApprovalViewed: body.view },
  });

  return data;
};

export const confirmApprovalRoute = async (body: IConfirmRoute) => {
  const { data } = await axiosService({
    endpoint: "/object-internal-approval-process/confirm",
    method: "POST",
    body,
  });

  return data;
};

export const startApprovalProcess = async (body: {
  objectInternalApprovalProcessId: number | null | undefined;
}) => {
  const { data } = await axiosService({
    endpoint: "/object-internal-approval-process/launch",
    method: "POST",
    body,
  });

  return data;
};

export const addApprover = async (body: IAddApproverForm) => {
  const { data } = await axiosService({
    endpoint: "/object-internal-approval-process/add-approver",
    method: "POST",
    body,
  });

  return data;
};

export const fetchSingleApprovals = async (
  { pageParam }: QueryFunctionContext,
  type: boolean
) => {
  const page = pageParam ?? 1;
  const limit = 20;
  const sortBy = "createdAt:DESC";
  const params = type
    ? {
        limit,
        page,
        sortBy,
        "filter.active": "true",
      }
    : {
        limit,
        page,
        sortBy,
      };

  const { data } = await axiosService({
    endpoint: "/single-approvals",
    body: params,
  });

  return data;
};

export const fetchCurrentUserApprovals = async (
  objectInternalApprovalProcessId: number
) => {
  const { data } = await axiosService({
    endpoint: `/comments/object-internal-approval-process/${objectInternalApprovalProcessId}`,
  });

  return data;
};

export const contractStatusChangeAction = async (
  body: IContractStatusChanging
) => {
  const { data } = await axiosService({
    endpoint: "/object-internal-approval-process/change-approval-status",
    method: "POST",
    body,
  });

  return data;
};

export const returningRejectedAgreementAction = async (
  body: IContractStatusChanging
) => {
  const { data } = await axiosService({
    endpoint: "/object-internal-approval-process/restart-single-approval",
    method: "POST",
    body,
  });

  return data;
};

export const restartRejectedAgreementAction = async (
  body: IContractStatusChanging
) => {
  const { data } = await axiosService({
    endpoint: "/object-internal-approval-process/restart-approval-process",
    method: "POST",
    body,
  });

  return data;
};

export const assignContractResponsiblePerson = async (
  id: string,
  body: IResponsiblePersonForm
) => {
  const { data } = await axiosService({
    endpoint: `/contracts/${id}/responsible`,
    method: "POST",
    body,
  });

  return data;
};

export const changeContractResponsiblePerson = async (
  id: string,
  body: IResponsiblePersonForm
) => {
  const { data } = await axiosService({
    endpoint: `/contracts/${id}/responsible`,
    method: "PUT",
    body,
  });

  return data;
};

export const sendContractToDo = async (id: number) => {
  const { data } = await axiosService({
    endpoint: `/contracts/send-to-contract-department/${id}`,
    method: "POST",
  });

  return data;
};

export const getContractsList = async (
  { pageParam }: QueryFunctionContext,
  filter: (string | undefined)[] = [],
  sortBy = "",
  search = ""
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const dateFilters = [
    "filter.expirationDate",
    "filter.dateOfSigning",
    "filter.expirationDate",
  ];
  const filterParams = getFilterParams(filter, dateFilters);

  const params = {
    search,
    sortBy,
    limit,
    page,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: "/contracts",
    body: params,
  });

  return data;
};

export const getContractsTypicalApprovers = async (
  { pageParam }: QueryFunctionContext,
  sortBy = "",
  search = ""
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const params = {
    search,
    sortBy,
    limit,
    page,
  };

  const { data } = await axiosService({
    endpoint: "/typical-approval-route-for-contracts",
    body: params,
  });

  return data;
};

export const editContractsFile = async (
  id: number,
  body: IContractFileEditing
) => {
  const { data } = await axiosService({
    endpoint: `/contract-attached-files/${id}`,
    method: "PUT",
    body,
  });

  return data;
};

export const deleteContractsFile = async (id: number) => {
  const { data } = await axiosService({
    endpoint: `/contract-attached-files/${id}`,
    method: "DELETE",
  });

  return data;
};

export const deleteContract = async (id: number) => {
  const { data } = await axiosService({
    endpoint: `/contracts/${id}`,
    method: "DELETE",
  });

  return data;
};

export const updateContractStatus = async (id: number, body: IUpdateStatus) => {
  const { data } = await axiosService({
    endpoint: `/contracts/${id}/status`,
    method: "PUT",
    body,
  });

  return data;
};

export const generateContractRegistrationNumber = async (
  body: IGenerateRegistrationNumber
) => {
  const { data } = await axiosService({
    endpoint: "/contract-registration-number",
    method: "POST",
    body,
  });

  return data;
};

export const deleteGeneratedRegistrationNumber = async (id: number) => {
  const { data } = await axiosService({
    endpoint: `/contract-registration-number/${id}`,
    method: "DELETE",
  });

  return data;
};

export const checkRegistrationNumber = async (body: {
  numberContract: string | number;
}) => {
  const { data } = await axiosService({
    endpoint: "contracts/check",
    method: "GET",
    body,
  });

  return data;
};

export const updateNumberAndDate = async (
  id: number,
  body: {
    numberContract: string;
    dateContract: Date;
  }
) => {
  const { data } = await axiosService({
    endpoint: `/contracts/${id}/number-date`,
    method: "PUT",
    body,
  });

  return data;
};

export const addFileSplitting = async (
  attachment: IAttachmentForm | ISAAttachmentForm
) => {
  const { data } = await axiosService({
    endpoint: "/files",
    method: "PUT",
    body: attachment,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return data;
};

export const fetchContractFilesById = async (payload: {
  contractId: number | undefined;
  supplementaryAgreementId: number | undefined;
}) => {
  if (!payload) return;

  const { contractId, supplementaryAgreementId } = payload;

  if (!contractId && !supplementaryAgreementId) return null;

  const body: {
    page: number;
    limit: number;
    "filter.contractId"?: number;
    "filter.supplementaryAgreementId"?: number;
  } = {
    page: 1,
    limit: 199,
  };

  if (contractId) {
    body["filter.contractId"] = contractId;
  } else if (supplementaryAgreementId) {
    body["filter.supplementaryAgreementId"] = supplementaryAgreementId;
  } else {
    return;
  }

  const { data } = await axiosService({
    endpoint: `/contract-attached-files`,
    body,
  });

  return data;
};

export const fetchCompletedApprovals = async ({
  pageParam,
}: QueryFunctionContext) => {
  const page = pageParam ?? 1;
  const limit = 20;
  const sortBy = "createdAt:DESC";
  const params = {
    limit,
    page,
    sortBy,
  };

  const { data } = await axiosService({
    endpoint: "/object-internal-approval-process",
    body: params,
  });

  return data;
};

export const fetchSingleApprovalsById = async (
  objectInternalApprovalProcessId: number
) => {
  if (!objectInternalApprovalProcessId) return;

  const { data } = await axiosService({
    endpoint: `/single-approvals/object-internal-approval-process/${objectInternalApprovalProcessId}`,
  });

  return data;
};
