import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetWidgetsForHomepage, useOrderingWidgets } from "api";
import { Icon } from "ui-kit";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { getStorageItem, setStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";
import { ChapterEnum, TIconNames } from "@types";

import { ChartTypesWrapperMemo } from "./ChartTypesWrapperMemo";
import {
  getChart,
  getDescription,
  getHref,
  getMaintanenceMode,
  getIconName,
} from "./helpers";
import { HomePageInstrumentsWrapper } from "./styles";

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Icon name="ChevronRight" className="cursor-pointer" />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: "rotate(90deg)",
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

export const HomePageInstruments: React.FC = () => {
  const { palette, shadows } = useTheme();
  const { t } = useTranslation("tasks");
  const { t: tCommon } = useTranslation();

  const { data: widgets } = useGetWidgetsForHomepage();
  const { mutate: orderingWidgets } = useOrderingWidgets();

  const [isToolsWidgetOpen, setIsToolsWidgetOpen] = useState(() =>
    getStorageItem(STORAGE_KEYS.homePageToolsWidget)
  );
  const [isProjectsWidgetOpen, setIsProjectsWidgetOpen] = useState(() =>
    getStorageItem(STORAGE_KEYS.homePageProjectsWidget)
  );

  const [draggedWidgetId, setDraggedWidgetId] = useState<number | null>(null);
  const [draggedProjectId, setDraggedProjectId] = useState<number | null>(null);

  const [toolsWidgets, setToolsWidgets] = useState(() => {
    if (!widgets) return [];
    return widgets
      .filter((w) => w.chapter === ChapterEnum.tool)
      .sort(
        (a, b) =>
          (a.serialNumberInTheChapter || 1) - (b.serialNumberInTheChapter || 1)
      )
      .map((w) => ({
        title: w.widgetName,
        maintenanceMode: getMaintanenceMode(w.widgetServiceId!),
        id: w.id,
        widgetId: w.widgetId,
        icon: getIconName(w.widgetServiceId!),
        chart: getChart(w.widgetServiceId!),
        href: getHref(w.widgetServiceId!, w.widgetOrganizationId),
        description: getDescription(w.widgetServiceId!),
        templateNumberForWidget: w.templateNumberForWidget,
        imageUrl: w.imageUrl,
      }));
  });

  const [projectWidgets, setProjectWidgets] = useState(() => {
    if (!widgets) return [];
    return widgets
      .filter((w) => w.chapter === ChapterEnum.project)
      .sort(
        (a, b) =>
          (a.serialNumberInTheChapter || 1) - (b.serialNumberInTheChapter || 1)
      );
  });

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    widgetId: number,
    type: "tool" | "project"
  ) => {
    if (type === "tool") {
      setDraggedWidgetId(widgetId);
    } else {
      setDraggedProjectId(widgetId);
    }
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    targetWidgetId: number,
    type: "tool" | "project"
  ) => {
    event.preventDefault();

    if (type === "tool") {
      if (draggedWidgetId === null || draggedWidgetId === targetWidgetId)
        return;

      setToolsWidgets((prevToolsWidgets) => {
        const newOrder = [...prevToolsWidgets];
        const draggedIndex = newOrder.findIndex(
          (w) => w.widgetId === draggedWidgetId
        );
        const targetIndex = newOrder.findIndex(
          (w) => w.widgetId === targetWidgetId
        );

        if (draggedIndex === -1 || targetIndex === -1) return prevToolsWidgets;

        [newOrder[draggedIndex], newOrder[targetIndex]] = [
          newOrder[targetIndex],
          newOrder[draggedIndex],
        ];

        const newOrderingIds = newOrder.map((e) => e.id);
        orderingWidgets({ orderedItemIds: newOrderingIds });

        setDraggedWidgetId(null);
        return newOrder;
      });
    } else {
      if (draggedProjectId === null || draggedProjectId === targetWidgetId)
        return;

      setProjectWidgets((prevProjectWidgets) => {
        const newOrder = [...prevProjectWidgets];
        const draggedIndex = newOrder.findIndex(
          (w) => w.widgetId === draggedProjectId
        );
        const targetIndex = newOrder.findIndex(
          (w) => w.widgetId === targetWidgetId
        );

        if (draggedIndex === -1 || targetIndex === -1)
          return prevProjectWidgets;

        [newOrder[draggedIndex], newOrder[targetIndex]] = [
          newOrder[targetIndex],
          newOrder[draggedIndex],
        ];

        const newOrderingIds = newOrder.map((e) => e.id);
        orderingWidgets({ orderedItemIds: newOrderingIds });

        setDraggedProjectId(null);
        return newOrder;
      });
    }
  };

  const handleDropEnd = () => {
    setDraggedWidgetId(null);
    setDraggedProjectId(null);
  };

  const toggleHomePageWidgets = (
    expanded: boolean,
    field: "projects" | "tools"
  ) => {
    setStorageItem(
      field === "projects"
        ? STORAGE_KEYS.homePageProjectsWidget
        : STORAGE_KEYS.homePageToolsWidget,
      expanded
    );

    if (field === "projects") {
      setIsProjectsWidgetOpen(expanded);
    } else {
      setIsToolsWidgetOpen(expanded);
    }
  };

  useEffect(() => {
    if (!widgets) return;

    const toolsWidget = widgets
      .filter((w) => w.chapter === ChapterEnum.tool)
      .sort(
        (a, b) =>
          (a.serialNumberInTheChapter || 1) - (b.serialNumberInTheChapter || 1)
      )
      .map((w) => ({
        title: w.widgetName,
        maintenanceMode: getMaintanenceMode(w.widgetServiceId!),
        id: w.id,
        widgetId: w.widgetId,
        icon: getIconName(w.widgetServiceId!),
        chart: getChart(w.widgetServiceId!),
        href: getHref(w.widgetServiceId!, w.widgetOrganizationId),
        description: getDescription(w.widgetServiceId!),
        templateNumberForWidget: w.templateNumberForWidget,
        imageUrl: w.imageUrl,
      }));

    const prjWidget = widgets
      .filter((w) => w.chapter === ChapterEnum.project)
      .sort(
        (a, b) =>
          (a.serialNumberInTheChapter || 1) - (b.serialNumberInTheChapter || 1)
      );

    setToolsWidgets(toolsWidget);
    setProjectWidgets(prjWidget);
  }, [widgets]);

  return (
    <>
      <Typography fontSize={28} fontWeight={700} sx={{ mt: 6 }}>
        {t("tasks_card_view.favorites")}
      </Typography>

      <HomePageInstrumentsWrapper>
        {toolsWidgets.length > 0 && (
          <Accordion
            expanded={isToolsWidgetOpen}
            onChange={(_, expanded) => toggleHomePageWidgets(expanded, "tools")}
            disableGutters
            sx={{
              boxShadow: shadows[4],
              borderRadius: "16px !important",
              background: palette.other.grey,
            }}
          >
            <AccordionSummary sx={{ px: 6 }}>
              <Typography variant="h7" fontWeight={700} sx={{ py: 3 }}>
                {tCommon("instruments").toUpperCase()}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 6, pb: 6, zIndex: 1 }}>
              <Grid container mt={1} spacing={6}>
                {toolsWidgets.map((module) => (
                  <Grid
                    key={module.id}
                    item
                    xs={12}
                    md={6}
                    xl={4}
                    className={
                      draggedWidgetId === module.widgetId
                        ? "dragged_widget"
                        : ""
                    }
                    sx={{
                      minHeight: 300,
                      transition: "all 0.3s ease-in-out",
                      cursor: "grab",
                      zIndex: 1,
                      border:
                        draggedWidgetId === module.widgetId
                          ? "2px dashed"
                          : "none",
                      borderRadius:
                        draggedWidgetId === module.widgetId ? "16px" : "none",
                      background:
                        draggedWidgetId && draggedWidgetId === module.widgetId
                          ? "inherit"
                          : "inherit",
                      opacity:
                        draggedWidgetId && draggedWidgetId !== module.widgetId
                          ? 0.5
                          : 1,
                      padding:
                        draggedWidgetId === module.widgetId
                          ? "0 !important"
                          : undefined,

                      "&:active": {
                        cursor: "grabbing",
                      },
                    }}
                    draggable
                    onDragStart={(event) =>
                      handleDragStart(event, module.widgetId, "tool")
                    }
                    onDragOver={handleDragOver}
                    onDragEnd={handleDropEnd}
                    onDrop={(event) =>
                      handleDrop(event, module.widgetId, "tool")
                    }
                  >
                    <ChartTypesWrapperMemo
                      title={module.title}
                      icon={module.icon as TIconNames | null}
                      href={module.href}
                      id={module.widgetId}
                      templateNumberForWidget={module.templateNumberForWidget}
                      imageUrl={module.imageUrl}
                      onHomePage
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {projectWidgets.length > 0 && (
          <Accordion
            expanded={isProjectsWidgetOpen}
            onChange={(_, expanded) =>
              toggleHomePageWidgets(expanded, "projects")
            }
            disableGutters
            sx={{
              boxShadow: shadows[4],
              borderRadius: "16px !important",
              background: palette.other.grey,
              mt: 2,
            }}
          >
            <AccordionSummary sx={{ px: 6 }}>
              <Typography variant="h7" fontWeight={700} sx={{ py: 3 }}>
                {tCommon("projects").toUpperCase()}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 6, pb: 6 }}>
              <Grid container mt={1} spacing={6}>
                {projectWidgets.map((prj) => (
                  <Grid
                    key={prj.id}
                    item
                    xs={12}
                    md={6}
                    xl={4}
                    draggable
                    onDragStart={(event) =>
                      handleDragStart(event, prj.widgetId, "project")
                    }
                    className={
                      draggedProjectId === prj.widgetId ? "dragged_widget" : ""
                    }
                    sx={{
                      transition: "all 0.3s ease-in-out",
                      cursor: "grab",
                      zIndex: 1,
                      border:
                        draggedProjectId === prj.widgetId
                          ? "2px dashed"
                          : "none",
                      borderRadius:
                        draggedProjectId === prj.widgetId ? "16px" : "none",
                      background:
                        draggedProjectId && draggedProjectId === prj.widgetId
                          ? "inherit"
                          : "inherit",
                      opacity:
                        draggedProjectId && draggedProjectId !== prj.widgetId
                          ? 0.5
                          : 1,

                      "&:active": {
                        cursor: "grabbing",
                      },
                    }}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDropEnd}
                    onDrop={(event) =>
                      handleDrop(event, prj.widgetId, "project")
                    }
                  >
                    <ChartTypesWrapperMemo
                      title={prj.widgetName || ""}
                      href={`/projects/${prj.widgetConstructionComplexId}`}
                      id={prj.widgetId}
                      templateNumberForWidget={prj.templateNumberForWidget}
                      imageUrl={prj.imageUrl}
                      onHomePage
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </HomePageInstrumentsWrapper>
    </>
  );
};
