import toast from "react-hot-toast";

import {
  getWidgets,
  getWidgetsForHomepage,
  orderingWidgets,
  updateWidgetSwitcher,
} from "api";

import { getAPIErrorMessage } from "@sbm/fe-utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IWidget, IWidgetForHomepage, WidgetModuleEnum } from "@types";

export const useGetWidgets = (module: WidgetModuleEnum) => {
  return useQuery<IWidget[]>(["get_widgets", module], () => getWidgets(module));
};

export const useGetWidgetsForHomepage = () => {
  return useQuery<IWidgetForHomepage[]>(
    ["get_widgets_for_homepage"],
    async () => {
      return getWidgetsForHomepage();
    }
  );
};

export const useSwitchWidgetMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, validity }: { id: number; validity: boolean }) => {
      return await updateWidgetSwitcher(id, validity);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_widgets_for_homepage"]);
      },
      onError: (error) => {
        const errorMsg = getAPIErrorMessage(error);
        toast.error(errorMsg);
      },
    }
  );
};

export const useOrderingWidgets = () => {
  return useMutation(
    async (body: { orderedItemIds: number[] }) => {
      await orderingWidgets(body.orderedItemIds);
    },
    {
      onError: (error) => {
        const errorMsg = getAPIErrorMessage(error);
        toast.error(errorMsg);
      },
    }
  );
};
