import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useRegisterSupportTaskMutation } from "api";
import { closeDrawer, TransitionPrompt, useAppDispatch } from "app";
import { format } from "date-fns";
import { Drawer } from "ui-kit";

import { Stack } from "@mui/material";
import { Button } from "@sbm/ui-components";
import { IRegisterSupportRequestPayload, ITaskById } from "@types";

import { RequestExecutorAutocomplete, RequestContentForm } from "./components";

interface Props {
  data: ITaskById;
}

export const RegisterSupportRequestDrawer: React.FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation("tasks");

  const [showPrompt, setShowPrompt] = useState(false);

  const {
    control,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    register,
    formState: { errors, dirtyFields },
  } = useForm<IRegisterSupportRequestPayload>({
    defaultValues: {
      textOfTheTask: data.executionRequirements.textOfTheTask ?? "",
      calendarDaysForExecution:
        data.executionRequirements.numberOfCalendarDays ?? 0,
      workingDaysForExecution: data.executionRequirements.numberOfWorkingDays,
      controlDate: data.executionRequirements.controlDate ?? "",
      controlTime: data.executionRequirements.controlTime ?? "",
    },
  });
  const { employeeId, externalSubstitutionId } = useWatch({ control });

  const isSaveButtonActive = Boolean(employeeId || externalSubstitutionId);

  const handleClose = () => {
    reset();
    dispatch(closeDrawer());
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setShowPrompt(true);
    } else {
      handleClose();
    }
  };

  const { mutate: registerSupportTask, isLoading } =
    useRegisterSupportTaskMutation(handleClose);

  const setScheduleDate = (date: Date | string, time: string) => {
    const newDate = new Date(date);
    const formattedDate = format(newDate, "yyyy-MM-dd");
    return String(formattedDate) + `T${time}.000Z`;
  };

  const onSubmit = handleSubmit((fields) => {
    const requestBody: Partial<IRegisterSupportRequestPayload> = {
      id: Number(id),
      scheduledDateOfExecution: setScheduleDate(
        fields.controlDate,
        fields.controlTime as string
      ),
      calendarDaysForExecution: Number(fields.calendarDaysForExecution),
      workingDaysForExecution: Number(fields.workingDaysForExecution),
    };

    if (fields.externalSubstitutionId) {
      requestBody["externalSubstitutionId"] = fields.externalSubstitutionId;
    } else {
      requestBody["employeeId"] = fields.employeeId;
    }

    registerSupportTask(requestBody as IRegisterSupportRequestPayload);
  });

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleClose}
        />
      )}
      <Drawer
        open
        resizable={false}
        anchor="right"
        onClose={handleCancel}
        title={t("feedback.register_request")}
        actions={
          <Stack
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
            gap={4}
          >
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              onClick={handleCancel}
              sx={{ width: "fit-content" }}
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              size="large"
              color="secondary"
              variant="contained"
              onClick={onSubmit}
              loading={isLoading}
              disabled={isLoading || !isSaveButtonActive}
              sx={{ width: "fit-content" }}
            >
              {t("registration")}
            </Button>
          </Stack>
        }
      >
        <form noValidate onSubmit={onSubmit}>
          <Stack gap={4}>
            <RequestExecutorAutocomplete
              setValue={setValue}
              clearErrors={clearErrors}
              control={control}
              errors={errors}
            />
            <RequestContentForm
              executionRequirements={data.executionRequirements}
              registration={data.registration}
              register={register}
              control={control}
              setValue={setValue}
            />
          </Stack>
        </form>
      </Drawer>
    </>
  );
};
