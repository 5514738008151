import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW } from "ui-kit";

import { Card } from "@mui/material";

export const WidgetWrapper = styled(Card)`
  ${FLEX_COLUMN};
  height: 100%;
  min-height: 280px;
  max-height: 280px;

  border-radius: ${({ theme }) => theme.spacing(4)};

  box-shadow: ${({ theme }) => theme.shadows[1]};
  padding: ${({ theme }) => theme.spacing(0, 4, 4, 4)};

  &:hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
};
`;

export const WidgetChartWrapper = styled.div`
  ${FLEX_COLUMN};
  height: 100%;
  width: 100%;
`;

export const ChartContentWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-around;
  align-items: center;
  height: 300px;
`;
