import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import * as echarts from "echarts";

import { Stack, useTheme } from "@mui/material";
import { IChartDataTypeB } from "@types";

import IMG from "./custom-gauge-panel.png";

export const CustomGaugeChart = ({ data }: { data?: IChartDataTypeB }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const theme = useTheme();
  const { t } = useTranslation("project");

  useEffect(() => {
    if (!chartRef.current || !data) return;

    if (!chartInstance.current) {
      chartInstance.current = echarts.init(chartRef.current);
    }

    const chart = chartInstance.current;

    const _panelImageURL = IMG;
    const _animationDuration = 2000;
    const _animationDurationUpdate = 2000;
    const _animationEasingUpdate = "quarticInOut";
    const _valOnRadianMax = 200;
    const _outerRadius = 90;
    const _innerRadius = 60;
    const _pointerInnerRadius = 20;
    const _insidePanelRadius = 40;

    function convertToPolarPoint(
      renderItemParams: echarts.CustomSeriesRenderItemParams,
      radius: number,
      radian: number
    ): [number, number] {
      const coordSys = renderItemParams.coordSys as unknown as {
        cx: number;
        cy: number;
      };

      return [
        Math.cos(radian) * radius + coordSys.cx,
        -Math.sin(radian) * radius + coordSys.cy,
      ];
    }

    function makePointerPoints(
      renderItemParams: echarts.CustomSeriesRenderItemParams,
      polarEndRadian: number
    ): [number, number][] {
      return [
        convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian),
        convertToPolarPoint(
          renderItemParams,
          _outerRadius,
          polarEndRadian + Math.PI * 0.04
        ),
        convertToPolarPoint(
          renderItemParams,
          _pointerInnerRadius,
          polarEndRadian
        ),
      ];
    }

    function makeText(valOnRadian: number) {
      return valOnRadian.toFixed(0);
    }

    function renderItem(
      params: echarts.CustomSeriesRenderItemParams,
      api: echarts.CustomSeriesRenderItemAPI
    ) {
      const coordSys = params.coordSys as unknown as { cx: number; cy: number };

      const valOnRadian = api.value(1) as number;
      const coords = api.coord([api.value(0) as number, valOnRadian]);
      const polarEndRadian = coords[3];

      const imageStyle = {
        image: _panelImageURL,
        x: coordSys.cx - _outerRadius,
        y: coordSys.cy - _outerRadius,
        width: _outerRadius * 2,
        height: _outerRadius * 2,
      };

      return {
        type: "group",
        children: [
          {
            type: "image",
            style: imageStyle,
            clipPath: {
              type: "sector",
              shape: {
                cx: coordSys.cx,
                cy: coordSys.cy,
                r: _outerRadius,
                r0: _innerRadius,
                startAngle: 0,
                endAngle: -polarEndRadian,
                transition: "endAngle",
                enterFrom: { endAngle: 0 },
              },
            },
          },
          {
            type: "image",
            style: imageStyle,
            clipPath: {
              type: "polygon",
              shape: {
                points: makePointerPoints(params, polarEndRadian),
              },
              transition: "shape",
              enterFrom: { shape: { points: makePointerPoints(params, 0) } },
            },
          },
          {
            type: "circle",
            shape: {
              cx: coordSys.cx,
              cy: coordSys.cy,
              r: _insidePanelRadius,
            },
            style: {
              fill: theme.palette.common.white,
              shadowBlur: 25,
              shadowColor: "#1B2C411F",
            },
          },
          {
            type: "text",
            style: {
              text: makeText(valOnRadian),
              fontSize: 20,
              fontWeight: 700,
              x: coordSys.cx,
              y: coordSys.cy,
              fill: theme.palette.secondary.main,
              align: "center",
              verticalAlign: "middle",
            },
          },
        ],
      };
    }

    const option = {
      animationEasing: _animationEasingUpdate,
      animationDuration: _animationDuration,
      animationDurationUpdate: _animationDurationUpdate,
      animationEasingUpdate: _animationEasingUpdate,
      dataset: {
        source: [
          [+data?.rightParameter.value ?? 0, +data?.rightParameter.value ?? 0],
        ],
      },
      tooltip: {
        show: true,
        confine: true,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        textStyle: {
          color: theme.palette.text.primary,
          fontSize: 14,
        },
        formatter: (params: any) => {
          return `<div style="display: flex; flex-direction: row; align-items: center; gap: 8px;
                  min-width: 150px; background: ${theme.palette.primary.main};
                  border-radius: 8px; white-space: normal; word-break: break-word;">
              <div style="color: #fff; flex: 1;">${t(
                data.rightParameter.name
              )}</div>
              <div style="background: #fff; height: 20px; padding: 4px 8px;
                          border-radius: 8px; display: flex; align-items: center; justify-content: center;">
                  <strong style="color: #1B2C41">${params.data[1]}</strong>
              </div>
            </div>`;
        },
      },
      angleAxis: {
        type: "value",
        startAngle: 0,
        show: false,
        min: 0,
        max: _valOnRadianMax,
      },
      radiusAxis: {
        type: "value",
        show: false,
      },
      polar: {},
      series: [
        {
          type: "custom",
          coordinateSystem: "polar",
          renderItem: renderItem,
        },
      ],
    };

    chart.setOption(option as any);

    return () => {
      chart.dispose();
      chartInstance.current = null;
    };
  }, [
    data,
    t,
    theme.palette.common.white,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.text.primary,
  ]);

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      <div ref={chartRef} style={{ width: "180px", height: "180px" }} />
    </Stack>
  );
};

export default CustomGaugeChart;
