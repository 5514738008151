import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { WidgetComingSoonBg, WidgetComingSection } from "./styles";

export const MaintenanceMode = () => {
  const { t } = useTranslation();

  return (
    <WidgetComingSection>
      <WidgetComingSoonBg>
        <div />
        <div className="right__section">
          <Typography
            variant="h6"
            fontWeight={700}
            sx={{ pb: 2, textTransform: "uppercase" }}
            color="secondary.main"
          >
            {t("in.developing")}
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ opacity: 0.7 }}
          >
            {t("stay.tuned.for.updates")}
          </Typography>
        </div>
      </WidgetComingSoonBg>
    </WidgetComingSection>
  );
};
