import React, { useEffect, useState } from "react";

import { Grid, Stack, Typography } from "@mui/material";
import { IChartDataTypeC } from "@types";

export const SingleNumberChart = ({ data }: { data: IChartDataTypeC }) => {
  const [animatedValue, setAnimatedValue] = useState(0);
  const mainValue = data?.rightSubParameters?.value
    ? +data?.rightSubParameters?.value
    : 0;

  useEffect(() => {
    const increment = Math.ceil(mainValue / 25);
    const interval = setInterval(() => {
      setAnimatedValue((prev) => {
        if (prev < mainValue) {
          return prev + increment;
        } else {
          clearInterval(interval);
          return mainValue;
        }
      });
    }, 10);

    return () => clearInterval(interval);
  }, [mainValue]);

  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      gap={2}
      width="100%"
    >
      <Grid container>
        <Grid item xs={12}>
          <Stack display="flex" justifyContent="center" alignItems="center">
            <Typography
              fontSize={96}
              lineHeight="96px"
              fontWeight={300}
              color="secondary.main"
            >
              {animatedValue}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="subtitle2"
              lineHeight="16px"
              color="text.secondary"
              fontWeight={600}
              sx={{ opacity: 0.7 }}
            >
              {data?.rightSubParameters?.name}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
