import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetEmployees, useGetSupportTasks } from "api";
import {
  openDrawer,
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useQueryParams,
} from "app";
import { Icon, Table, useTableQueryMethods, useTableQuerySet } from "ui-kit";

import { Chip, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { getAPIErrorMessage, getLocaleDateFormat } from "@sbm/fe-utils";
import { DrawerVariantsEnum, StatusForAuthorEnum } from "@types";

import { TaskFilters, TaskPreview } from "../../components";
import {
  getTableColumns,
  getTasksFilterOptions,
  getTasksFilterValues,
} from "./helpers";
import { CellWrapper } from "./styles";

const DEFAULT_SORT_MODEL = "createdAt:DESC";

export const SupportRequestTable = () => {
  const { t } = useTranslation("tasks");
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const { get, set } = useQueryParams();

  const { data: employees } = useGetEmployees({ limit: 249 });

  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODEL);
  const filterFromUrl = get("filter", true) as string[];

  const debouncedSearch = useDebounce(search);
  const { drawer } = useAppSelector((state) => state.global);

  const filtersToSend = useMemo(() => {
    return filterFromUrl?.length > 0
      ? getTasksFilterValues(filterFromUrl, t, employees)
      : filterFromUrl;
  }, [filterFromUrl, t, employees]);

  const getFiltersForSending = (filtersToSend: string[]) => {
    return [
      "group=completed,in_the_spotlight,requires_action",
      ...filtersToSend,
    ];
  };

  const {
    data,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetSupportTasks(
    debouncedSearch,
    sortModel,
    getFiltersForSending(filtersToSend)
  );

  if (error) {
    const errorMSG = getAPIErrorMessage(error);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }

  const { onSortModelChange, onSearchChange } = useTableQueryMethods({
    setSortModel,
    setSearch,
  });

  useTableQuerySet({
    sortModel,
    search,
    setSortModel,
    setSearch,
  });

  const filterableFields = useMemo(
    () => getTasksFilterOptions(t, employees),
    [t, employees]
  );
  const rowsCount = data?.pages[0].meta.totalItems ?? 0;

  const rows = useMemo(() => {
    return (
      data?.pages?.flatMap((page) => {
        return page.items.map((item) => {
          return {
            ...item,
            id: item.id,
            createdAt: getLocaleDateFormat(item.createdAt),
            actualDateOfExecution: getLocaleDateFormat(
              item.actualDateOfExecution
            ),
            highPriority: item.highPriority,
            textOfTheTask: item.textOfTheTask,
            briefTextOrganizationName: item.briefTextOrganizationName,
          };
        });
      }) || []
    );
  }, [data]);

  const filtersButton = useMemo(() => {
    return <TaskFilters data={data} />;
  }, [data]);

  const columns = useMemo(() => {
    const tableColumns = getTableColumns(t);
    return tableColumns.map((column) => {
      return {
        ...column,
        headerName: column.headerName ? t(column.headerName) : "",
        renderCell: (params: GridCellParams) => {
          const isSentToSBM =
            params.row.statusForAuthor === StatusForAuthorEnum.sentToSupport;

          if (column.field === "highPriority") {
            const isHighPriority = params.row.highPriority === true;

            return (
              <Tooltip
                key={params.row.id}
                title={isHighPriority ? t("priority.high") : t("priority.low")}
                arrow
                disableInteractive
                placement="top"
              >
                <Stack flexDirection="row" alignItems="center" gap={1.5}>
                  <Icon
                    name={isHighPriority ? "Triangle" : "Equal"}
                    size={20}
                    strokeWidth={isSentToSBM ? 4 : 2}
                    color={
                      isHighPriority
                        ? theme.palette.error.main
                        : theme.palette.success.main
                    }
                  />

                  <Typography
                    fontWeight={isSentToSBM ? 700 : 400}
                    variant="body2"
                  >
                    {isHighPriority ? t("priority.high") : t("priority.low")}
                  </Typography>
                </Stack>
              </Tooltip>
            );
          }

          if (column.field === "attachedFiles") {
            return (
              <Stack justifyContent="center" flexDirection="row" width="100%">
                {params.row.hasAttachedFile ? (
                  <Icon
                    name="Paperclip"
                    size={18}
                    strokeWidth={isSentToSBM ? 3 : 2}
                    color={theme.palette.primary.main}
                  />
                ) : null}

                {params.row.hasAttachedLink ? (
                  <Icon
                    name="Link"
                    size={18}
                    strokeWidth={isSentToSBM ? 3 : 2}
                    color={theme.palette.primary.main}
                  />
                ) : null}
              </Stack>
            );
          }

          const status = params.row.statusForAuthor;

          return (
            <Tooltip
              key={params.row.id}
              title={
                params.field === "statusForAuthor"
                  ? t(`statuses.${status}`)
                  : params.row[params.field]
              }
              arrow
              disableInteractive
              placement="top"
            >
              <CellWrapper
                $isSentToSBM={isSentToSBM}
                className="MuiDataGrid-cellContent"
              >
                {params.field === "statusForAuthor" ? (
                  <Chip
                    label={t(`statuses.${status}`)}
                    className={`status-chip status-chip-${params.row.styleForStatusForAuthor}`}
                    sx={{
                      ".MuiChip-label": {
                        display: "block",
                      },
                    }}
                  />
                ) : null}
                {params.field !== "statusForExecutor" &&
                  params.field !== "statusForAuthor" &&
                  params.row[params.field]}
              </CellWrapper>
            </Tooltip>
          );
        },
      };
    });
  }, [t, theme]);

  const handleRowClick = (params: GridCellParams, event: React.MouseEvent) => {
    if (!params) return;
    set("selectedTaskId", params.row.id as string);

    if (event.ctrlKey) {
      dispatch(openDrawer(DrawerVariantsEnum.taskPreview));
    } else {
      navigate(`/settings/tech-support/${params.id}`, {
        state: { prevPath: location.pathname },
      });
    }
  };

  return (
    <>
      <Stack gap={8} sx={{ minHeight: 400 }}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6_semiBold" color="text.secondary">
            {t("feedback.technical_support_requests")}
          </Typography>
        </Stack>

        <Table
          rows={rows}
          columns={columns}
          loading={isLoading || isFetchingNextPage}
          rowCount={rowsCount}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          onSearchOptionChange={onSearchChange}
          onCellClick={(params, event) => handleRowClick(params, event)}
          hideFooter
          hasToolbar
          hideFooterPagination
          filterableFields={filterableFields}
          filteringButtons={filtersButton}
        />
      </Stack>
      {drawer === DrawerVariantsEnum.taskPreview && <TaskPreview />}
    </>
  );
};
