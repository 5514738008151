import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@sbm/ui-components";

interface Props {
  open: boolean;
  onClose: () => void;
  data: {
    pdfUrl: string;
  };
}

export const PreviewBlankTemplatePopup: React.FC<Props> = ({
  open,
  onClose,
  data: { pdfUrl },
}) => {
  const { t } = useTranslation("correspondence");

  const content = {
    title: t("generate_file_drawer.preview_template"),
    secondaryButton: null,
    body: (
      <iframe
        src={pdfUrl.concat("#toolbar=0&navpanes=0&view=fitH&statusbar=0")}
        title={pdfUrl}
        width="100%"
        height="100%"
      />
    ),
    mainButton: (
      <Button variant="outlined" color="secondary" onClick={onClose}>
        {t("cancel")}
      </Button>
    ),
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      content={content}
      updatedVersion
      fullHeight
    />
  );
};
