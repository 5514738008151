import React from "react";

import { TFunction } from "i18next";
import {
  BarChart,
  ChartDate,
  CustomBarChart,
  CustomGaugeChart,
  DoughnutChart,
  MultipleNumbersChart,
  RingGaugeChart,
  SingleBarChart,
  SingleNumberChart,
  TreeMapChart,
  TypeD1WidgetChart,
  TypeE1WidgetChart,
  TypeFWidgetChart,
} from "ui-kit";

import { Stack, Theme } from "@mui/material";
import {
  IChartDataTypeA,
  IChartDataTypeB,
  IChartDataTypeC,
  IChartDataTypeD,
  IChartDataTypeE,
  TemplateNumberForWidgetEnum,
} from "@types";

import { WidgetChartWrapper, ChartContentWrapper } from "./styles";
import { MaintenanceMode } from "./types";

export const getChart = (
  templateNumberForWidget: TemplateNumberForWidgetEnum,
  data:
    | IChartDataTypeA
    | IChartDataTypeD
    | IChartDataTypeB
    | IChartDataTypeE
    | IChartDataTypeC,
  theme: Theme,
  t: TFunction,
  maintenanceMode: boolean,
  imageUrl?: string
) => {
  const dataWithTranslationForTypeA =
    data && Array.isArray(data)
      ? {
          id: data[0]?.id,
          leftSubParameters: data[0]?.leftSubParameters?.map(
            (e: { name: string; value: number }) => ({
              name: t(`${e.name}`),
              value: e.value,
            })
          ),
          leftParameter: {
            name: t(`${data[0]?.leftParameter?.name}`),
            value: data[0]?.leftParameter?.value,
          },
          rightParameter: {
            name: t(`${data[0]?.rightParameter?.name}`),
            value: data[0]?.rightParameter?.value,
          },
        }
      : data;

  const dataAdapterForTypeB =
    data && Array.isArray(data)
      ? {
          id: data[0]?.id,
          rightParameter: {
            name: data[0]?.rightParameter?.name,
            value: data[0]?.rightParameter?.value,
          },
          leftParameter: data[0]?.leftParameter?.value,
        }
      : undefined;

  const dataWithTranslationForTypeC =
    data && Array.isArray(data)
      ? {
          id: data[0]?.id,
          leftSubParameters:
            data[0]?.leftSubParameters?.map(
              (e: { name: string; value: number }) => ({
                name: t(`${e.name}`),
                value: e.value,
              })
            ) || [],
          rightSubParameters: {
            name: t(`${data[0]?.rightParameter?.name}`),
            value: data[0]?.rightParameter?.value,
          },
        }
      : data;

  const dataWithTranslationForTypeD =
    data && Array.isArray(data)
      ? {
          id: data[0]?.id,
          rightSubParameters: data[0]?.rightSubParameters?.map(
            (e: { name: string; value: string }) => ({
              name: e.name,
              value: e.value,
            })
          ),
        }
      : undefined;

  const dataWithTranslationForTypeE =
    data && Array.isArray(data) && data.length
      ? data[0]?.rightSubParameters?.reduce(
          (
            acc: { [key: string]: string | number | null },
            { name, value }: { name: string; value: string | number | null }
          ) => {
            return { ...acc, [name]: value };
          },
          {} as { [key: string]: string | number | null }
        )
      : data;

  if (maintenanceMode) {
    return <MaintenanceMode />;
  }

  switch (templateNumberForWidget) {
    case TemplateNumberForWidgetEnum.A1:
      return (
        <ChartContentWrapper>
          <WidgetChartWrapper>
            <RingGaugeChart
              uniqueId={Math.random()}
              data={dataWithTranslationForTypeA as IChartDataTypeA}
            />
          </WidgetChartWrapper>
          <WidgetChartWrapper>
            <DoughnutChart
              uniqueId={Math.random()}
              data={dataWithTranslationForTypeA as IChartDataTypeA}
            />
          </WidgetChartWrapper>
        </ChartContentWrapper>
      );
    case TemplateNumberForWidgetEnum.A2:
      return (
        <ChartContentWrapper>
          <WidgetChartWrapper>
            <TreeMapChart
              uniqueId={Math.random()}
              data={dataWithTranslationForTypeA as IChartDataTypeA}
            />
          </WidgetChartWrapper>
          <WidgetChartWrapper>
            <CustomBarChart
              data={dataWithTranslationForTypeA as IChartDataTypeA}
            />
          </WidgetChartWrapper>
        </ChartContentWrapper>
      );
    case TemplateNumberForWidgetEnum.A3:
      return (
        <ChartContentWrapper>
          <WidgetChartWrapper>
            <BarChart
              uniqueId={Math.random()}
              data={dataWithTranslationForTypeA as IChartDataTypeA}
            />
          </WidgetChartWrapper>
          <WidgetChartWrapper>
            <SingleBarChart
              uniqueId={Math.random()}
              data={dataWithTranslationForTypeA as IChartDataTypeA}
            />
          </WidgetChartWrapper>
        </ChartContentWrapper>
      );
    case TemplateNumberForWidgetEnum.B1:
      return (
        <ChartContentWrapper>
          <WidgetChartWrapper>
            <ChartDate data={dataAdapterForTypeB?.leftParameter} />
          </WidgetChartWrapper>
          <WidgetChartWrapper>
            <CustomGaugeChart data={dataAdapterForTypeB} />
          </WidgetChartWrapper>
        </ChartContentWrapper>
      );
    case TemplateNumberForWidgetEnum.C1:
      return (
        <ChartContentWrapper>
          <WidgetChartWrapper>
            <MultipleNumbersChart
              data={dataWithTranslationForTypeC as IChartDataTypeC}
            />
          </WidgetChartWrapper>
          <Stack
            width={2}
            ml={3}
            mr={3}
            p="1px"
            height="100%"
            bgcolor={theme.palette.text.secondary}
          ></Stack>
          <WidgetChartWrapper>
            <SingleNumberChart
              data={dataWithTranslationForTypeC as IChartDataTypeC}
            />
          </WidgetChartWrapper>
        </ChartContentWrapper>
      );
    case TemplateNumberForWidgetEnum.D1:
      return (
        <ChartContentWrapper>
          <TypeD1WidgetChart
            data={dataWithTranslationForTypeD}
            imageUrl={imageUrl}
          />
        </ChartContentWrapper>
      );
    case TemplateNumberForWidgetEnum.E1:
      return (
        <ChartContentWrapper>
          <TypeE1WidgetChart
            data={dataWithTranslationForTypeE}
            imageUrl={imageUrl}
          />
        </ChartContentWrapper>
      );
    case TemplateNumberForWidgetEnum.F:
      return (
        <ChartContentWrapper>
          <TypeFWidgetChart data={data} />
        </ChartContentWrapper>
      );
    default:
      return <MaintenanceMode />;
  }
};
