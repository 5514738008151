import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useSetActualDate } from "api";
import {
  closeDrawerWithData,
  TransitionPrompt,
  UI_CONFIG,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer } from "ui-kit";

import { Chip, Stack, Typography, useTheme } from "@mui/material";
import { getLocaleDateFormat, hexToRgba } from "@sbm/fe-utils";
import { Button, FormDatePicker, FormRadio } from "@sbm/ui-components";
import { IFactDates, IGanttChartActualDate } from "@types";

import set_planned_image from "../../../../../src/assets/backgrounds/set_actual_date.svg";
import {
  getCompletionDateConfig,
  getCompletionDateOptionConfig,
  getIsSaveDisabled,
  getStartDateConfig,
  getStartDateOptionConfig,
  getStructuredPayload,
} from "./helpers";
import { CardImage, CardWrapper } from "./styles";

export const AssignmentActualDatesForm = ({
  data,
  ganttChartLineId,
}: {
  data: IGanttChartActualDate | undefined;
  ganttChartLineId: number;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("project");
  const theme = useTheme();
  const { drawerWithData } = useAppSelector((state) => state.global);
  const [showPrompt, setShowPrompt] = useState(false);

  const startDateOptionConfig = getStartDateOptionConfig(data);

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { dirtyFields },
    getValues,
  } = useForm<
    IFactDates & {
      startDateOption: "expected" | "actual";
      completionDateOption: "expected" | "actual";
    }
  >({
    defaultValues: {
      startDateOption: startDateOptionConfig?.selected,
    },
  });

  const {
    startDateOption,
    actualStartDate,
    expectedStartDate,
    completionDateOption,
    actualCompletionDate,
    expectedCompletionDate,
  } = useWatch({
    control,
  });

  const completionDateOptionConfig = getCompletionDateOptionConfig(
    data,
    startDateOption
  );

  const isSaveDisabled = getIsSaveDisabled(getValues(), dirtyFields);

  useEffect(() => {
    if (startDateOptionConfig?.selected) {
      reset({ startDateOption: startDateOptionConfig.selected });
    }
  }, [startDateOptionConfig?.selected, reset]);

  const handleConfirmTransition = () => {
    reset();
    handleClose();
  };

  const { mutate: setActualDate, isLoading } = useSetActualDate(
    handleConfirmTransition
  );

  // start date
  useEffect(() => {
    if (data?.expectedStartDate && !data?.actualStartDate) {
      setValue("expectedStartDate", data.expectedStartDate);
    } else if (data?.actualStartDate) {
      setValue("actualStartDate", data.actualStartDate);
    }
  }, [setValue, data]);

  // completion date
  useEffect(() => {
    if (
      !data?.expectedCompletionDate &&
      !data?.actualCompletionDate &&
      startDateOption === "expected"
    ) {
      setValue("completionDateOption", "expected");
    } else if (
      !data?.expectedCompletionDate &&
      !data?.actualCompletionDate &&
      startDateOption === "actual"
    ) {
      setValue("completionDateOption", "expected");
    } else if (
      data?.expectedCompletionDate &&
      !data?.actualCompletionDate &&
      startDateOption === "expected"
    ) {
      setValue("completionDateOption", "expected");
      setValue("expectedCompletionDate", data.expectedCompletionDate);
    } else if (
      data?.expectedCompletionDate &&
      !data?.actualCompletionDate &&
      startDateOption === "actual"
    ) {
      setValue("completionDateOption", "expected");
      setValue("expectedCompletionDate", data.expectedCompletionDate);
    } else if (data?.actualCompletionDate && startDateOption === "actual") {
      setValue("actualCompletionDate", data.actualCompletionDate);
      setValue("completionDateOption", "actual");
    }
  }, [data, startDateOption, setValue]);

  const handleCloseDrawer = () => {
    reset();
    dispatch(closeDrawerWithData());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleCancel = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setShowPrompt(true);
    } else {
      handleCloseDrawer();
    }
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const onSubmit = handleSubmit(
    async (
      data: IFactDates & {
        startDateOption: "expected" | "actual";
        completionDateOption: "expected" | "actual";
      }
    ) => {
      const payload = getStructuredPayload(data);
      const body = { id: ganttChartLineId, requestBody: payload };
      setActualDate(body);
    }
  );

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("set_actual_dates")}
        open={Boolean(drawerWithData)}
        onClose={handleCancel}
        actions={
          <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={handleCancel}
            >
              {t("actual_dates.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              loading={isLoading}
              disabled={!isSaveDisabled || isLoading}
              onClick={onSubmit}
            >
              {t("save")}
            </Button>
          </Stack>
        }
      >
        <CardWrapper>
          <Stack direction="column" width="100%" gap={4}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column" gap={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ color: theme.palette.primary.contrastText }}
                >
                  {data?.lineNumber} {data?.nameOfLine}
                </Typography>
                <Chip
                  label={t(`gantt_chart_status.${data?.statusOfLine}`)}
                  color="secondary"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.primary.contrastText,
                    borderRadius: 4,
                    width: UI_CONFIG.chipWidth,
                  }}
                />
              </Stack>
              <Stack>
                <CardImage src={set_planned_image} alt="chart card icon" />
              </Stack>
            </Stack>
            <Stack direction="row" gap={20}>
              <Stack direction="column">
                <Typography
                  variant="subtitle2"
                  fontWeight={600}
                  color={theme.palette.primary.contrastText}
                >
                  {getStartDateConfig(data, t).title}
                </Typography>
                <Typography
                  variant="caption"
                  color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
                >
                  {getLocaleDateFormat(getStartDateConfig(data, t).value)}
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography
                  variant="subtitle2"
                  fontWeight={600}
                  color={theme.palette.primary.contrastText}
                >
                  {getCompletionDateConfig(data, t).title}
                </Typography>
                <Typography
                  variant="caption"
                  color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
                >
                  {getLocaleDateFormat(getCompletionDateConfig(data, t).value)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardWrapper>
        <form noValidate onSubmit={onSubmit}>
          <Stack mt={8}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              mb={4}
            >
              {t("actual_dates.start_date")}
            </Typography>
            <FormRadio
              name="startDateOption"
              size="medium"
              control={control}
              values={[
                {
                  option: t("actual_dates.expected"),
                  value: "expected",
                  disabled: startDateOptionConfig?.expectedDateDisabled,
                },
                {
                  option: t("actual_dates.actual"),
                  value: "actual",
                  disabled: startDateOptionConfig?.actualDateDisabled,
                },
              ]}
            />
            <Stack mt={4}>
              {startDateOption === "expected" && (
                <FormDatePicker
                  required
                  control={control}
                  name="expectedStartDate"
                  variant="outlined"
                  size="medium"
                  label={t("date")}
                  value={expectedStartDate}
                />
              )}
              {startDateOption === "actual" && (
                <FormDatePicker
                  required
                  control={control}
                  name="actualStartDate"
                  variant="outlined"
                  size="medium"
                  label={t("date")}
                  value={actualStartDate}
                />
              )}
            </Stack>
          </Stack>
          <Stack mt={8}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              mb={4}
            >
              {t("actual_dates.end_date")}
            </Typography>
            <FormRadio
              name="completionDateOption"
              size="medium"
              control={control}
              values={[
                {
                  option: t("actual_dates.expected"),
                  value: "expected",
                  disabled: completionDateOptionConfig?.expectedDateDisabled,
                },
                {
                  option: t("actual_dates.actual"),
                  value: "actual",
                  disabled: completionDateOptionConfig?.actualDateDisabled,
                },
              ]}
            />
            <Stack mt={4}>
              {completionDateOption === "expected" && (
                <FormDatePicker
                  required
                  control={control}
                  name="expectedCompletionDate"
                  variant="outlined"
                  size="medium"
                  label={t("date")}
                  value={expectedCompletionDate}
                />
              )}
              {completionDateOption === "actual" && (
                <FormDatePicker
                  required
                  control={control}
                  name="actualCompletionDate"
                  variant="outlined"
                  size="medium"
                  label={t("date")}
                  value={actualCompletionDate}
                />
              )}
            </Stack>
          </Stack>
        </form>
      </Drawer>
    </>
  );
};
