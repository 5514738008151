import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useRejectTechSupportRequestMutation } from "api";
import { Modal } from "ui-kit";

import { Input, Button } from "@sbm/ui-components";

interface Props {
  open: boolean;
  onClose: () => void;
  data: { data: { id: number } };
}

export const RejectTechSupportRequestPopup: React.FC<Props> = ({
  open,
  onClose,
  data: {
    data: { id },
  },
}) => {
  const { t } = useTranslation("tasks");

  const [comment, setComment] = useState("");

  const { mutate: rejectTechSupportRequest, isLoading } =
    useRejectTechSupportRequestMutation(onClose);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setComment(e.target.value);

  const handleSendForRevision = () => {
    rejectTechSupportRequest({ id, comment });
  };

  const content = {
    title: t("reject_tech_support_task_title"),
    body: (
      <Input
        multiline
        required
        label={t("comment")}
        maxLength={1000}
        onChange={handleChange}
        sx={{ my: 2 }}
      />
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        onClick={handleSendForRevision}
        loading={isLoading}
        disabled={!comment.trim().length}
      >
        {t("send")}
      </Button>
    ),
  };

  return (
    <Modal updatedVersion isOpen={open} onClose={onClose} content={content} />
  );
};
