import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "app";
import { ListFilter } from "lucide-react";

import { Stack } from "@mui/material";
import { getUrlParam } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";

import { getFilterAutocompleteName } from "../../../Autocomplete/helpers";
import { SelectedFiltersButton, SelectFiltersPopover } from "../../components";
import { TableProps, IOption } from "../../types";
import { SearchComponent } from "./SearchComponent";
import { SelectDate } from "./SelectDate";

export type TableFiltersProps = Pick<
  TableProps,
  "filterableFields" | "onSearchOptionChange" | "onFilterChange"
>;

export const TableFilters: FC<TableFiltersProps> = ({
  filterableFields,
  onSearchOptionChange,
  onFilterChange,
}) => {
  const { t } = useTranslation();
  const { get, remove, set } = useQueryParams();

  const [autocompleteValue, setAutocompleteValue] = useState<IOption[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const isDocumentsForAvailable = filterableFields?.find(
    (item) => item.optionType === "documentsFor"
  );

  const withoutReset =
    autocompleteValue.length === 1 &&
    autocompleteValue[0].optionType === "documentsFor";

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClearFilters = () => {
    const searchFromUrl = getUrlParam("search") as string;

    if (searchFromUrl) {
      set("search", searchFromUrl);
    } else {
      remove("search");
    }

    remove("filter");
    handlePopoverClose();
  };

  useEffect(() => {
    const filterQueryFromUrl = get("filter", true) as string[];

    const valuesToSet: IOption[] = [];

    if (filterQueryFromUrl?.length) {
      filterQueryFromUrl.forEach((val) => {
        const parts = val.split("=");
        const key = parts[0];
        const value = parts[1];

        const filterOption = filterableFields?.find((i) => i.field === key);

        if (filterOption) {
          valuesToSet.push({
            ...filterOption,
            title: `${getFilterAutocompleteName(key, t)}: "${value}"`,
            value,
            type: "filter",
          });
        }
      });
    }

    setAutocompleteValue(valuesToSet);
  }, [filterableFields, get, t]);

  useEffect(() => {
    if (onFilterChange) {
      let filterValues: string[] = [];

      if (autocompleteValue.length > 0) {
        filterValues = autocompleteValue.map(
          (item) => `${item.field}=${item.value}`
        );
      }

      onFilterChange(filterValues);
    }
  }, [autocompleteValue, onFilterChange]);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={3}
      flexWrap="wrap"
      sx={{ width: `calc(100% - ${isDocumentsForAvailable ? 200 : 370}px)` }}
    >
      {Boolean(onSearchOptionChange) && (
        <SearchComponent onSearchOptionChange={onSearchOptionChange} />
      )}

      {isDocumentsForAvailable && (
        <SelectDate filterableFields={filterableFields} />
      )}

      {filterableFields && filterableFields.length > 0 && (
        <>
          <SelectedFiltersButton filterableFields={filterableFields} />

          <Stack flexDirection="row" alignItems="center" gap={3}>
            <Button
              color="secondary"
              startIcon={<ListFilter size={20} />}
              onClick={handlePopoverOpen}
            >
              {t("filters")}
            </Button>

            {autocompleteValue.length > 0 && !withoutReset && (
              <Button
                variant="text"
                color="secondary"
                onClick={handleClearFilters}
              >
                {t("reset")}
              </Button>
            )}
          </Stack>

          <SelectFiltersPopover
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            filterableFields={filterableFields}
          />
        </>
      )}
    </Stack>
  );
};
