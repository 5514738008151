import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useGetWidgetStats } from "api";

import { Stack, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { TemplateNumberForWidgetEnum, TIconNames } from "@types";

import { WidgetHeader } from "./header";
import { getChart } from "./helper";
import { WidgetWrapper } from "./styles";

export interface ChartTypesWrapperProps {
  id?: number;
  title: string;
  icon?: TIconNames | null;
  href?: string;
  switcherOn?: boolean;
  maintenanceMode?: boolean;
  templateNumberForWidget?: TemplateNumberForWidgetEnum;
  imageUrl?: string;
  onHomePage?: boolean;
}

export const ChartTypesWrapper = ({
  id,
  title,
  icon,
  templateNumberForWidget,
  href,
  imageUrl,
  switcherOn,
  onHomePage = false,
  maintenanceMode = false,
}: ChartTypesWrapperProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data } = useGetWidgetStats(id);

  return (
    <Stack
      onClick={(e) => {
        if ((e.target as HTMLElement).closest(".switch-ignore")) return;
        void queryClient.invalidateQueries(["get_widgets"]);
        if (href) navigate(href);
      }}
    >
      <WidgetWrapper>
        <WidgetHeader
          icon={icon}
          title={title}
          id={id}
          type={templateNumberForWidget}
          switcherOn={switcherOn}
          onHomePage={onHomePage}
        />

        {data
          ? getChart(
              templateNumberForWidget as TemplateNumberForWidgetEnum,
              data,
              theme,
              t,
              maintenanceMode,
              imageUrl
            )
          : null}
      </WidgetWrapper>
    </Stack>
  );
};
