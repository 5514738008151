import React from "react";
import { useTranslation } from "react-i18next";

import { Stack, Typography, useTheme } from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";

export const ChartDate = ({ data }: { data: Date | string }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      gap={2}
      pr={8}
    >
      <Typography
        variant="h9_semiBold"
        color={theme.palette.text.secondary}
        sx={{ opacity: 0.7 }}
      >
        {t("last_added")}
      </Typography>
      <Typography
        variant="h6"
        fontWeight={700}
        color={theme.palette.text.secondary}
      >
        {getLocaleDateFormat(data)}
      </Typography>
    </Stack>
  );
};
