import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useConfirmApprovalRoute, useGetDefaultApprovalRoute } from "api";
import {
  closeDrawer,
  resetTaskCreationStep,
  setModal,
  TransitionPrompt,
  UI_CONFIG,
  useAppDispatch,
  useAppSelector,
  useQueryParams,
} from "app";
import { Drawer, Icon, Table } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import {
  COLUMN_SIZE,
  getPersonalDataName,
  SnackbarMessages,
} from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import {
  ApproverActions,
  CorrespondenceTypeEnum,
  IAddAgreement,
  IAgreementDuration,
  IApprover,
  IApproverTypicalApprovalRoute,
  IConfirmRoute,
  IDocumentApprovalRoute,
  ModalVariants,
} from "@types";

import {
  getAgreementQueueTableColumns,
  groupByQueueNumber,
} from "../../../../agreements/helpers";
import { ApprovalSubject } from "./ApprovalSubject";
import { TitleWrapper, Wrapper } from "./styles";

interface Props {
  correspondenceId: number | undefined;
  correspondenceType: CorrespondenceTypeEnum;
}

export const AdjustAgreementRoute = ({
  correspondenceId,
  correspondenceType,
}: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { set } = useQueryParams();

  const { t } = useTranslation("correspondence");
  const { t: tCommon } = useTranslation("");

  const [localAgreementRows, setLocalAgreementRows] = useState<
    GridValidRowModel[]
  >([]);

  const { drawer } = useAppSelector((state) => state.global);

  const { data, isLoading } = useGetDefaultApprovalRoute(correspondenceId);

  const [myApprover, setMyApprover] = useState<IApproverTypicalApprovalRoute[]>(
    []
  );

  useEffect(() => {
    if (data) {
      setMyApprover(data.approvers as IApproverTypicalApprovalRoute[]);
    }
  }, [data, setMyApprover]);

  const onSubmitSuccess = async () => {
    toast.success(SnackbarMessages.success);
    await queryClient.invalidateQueries(["get_document_by_id"]);
    await queryClient.invalidateQueries(["get_default_approval_route"]);

    set("tab", "3");

    handleClose();
  };

  const onErrorSuccess = () => {
    toast.error(SnackbarMessages.error);
    handleClose();
  };

  const { mutate: confirmRoute, isLoading: isConfirmLoading } =
    useConfirmApprovalRoute(onSubmitSuccess, onErrorSuccess);

  const [showPrompt, setShowPrompt] = useState(false);

  const handleCloseDrawer = () => {
    dispatch(resetTaskCreationStep());
    dispatch(closeDrawer());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const createReqBody = (data: IDocumentApprovalRoute[]) => {
    const body = data.map((item) => {
      if (item.externalSubstitutionId) {
        return {
          queueNumber: item.queueNumber,
          externalSubstitutionId: item.externalSubstitutionId,
          amountOfDays: item.amountOfDays,
        };
      }
      return {
        queueNumber: item.queueNumber,
        employeeId: item.employeeId,
        amountOfDays: item.amountOfDays,
      };
    });

    if (correspondenceType === CorrespondenceTypeEnum.outgoingDocument) {
      return {
        type: correspondenceType,
        outgoingDocumentId: correspondenceId,
        approvers: body,
      };
    }
    if (correspondenceType === CorrespondenceTypeEnum.serviceNote) {
      return {
        type: correspondenceType,
        serviceNoteId: correspondenceId,
        approvers: body,
      };
    }
  };

  const handleConfirm = () => {
    const requestBody = createReqBody(
      localAgreementRows as IDocumentApprovalRoute[]
    );
    confirmRoute(requestBody as unknown as IConfirmRoute);
  };

  const rows: GridValidRowModel[] = React.useMemo(() => {
    return myApprover
      ? myApprover.map((item, index) => ({
          ...item,
          id: index,
          approverType: t(item.approverMission),
          queueNumber: item.queueNumber,
          structuralUnitId: item.structuralUnitId || undefined,
          position: t(item.positionName),
          longName: item.personalData
            ? getPersonalDataName(item.personalData)
            : item.longName || "",
          status: t(item?.status?.statusSingleApproval as string),
          singleApprovalId: item?.status?.singleApprovalId,
        }))
      : [];
  }, [myApprover, t]);

  useEffect(() => {
    setLocalAgreementRows(rows);
  }, [data, rows]);

  const isApprovalEnded = Boolean(
    myApprover?.every((item) => {
      return item.status?.statusSingleApproval === ApproverActions.approved;
    })
  );

  const handelChangeDuration = useCallback(
    (arg: IAgreementDuration, id: number) => {
      setMyApprover((prevApprovers) =>
        prevApprovers.map((approver, index) =>
          index === id
            ? {
                ...approver,
                amountOfDays: arg.duration,
              }
            : approver
        )
      );
    },
    [setMyApprover]
  );

  const handleDeleteApproval = useCallback(
    (arg: { queueNumber: number; id: number }) => {
      setMyApprover((prevApprovers) =>
        prevApprovers
          .filter((_, index) => index !== arg.id)
          .map((approver, index) => ({ ...approver, queueNumber: index + 1 }))
      );
      const queueColumns = getAgreementQueueTableColumns(rows, tCommon, theme);
      return [...queueColumns];
    },
    [setMyApprover, rows, tCommon, theme]
  );

  const columns: GridColDef[] = React.useMemo(() => {
    const defaultColumns: GridColDef[] = [
      {
        field: "position",
        minWidth: COLUMN_SIZE * 5,
        headerName: t("approval_subject"),
        sortable: false,
        filterable: false,
        cellClassName: "approval-table__position-cell",
        renderCell: (params) => {
          const handleUpdateDuration = (arg: IAgreementDuration) => {
            handelChangeDuration(arg, params.row.id);
          };

          const handleDelete = (arg: { queueNumber: number; id: number }) => {
            handleDeleteApproval(arg);
          };

          const handleMoveLeft = ({
            queueNumber,
            id,
          }: {
            queueNumber: number;
            id: number;
          }) => {
            setMyApprover((prevApprovers) => {
              const updatedApprovers = prevApprovers.map((approver, index) => {
                if (index === id) {
                  return {
                    ...approver,
                    queueNumber: approver.queueNumber - 1,
                  } as IApproverTypicalApprovalRoute;
                }
                return approver;
              });

              const groupedQueues = groupByQueueNumber(
                updatedApprovers as IApprover[]
              );
              return Object.entries(groupedQueues)
                .sort(([a], [b]) => Number(a) - Number(b))
                .flatMap(
                  ([_, approvers], index) =>
                    approvers.map((approver) => ({
                      ...approver,
                      queueNumber: index + 1,
                    })) as IApproverTypicalApprovalRoute[]
                );
            });
          };

          const handleMoveRight = ({
            queueNumber,
            id,
          }: {
            queueNumber: number;
            id: number;
          }) => {
            setMyApprover((prevApprovers) => {
              const isLastQueue =
                queueNumber ===
                Math.max(...prevApprovers.map((a) => a.queueNumber));

              const outgoingSender = prevApprovers.length === queueNumber;

              if (isLastQueue && outgoingSender) {
                return prevApprovers;
              }

              const updatedApprovers = prevApprovers.map((approver, index) => {
                if (index === id) {
                  return { ...approver, queueNumber: approver.queueNumber + 1 };
                }
                return approver;
              });

              const groupedQueues = groupByQueueNumber(
                updatedApprovers as IApprover[]
              );

              return Object.entries(groupedQueues)
                .sort(([a], [b]) => Number(a) - Number(b))
                .flatMap(
                  ([_, approvers], index) =>
                    approvers.map((approver) => ({
                      ...approver,
                      queueNumber: index + 1,
                    })) as IApproverTypicalApprovalRoute[]
                );
            });
          };

          const groupedQueues = groupByQueueNumber(myApprover as IApprover[]);

          return (
            <ApprovalSubject
              name={params.row.longName}
              position={params.row.position}
              approverType={params.row.approverType}
              amountOfDays={params.row.amountOfDays}
              onUpdateDuration={handleUpdateDuration}
              onDelete={handleDelete}
              onMoveLeft={handleMoveLeft}
              onMoveRight={handleMoveRight}
              allowedDelete={
                Object.keys(groupedQueues).length === params.row.queueNumber ||
                myApprover.length < 3
              }
              allowedLeft={
                Object.keys(groupedQueues).length === params.row.queueNumber ||
                (params.row.queueNumber === 1 &&
                  groupedQueues[1]?.length === 1) ||
                (params.row.queueNumber === 2 &&
                  Object.keys(groupedQueues).length === 2)
              }
              allowedRight={
                Object.keys(groupedQueues).length === params.row.queueNumber ||
                Object.keys(groupedQueues).length === params.row.queueNumber + 1
              }
              queueNumber={params.row.queueNumber}
              id={params.row.id}
            />
          );
        },
      },
    ];

    if (!isApprovalEnded) {
      const queueColumns = getAgreementQueueTableColumns(rows, tCommon, theme);

      return [...defaultColumns, ...queueColumns];
    }

    return [...defaultColumns];
  }, [
    t,
    isApprovalEnded,
    rows,
    theme,
    tCommon,
    handleDeleteApproval,
    handelChangeDuration,
    myApprover,
  ]);

  const handleUpdateLocalApprovalRoutes = (arg: IAddAgreement) => {
    const newItem = {
      approverMission: arg.nameOfStructuralUnit,
      queueNumber: +arg.queueNumber,
      positionName: arg.positionName || "",
      employeeId: arg.employeeId,
      amountOfDays: +arg.duration,
      longName: arg.title,
      externalSubstitutionId: arg.externalSubstitutionId,
      id: localAgreementRows.length,
      status: "",
      singleApprovalId: undefined,
    };

    const newApprovers = [...myApprover, newItem];
    newApprovers.sort((a, b) => a.queueNumber - b.queueNumber);
    setMyApprover(newApprovers as IApproverTypicalApprovalRoute[]);
  };

  const addApprover = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.addAgreementRouteApprover,
        content: {
          data: {
            duration: 1,
            queueCount: columns.length - 2, //-2 ==> position column + last column,
            onUpdate: (arg: IAddAgreement) =>
              handleUpdateLocalApprovalRoutes(arg),
          },
        },
      })
    );
  };

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("set_up_approval")}
        open={Boolean(drawer)}
        onClose={handleCancel}
        width={UI_CONFIG.rightDrawerFullWidth}
        actions={
          <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="secondary"
              size="large"
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              loading={isConfirmLoading}
              disabled={isConfirmLoading}
              onClick={handleConfirm}
            >
              {t("confirm")}
            </Button>
          </Stack>
        }
      >
        <Wrapper>
          <TitleWrapper>
            <Typography variant="h6" color="text.secondary" fontWeight={600}>
              {t("approval_route")}
            </Typography>
            <Button
              variant="text"
              color="secondary"
              onClick={addApprover}
              startIcon={
                <Icon name="UserPlus2" color={theme.palette.secondary.main} />
              }
            >
              {t("add_approver")}
            </Button>
          </TitleWrapper>

          <Table
            rows={localAgreementRows}
            columns={columns}
            hasPagination={false}
            checkboxSelection={false}
            loading={isLoading}
            getRowHeight={() => "auto"}
          />
        </Wrapper>
      </Drawer>
    </>
  );
};
