import React, { useMemo } from "react";
import { Control, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { FormRadio } from "@sbm/ui-components";
import { ILetterMessages, IMailData, MethodOfSendingEnum } from "@types";

export interface MethodStepProps {
  register: UseFormRegister<IMailData>;
  messages?: ILetterMessages[];
  control: Control<IMailData>;
}

export const MethodStep: React.FC<MethodStepProps> = ({
  register,
  control,
  messages,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");

  const systemOptions = useMemo(
    () => [
      {
        option: t(MethodOfSendingEnum.viaOrganizationEmail),
        value: MethodOfSendingEnum.viaOrganizationEmail,
      },
      {
        option: t(MethodOfSendingEnum.viaApplication),
        value: MethodOfSendingEnum.viaApplication,
      },
    ],
    [t]
  );

  const serviceOptions = useMemo(
    () => [
      {
        option: t(MethodOfSendingEnum.byEmail),
        value: MethodOfSendingEnum.byEmail,
      },
      {
        option: t(MethodOfSendingEnum.byRussianPost),
        value: MethodOfSendingEnum.byRussianPost,
      },
      {
        option: t(MethodOfSendingEnum.viaCourierDeliveryService),
        value: MethodOfSendingEnum.viaCourierDeliveryService,
      },
      {
        option: t(
          MethodOfSendingEnum.byARepresentativeOfTheSendingOrganisation
        ),
        value: MethodOfSendingEnum.byARepresentativeOfTheSendingOrganisation,
      },
    ],
    [t]
  );

  if (!messages?.length) {
    return <></>;
  }

  return (
    <React.Fragment>
      {messages.map((message, index) => {
        return (
          <Stack key={index} mt={8}>
            <Stack mb={4} direction={"column"}>
              <Typography variant={"body1"} fontWeight={700}>
                {message.personalDataLongName}
              </Typography>
              <Typography color={theme.palette.text.secondary} fontWeight={600}>
                {message.briefTextOrganizationName}
              </Typography>
            </Stack>
            <Card>
              <CardContent>
                <Typography
                  variant={"h9_semiBold"}
                  color={theme.palette.text.disabled}
                >
                  {t("sending_method").toUpperCase()}
                </Typography>
                <Stack mt={6}>
                  <FormControlLabel
                    label={t("send_from_system")}
                    control={
                      <Switch
                        color="secondary"
                        defaultChecked={message.sendFromSystem}
                        {...register(`messages.${index}.sendFromSystem`)}
                      />
                    }
                  />
                  {messages[index].sendFromSystem && (
                    <Stack pl={12}>
                      <FormRadio
                        name={`messages.${index}.methodOfSending`}
                        size="medium"
                        control={control}
                        flexDirection="column"
                        alignItems="flex-start"
                        values={systemOptions}
                      />
                    </Stack>
                  )}
                  <FormControlLabel
                    sx={{ mt: 4 }}
                    label={t("sending_via_external_services")}
                    control={
                      <Switch
                        color="secondary"
                        defaultChecked={message.sendFromService}
                        {...register(`messages.${index}.sendFromService`)}
                      />
                    }
                  />
                  {messages[index].sendFromService && (
                    <Stack pl={12}>
                      <FormRadio
                        name={`messages.${index}.methodOfSending`}
                        size="medium"
                        control={control}
                        flexDirection="column"
                        alignItems="flex-start"
                        values={serviceOptions}
                      />
                    </Stack>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        );
      })}
    </React.Fragment>
  );
};
