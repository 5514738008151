import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import gantt_chart_progress_image_completed from "../../../../../../assets/backgrounds/gantt_chart_progress_completed.svg";
import gantt_chart_progress_image from "../../../../../../assets/backgrounds/gantt_chart_progress_image.svg";

export const Wrapper = styled.div`
  max-height: 260px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary["8p"]};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
  .progress {
    ${FLEX_ROW_ALIGN_CENTER}
  }
`;

export const SectionWrapper = styled.div`
  max-height: 260px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  margin-top: ${({ theme }) => theme.spacing()};
`;

export const CardIMG = styled.div<{ $isCompleted: boolean }>`
  height: 153px;
  width: 100%;
  background-image: ${({ $isCompleted }) =>
    $isCompleted
      ? `url(${gantt_chart_progress_image_completed})`
      : `url(${gantt_chart_progress_image})`};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
