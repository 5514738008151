import React from "react";

import { openDrawerWithData, useAppDispatch } from "app";

import { Stack, Typography, useTheme } from "@mui/material";
import { getLocaleDateFormat, hexToRgba } from "@sbm/fe-utils";
import { DrawerWithDataVariantsEnum } from "@types";
import { Gantt, ViewMode, TaskOrEmpty, Column } from "@wamra/gantt-task-react";
import "@wamra/gantt-task-react/dist/style.css";

import { Wrapper } from "./styles";

interface GanttChartProps {
  tasks: TaskOrEmpty[];
  viewMode: ViewMode;
  viewDate: Date;
  columns?: Column[];
}

export const GanttChart = ({
  tasks,
  viewDate,
  viewMode,
  columns,
}: GanttChartProps) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const handleRowClick = (task: TaskOrEmpty) => {
    if (task.id) {
      const chartLineId = task.id;

      dispatch(
        openDrawerWithData({
          variant: DrawerWithDataVariantsEnum.ganttChartProgress,
          content: { lineId: +chartLineId },
        })
      );
    }
  };

  if (!tasks?.length) return null;

  return (
    <Wrapper>
      <Gantt
        tasks={tasks}
        columns={columns}
        viewMode={viewMode}
        viewDate={viewDate}
        canMoveTasks={false}
        preStepsCount={5}
        onDoubleClick={handleRowClick}
        fontFamily="Montserrat"
        fontSize="14px"
        distances={{
          rowHeight: 56,
          barCornerRadius: 1,
          headerHeight: 56,
          barFill: 36,
          tableWidth: 380,
        }}
        colors={{
          taskDragColor: hexToRgba(palette.secondary.main, 0.08),
          selectedTaskBackgroundColor: hexToRgba(palette.secondary.main, 0.08),
          oddTaskBackgroundColor: "transparent",
          evenTaskBackgroundColor: "transparent",
        }}
        TooltipContent={({ task }) => {
          return (
            <Stack
              bgcolor={palette.primary.main}
              gap={0.5}
              py={1}
              px={2}
              borderRadius={1}
            >
              <Typography color="primary.contrastText" variant="body4">
                {task.name}
              </Typography>

              <Stack>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body4"
                    fontSize={8}
                    sx={{ color: hexToRgba(palette.primary.contrastText, 0.7) }}
                  >
                    Начало
                  </Typography>
                  <Typography
                    color="secondary"
                    variant="body4"
                    fontSize={8}
                    fontWeight={700}
                  >
                    /
                  </Typography>
                  <Typography
                    variant="body4"
                    fontSize={8}
                    sx={{ color: hexToRgba(palette.primary.contrastText, 0.7) }}
                  >
                    Конец
                  </Typography>
                </Stack>

                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body4"
                    fontSize={8}
                    fontWeight={600}
                    sx={{ color: hexToRgba(palette.primary.contrastText, 0.7) }}
                  >
                    {getLocaleDateFormat(task.start)}
                  </Typography>
                  <Typography
                    variant="body4"
                    fontSize={8}
                    fontWeight={600}
                    sx={{ color: hexToRgba(palette.primary.contrastText, 0.7) }}
                  >
                    {getLocaleDateFormat(task.end)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          );
        }}
      />
    </Wrapper>
  );
};
