import React from "react";

import { TFunction } from "i18next";

import { Stack, Typography, Tooltip, Chip } from "@mui/material";
import { COLUMN_SIZE, getLocaleDateFormat } from "@sbm/fe-utils";
import { IGanttChartExecutor } from "@types";
import { Column } from "@wamra/gantt-task-react";

import { GanttChartTableActions } from "./GanttChartTable/GanttChartTableActions";

export const getColumns = (
  t: TFunction,
  isColumnsHidden: boolean
): Column[] => {
  const NameColumn: Column = {
    id: "name",
    title: (
      <Typography variant="h9_semiBold" sx={{ pl: 2 }}>
        {t("charts_table.name")}
      </Typography>
    ),
    width: COLUMN_SIZE * 4,
    canResize: true,
    Cell: (column) => {
      const task = column.data.task;
      // @ts-ignore
      const context = task.context;

      return (
        <Stack flexDirection="row" alignItems="center" gap={2} py={1} px={2}>
          <Tooltip arrow placement="top" title={task.name}>
            <Typography
              variant="body2"
              fontWeight={context?.headerOfChapter ? 600 : 400}
            >
              {`${context?.lineNumber || ""} ${task.name}`}
            </Typography>
          </Tooltip>
        </Stack>
      );
    },
  };

  const ActionColumn: Column = {
    id: "actions",
    title: "",
    width: 60,
    canResize: false,
    Cell: (column) => {
      const task = column.data.task;
      // @ts-ignore
      const context = task.context;

      if (!context) return <div />;

      const { id } = context;

      return (
        <Stack py={1} px={2}>
          <GanttChartTableActions ganttChartLineId={id} />
        </Stack>
      );
    },
  };

  return [
    ...(!isColumnsHidden
      ? ([NameColumn, ActionColumn] as Column[])
      : ([
          NameColumn,
          {
            id: "executors",
            title: (
              <Typography variant="h9_semiBold" sx={{ pl: 2 }}>
                {t("charts_table.executor")}
              </Typography>
            ),
            width: COLUMN_SIZE * 3,
            canResize: true,
            Cell: (column) => {
              const task = column.data.task;
              // @ts-ignore
              const context = task.context;

              const executors = context?.executors?.[0];
              const executorValue =
                executors?.organizationName ||
                executors?.structuralUnitName ||
                executors?.shortName ||
                executors?.performerName ||
                "";

              const executorsTooltipValue = context?.executors
                ?.map((executor: IGanttChartExecutor, index: number) => {
                  return (
                    executor?.organizationName ||
                    executor?.structuralUnitName ||
                    executor?.shortName ||
                    executor?.performerName ||
                    ""
                  );
                })
                .filter((i: string) => i)
                .join(", ");

              return (
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                  py={1}
                  px={2}
                >
                  <Tooltip arrow placement="top" title={executorsTooltipValue}>
                    <Typography
                      variant="body2"
                      fontWeight={context?.headerOfChapter ? 600 : 400}
                    >
                      {executorValue}
                    </Typography>
                  </Tooltip>
                </Stack>
              );
            },
          },
          {
            id: "status",
            title: (
              <Typography variant="h9_semiBold" sx={{ pl: 2 }}>
                {t("charts_table.status")}
              </Typography>
            ),
            width: COLUMN_SIZE * 2,
            canResize: true,
            Cell: (column) => {
              const task = column.data.task;
              // @ts-ignore
              const context = task.context;

              if (!context) return <div />;

              return (
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                  py={1}
                  px={2}
                >
                  <Chip
                    label={t(`gantt_chart_status.${context.status}`)}
                    className={`status-chip status-chip-${
                      context.styleForStatus || "secondary"
                    }`}
                  />
                </Stack>
              );
            },
          },
          {
            id: "duration",
            title: (
              <Typography variant="h9_semiBold" sx={{ pl: 2 }}>
                {t("charts_table.duration")}
              </Typography>
            ),
            width: COLUMN_SIZE * 3,
            canResize: true,
            Cell: (column) => {
              const task = column.data.task;
              // @ts-ignore
              const context = task.context;

              if (!context) return <div />;

              const {
                durationPlannedCalendarDays,
                durationPlannedWorkingDays,
                durationActualCalendarDays,
                durationActualWorkingDays,
              } = context;

              return (
                <Stack py={1} px={2}>
                  {durationPlannedCalendarDays || durationPlannedWorkingDays ? (
                    <Typography variant="body2">
                      {durationPlannedCalendarDays
                        ? `${durationPlannedCalendarDays} ${t("days")} `
                        : ""}
                      {durationPlannedWorkingDays
                        ? `(${durationPlannedWorkingDays} ${t("work_days")})`
                        : ""}
                    </Typography>
                  ) : null}

                  {durationActualCalendarDays || durationActualWorkingDays ? (
                    <Typography variant="body2">
                      {durationActualCalendarDays
                        ? `${durationActualCalendarDays} ${t("days")} `
                        : ""}
                      {durationActualWorkingDays
                        ? `(${durationActualWorkingDays} ${t("work_days")})`
                        : ""}
                    </Typography>
                  ) : null}
                </Stack>
              );
            },
          },
          {
            id: "period",
            title: (
              <Typography variant="h9_semiBold" sx={{ pl: 2 }}>
                {t("charts_table.period")}
              </Typography>
            ),
            width: COLUMN_SIZE * 3,
            canResize: true,
            Cell: (column) => {
              const task = column.data.task;
              // @ts-ignore
              const context = task.context;

              if (!context) return <div />;

              const {
                plannedStartDate,
                plannedCompletionDate,
                actualStartDate,
                actualCompletionDate,
              } = context;

              return (
                <Stack py={1} px={2}>
                  {plannedStartDate && plannedCompletionDate && (
                    <Typography variant="body2">
                      {getLocaleDateFormat(plannedStartDate)} -{" "}
                      {getLocaleDateFormat(plannedCompletionDate)}
                    </Typography>
                  )}
                  {actualStartDate && actualCompletionDate && (
                    <Typography variant="body2">
                      {getLocaleDateFormat(actualStartDate)} -{" "}
                      {getLocaleDateFormat(actualCompletionDate)}
                    </Typography>
                  )}
                </Stack>
              );
            },
          },
          ActionColumn,
        ] as Column[])),
  ];
};
