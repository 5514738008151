import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";

export const WidgetWrapper = styled(Card)`
  ${FLEX_COLUMN};
  height: 100%;
  min-height: 200px;
  max-height: 360px;

  border-bottom: 4px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(4)};

  box-shadow: ${({ theme }) => theme.shadows[4]};
  padding: ${({ theme }) => theme.spacing(0, 4, 4, 4)};
};
`;
