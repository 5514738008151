import {
  DeliveryMethodEnum,
  IDocumentById,
  IncomingDocumentEntity,
} from "@types";

export const createRequestBody = (body: IncomingDocumentEntity) => {
  const formData = new FormData();

  const keys = Object.keys(body) as (keyof IncomingDocumentEntity)[];
  keys.forEach((key) => {
    if (
      key !== "files" &&
      key !== "links" &&
      key !== "constructionComplexes" &&
      key !== "typeOfAttachment"
    ) {
      formData.append(key, body[key] as string);
    }
  });

  if (body.files?.length) {
    body.files?.forEach((file) => {
      formData.append("files", file);
    });
  }

  if (body.links?.length) {
    body.links?.forEach((link, index) => {
      formData.append(`links[${index}]`, link.id.toString());
    });
  }

  if (body.typeOfAttachment?.length) {
    body.typeOfAttachment?.forEach((typeOfAttachment, index) => {
      formData.append(
        `typeOfAttachment[${index}]`,
        typeOfAttachment.toString()
      );
    });
  }

  if (body.constructionComplexes?.length) {
    body.constructionComplexes?.forEach((complex, index) => {
      formData.append(`constructionComplexes[${index}]`, complex.toString());
    });
  }

  return formData;
};

export const isNextButtonActive = (
  fields: IncomingDocumentEntity,
  isEmployeeAndExternalSubInSameOrganization?: boolean,
  textForTopicId?: number,
  topicForTheCorrespondence?: string,
  isEditMode?: boolean
) => {
  let REQUIRED_FIELDS: (keyof IncomingDocumentEntity)[] = [
    "regNumberForCounterparty",
    "correspondenceSubtype",
    "dateOfRegistration",
    "personalDataId",
    "senderEmployeeId",
    "senderExternalSubstitutionId",
    "senderOrganizationId",
    "externalOrganizationStaffId",
    "mainActorEmployeeId",
    "mainActorExternalSubstitutionId",
    "recipientOrganizationId",
    "deliveryMethod",
    "storageOrderForOriginal",
  ];

  if (fields.replyToOutgoingLetter) {
    REQUIRED_FIELDS.push("initiatingCorrespondenceId");
  }

  if (fields.externalOrganizationStaffId) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (i) =>
        i !== "personalDataId" &&
        i !== "senderEmployeeId" &&
        i !== "senderExternalSubstitutionId"
    );
  }

  if (fields.senderEmployeeId) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (i) =>
        i !== "personalDataId" &&
        i !== "externalOrganizationStaffId" &&
        i !== "senderExternalSubstitutionId"
    );
  }

  if (fields.senderExternalSubstitutionId) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (i) =>
        i !== "personalDataId" &&
        i !== "externalOrganizationStaffId" &&
        i !== "senderEmployeeId"
    );
  }

  if (fields.personalDataId) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (i) =>
        i !== "senderEmployeeId" &&
        i !== "senderExternalSubstitutionId" &&
        i !== "externalOrganizationStaffId" &&
        i !== "senderOrganizationId"
    );
  }

  if (fields.mainActorExternalSubstitutionId) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (i) => i !== "mainActorEmployeeId"
    );
  }

  if (fields.mainActorEmployeeId) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (i) => i !== "mainActorExternalSubstitutionId"
    );
  }

  if (fields.deliveryMethod === DeliveryMethodEnum.byEmail) {
    REQUIRED_FIELDS = REQUIRED_FIELDS.filter(
      (i) => i !== "storageOrderForOriginal"
    );
  }

  if (isEmployeeAndExternalSubInSameOrganization && !isEditMode) {
    REQUIRED_FIELDS.push("creatorPosition");
  }

  if (!textForTopicId && !topicForTheCorrespondence) {
    return false;
  }

  return REQUIRED_FIELDS.every((key) => {
    return fields[key] || fields[key] === false;
  });
};

export const getDefaultDataForEdit = (
  data?: IDocumentById
): IncomingDocumentEntity | null => {
  if (!data) return null;

  return {
    confidentially: data.confidentially,
    constructionComplexes: data.constructionComplexes.map((i) => i.id),
    contextForTheCorrespondence: data.contextForTheCorrespondence,
    correspondenceSubtype: data.correspondenceSubtype,
    dateOfRegistration: data.dateOfRegistration
      ? new Date(data.dateOfRegistration)
      : undefined,
    deliveryMethod: data.deliveryMethod,
    emailId: data.emailId,
    externalOrganizationStaffId: data.sender.externalOrganizationStaffId,
    id: data.id,
    initiatingCorrespondenceId: data.initiatingCorrespondenceId,
    initiatingDocumentOutTheSystem: data.initiatingDocumentOutTheSystem,
    initiatingDocumentRegistrationDate: data.initiatingCorrespondenceDate
      ? new Date(data.initiatingCorrespondenceDate)
      : undefined,
    legalEntity: data.sender.legalEntity,
    mainActorEmployeeId: data.recipients[0]?.employeeId,
    mainActorEmployeePosition: data.recipients[0]?.position,
    personalDataId:
      !data.sender.employeeId && !data.sender.externalSubstitutionId
        ? data.sender.personalDataId
        : undefined,
    recipientOrganizationId: data.recipients[0]?.organizationId,
    regNumberForCounterparty: data.numberAtCounterparty,
    replyToOutgoingLetter: !!data.initiatingCorrespondenceId,
    selectConstructionComplexes: data.constructionComplexes.length > 0,
    senderEmployeeId: data.sender.employeeId,
    senderEmployeePosition: data.sender.position,
    senderOrganizationId: data.sender.organizationId,
    storageOrderForOriginal: data.storageOrderForOriginal,
    textForTopicId: data.textForTopicId,
    topicForTheCorrespondence: data.topicForTheCorrespondence,
  };
};
