import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSwitchWidgetMutation } from "api";
import { Icon } from "ui-kit";

import { Switch, Tooltip, Typography } from "@mui/material";
import { TemplateNumberForWidgetEnum, TIconNames } from "@types";

import { IconWrapper, WidgetHeaderWrapper } from "./styles";

export interface WidgetHeaderProps {
  title: string;
  icon?: TIconNames | null;
  translated?: boolean;
  id?: number;
  type?: TemplateNumberForWidgetEnum;
  switcherOn?: boolean;
  onHomePage?: boolean;
}

export const WidgetHeader = ({
  title,
  icon,
  translated = false,
  id,
  type,
  switcherOn = false,
  onHomePage = false,
}: WidgetHeaderProps) => {
  const { t } = useTranslation();

  const { mutate: updateSwitcher } = useSwitchWidgetMutation();

  const [checked, setChecked] = useState(switcherOn);

  useEffect(() => {
    setChecked(switcherOn);
  }, [switcherOn]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!id) return;

    const newChecked = e.target.checked;
    setChecked(newChecked);

    setTimeout(() => {
      updateSwitcher({ id, validity: newChecked });
    }, 200);
  };

  return (
    <WidgetHeaderWrapper>
      <div className="widget_icon_title">
        {icon &&
        type !== TemplateNumberForWidgetEnum.D1 &&
        type !== TemplateNumberForWidgetEnum.E1 ? (
          <IconWrapper>
            <Icon name={icon} size={48} />
          </IconWrapper>
        ) : null}

        <Typography variant="h6" fontWeight={700} sx={{ textAlign: "initial" }}>
          {translated ? title : t(title)}
        </Typography>
      </div>
      {id && !onHomePage ? (
        <Tooltip placement="top" title={"Показать на главной странице"}>
          <Switch
            className="switch-ignore"
            color="secondary"
            checked={checked}
            onChange={handleChange}
          />
        </Tooltip>
      ) : null}
    </WidgetHeaderWrapper>
  );
};
