import React from "react";
import { useTranslation } from "react-i18next";

import { Chip } from "@mui/material";
import { StatusForCorrespondenceEnum } from "@types";

import { getLabel } from "./helpers";

interface CorrespondenceStatusChipProps {
  status: StatusForCorrespondenceEnum;
  styleForStatus: string | undefined;
}

export const CorrespondenceStatusChip: React.FC<
  CorrespondenceStatusChipProps
> = ({ status, styleForStatus }) => {
  const { t } = useTranslation("correspondence");

  return (
    <Chip
      label={getLabel(status, t)}
      className={`status-chip status-chip-${styleForStatus}`}
    />
  );
};
