import { FC, useCallback, useMemo, useRef } from "react";

import { LinearProgress, useTheme } from "@mui/material";
import {
  DataGridProProps,
  GridRowParams,
  GridSortItem,
  ruRU,
  GridRowId,
  GridColumnVisibilityModel,
  GridRow,
  DataGridPro,
} from "@mui/x-data-grid-pro";
import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";

import { Icon } from "../Icon";
import { CustomToolbar, CustomNoRowsOverlay, EmptyTable } from "./components";
import { TableProps } from "./types";

const locale = getStorageItem(STORAGE_KEYS.lang);
const localeText =
  locale === "ru"
    ? ruRU.components.MuiDataGrid.defaultProps.localeText
    : undefined;

export const Table: FC<TableProps> = ({
  rows = [],
  columns,
  loading,
  rowCount = 0,
  page = 0,
  hasNextPage,
  pageSize = 10,
  sortModel,
  onRowClick,
  onCellClick,
  onSelectionChange,
  onSortModelChange,
  onPageChange,
  onPageSizeChange,
  rowsPerPageOptions = [10, 20, 50],
  hasToolbar = false,
  columnVisibilityModel,
  setColumnVisibilityModel,
  treeData,
  getTreeDataPath,
  groupingColDefName,
  checkboxSelection = false,
  rowReordering,
  onRowOrderChange,
  hideFooter = true,
  hideFooterPagination = true,
  apiRef,
  getRowHeight,
  rowModesModel,
  onRowModesModelChange,
  slots,
  editMode = "row",
  filterableFields,
  onFilterChange,
  onSearchOptionChange,
  fetchNextPage,
  hasPagination = true,
  disableVirtualization = true,
  textForEmptyTable = "",
  filteringButtons,
  ...rest
}) => {
  const theme = useTheme();
  const observer = useRef<IntersectionObserver | undefined>();

  const DataGridComponents = useMemo(() => {
    if (slots) return slots;

    return {
      toolbar: hasToolbar
        ? () => (
            <CustomToolbar
              filterableFields={filterableFields}
              onSearchOptionChange={onSearchOptionChange}
              onFilterChange={onFilterChange}
              filteringButtons={filteringButtons}
            />
          )
        : null,
      noRowsOverlay: () =>
        textForEmptyTable ? (
          <EmptyTable text={textForEmptyTable} />
        ) : (
          <CustomNoRowsOverlay />
        ),
      loadingOverlay: LinearProgress,
      detailPanelExpandIcon: () => (
        <Icon
          color={theme.palette.primary.main}
          name="ChevronRight"
          onClick={() => null}
        /> // Need this onclick to handle correct css
      ),
      detailPanelCollapseIcon: () => (
        <Icon
          color={theme.palette.primary.main}
          name="ChevronDown"
          onClick={() => null}
        />
      ),
      row: (props: any) => {
        const index = props.index;
        const lastItemIndex = rows.length - 1;

        if (lastItemIndex === index && !loading) {
          return (
            <div ref={lastOptionElementRef}>
              <GridRow {...props} />
            </div>
          );
        }

        return <GridRow {...props} />;
      },
    };
    // eslint-disable-next-line
  }, [
    filterableFields,
    hasToolbar,
    slots,
    rows.length,
    loading,
    filteringButtons,
  ]);

  const handleRowClick = (params: GridRowParams) => onRowClick?.(params);

  const handleRowsSelection = useCallback(
    (ids: GridRowId[]) => {
      const selectedRowsData = ids.map((id) =>
        rows.find((row) => row.id === id)
      );
      onSelectionChange?.(selectedRowsData);
    },
    [onSelectionChange, rows]
  );

  const handleColumnVisibilityModel = useCallback(
    (newModel: GridColumnVisibilityModel) =>
      setColumnVisibilityModel?.(newModel),
    [setColumnVisibilityModel]
  );

  const SortingModel = useMemo(() => {
    if (!sortModel) return [];

    const parts = sortModel.split(":");
    const field = parts[0];
    const sort = parts[1].toLowerCase();

    return [{ field, sort }] as GridSortItem[];
  }, [sortModel]);

  const groupingColDef: DataGridProProps["groupingColDef"] = useMemo(
    () => ({
      headerName: groupingColDefName,
      flex: 1,
      minWidth: 320,
      valueGetter: (params) => params.row.name,
      cellClassName: (params) =>
        params.row.headOfStructuralUnit || params.row.isFontWeightBold
          ? "cell-customize"
          : "",
      hideDescendantCount: true,
    }),
    [groupingColDefName]
  );

  const lastOptionElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        async (entries) => {
          if (loading) return;

          const [target] = entries;

          if (
            target.isIntersecting &&
            target.intersectionRatio > 0 &&
            hasNextPage
          ) {
            fetchNextPage();
          }
        },
        {
          root: null,
          threshold: 0,
        }
      );

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, loading, hasNextPage]
  );

  if (!columns) return null;

  return (
    <DataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      loading={loading}
      getRowHeight={getRowHeight}
      paginationModel={{ page, pageSize }}
      groupingColDef={groupingColDef}
      rowCount={rowCount}
      sortModel={SortingModel}
      pageSizeOptions={rowsPerPageOptions}
      onSortModelChange={onSortModelChange}
      onRowSelectionModelChange={handleRowsSelection}
      onRowClick={handleRowClick}
      onCellClick={onCellClick}
      sortingMode={treeData ? "client" : "server"}
      filterMode={treeData ? "client" : "server"}
      paginationMode="server"
      editMode={editMode}
      localeText={localeText}
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick
      slots={DataGridComponents}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityModel}
      treeData={treeData}
      getTreeDataPath={getTreeDataPath}
      isGroupExpandedByDefault={() => Boolean(treeData)}
      rowsLoadingMode="server"
      rowReordering={rowReordering}
      onRowOrderChange={onRowOrderChange}
      hideFooterPagination={!hasPagination}
      hideFooter={!hasPagination}
      rowModesModel={rowModesModel}
      onRowModesModelChange={onRowModesModelChange}
      getDetailPanelHeight={() => "auto"} // Height based on the content.
      disableVirtualization={disableVirtualization}
      sx={{
        "@media print": {
          ".MuiDataGrid-main": {
            maxHeight: "100% !important",
          },
        },
      }}
      {...rest}
    />
  );
};

export * from "./hooks/useTableQuerySet";
export * from "./hooks/useTableQueryMethods";
