import styled from "styled-components";
import {
  FLEX_COLUMN,
  FLEX_COLUMN_FULL,
  FLEX_ROW_ALIGN_CENTER,
  FLEX_ROW,
} from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

import CorrespondenceDocumentCard_image from "../../../../../../../../../assets/backgrounds/letter_card.svg";

export const MainWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  ${FLEX_ROW};
  justify-content: space-between;  background: linear-gradient(
    91deg,
    ${({ theme }) => hexToRgba(theme.palette.primary.main, 0.12)} 0%,
    ${({ theme }) => hexToRgba(theme.palette.primary.main, 0.01)} 100%
  );
  border-bottom: 4px solid ${({ theme }) => theme.palette.primary.main};

  color: ${({ theme }) => theme.palette.text.secondary};
  box-shadow: ${({ theme }) => theme.shadows[4]};
  .recipientCount {
    height: 24px;
    width: 24px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 50%;
    color: white;
    ${FLEX_COLUMN_FULL}
`;
export const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const ContentWrapper = styled.div`
  ${FLEX_COLUMN};

  .title {
    min-height: 22px;
  }

  .title-text {
    ${FLEX_COLUMN};
  }

  position: relative;
  z-index: 9;
`;

export const CardImageWrapper = styled.div`
  background-image: ${() => `url(${CorrespondenceDocumentCard_image})`};
  background-position: center;
  height: 219px;
  width: 444px;
  background-repeat: no-repeat;
`;

export const ImageWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: end;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: none;
  }
`;

export const EllipsisTextWrapper = styled.div<{ $height?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: ${({ $height }) => `${$height}px`};
  max-height: ${({ $height }) => `${$height}px`};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => hexToRgba(theme.palette.primary.contrastText, 0.7)};
  &:hover {
    cursor: pointer;
  }
`;
