import React from "react";

import { Grid, Stack, Typography } from "@mui/material";
import { IChartDataTypeD } from "@types";

import { ContentWrapper, ImageWrapper, VerticalDivider } from "./styles";

interface TypeD1WidgetChartProps {
  data?: IChartDataTypeD;
  imageUrl?: string;
}

export const TypeD1WidgetChart = ({
  data,
  imageUrl,
}: TypeD1WidgetChartProps) => {
  const rightSubParams = data?.rightSubParameters || [];

  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      gap={2}
      width="100%"
    >
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <ImageWrapper>
            <img
              src={imageUrl || ""}
              height="100%"
              width="auto"
              alt="project_img"
              draggable={false}
            />
          </ImageWrapper>
        </Grid>
        <Grid item xs={8}>
          <ContentWrapper>
            <Typography
              variant="h8_bold"
              fontWeight={700}
              color="text.secondary"
              textAlign="center"
              sx={{ opacity: 0.7 }}
            >
              {rightSubParams[0]?.value ?? "-"}
            </Typography>
            <VerticalDivider />
            <Typography
              variant="h9_semiBold"
              fontWeight={700}
              color="text.secondary"
              textAlign="center"
              sx={{ opacity: 0.7 }}
            >
              {rightSubParams[1]?.value ?? "-"}
            </Typography>
          </ContentWrapper>
        </Grid>
      </Grid>
    </Stack>
  );
};
