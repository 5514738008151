import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import * as echarts from "echarts";

import { Stack, Typography, useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { IChartDataTypeA } from "@types";

export const RingGaugeChart = ({
  uniqueId,
  data,
}: {
  uniqueId?: number | string;
  data: IChartDataTypeA;
}) => {
  const theme = useTheme();
  const { t } = useTranslation("project");

  useEffect(() => {
    const chartDom = document.getElementById(`gaugeChart${uniqueId}`);
    if (!chartDom) return;

    const chart = echarts.init(chartDom);

    const option: echarts.EChartsOption = {
      tooltip: {
        show: true,
        confine: true,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        textStyle: {
          color: theme.palette.text.primary,
          fontSize: 14,
        },
        formatter: (params: any) => {
          return `<div style="display: flex; flex-direction: row; align-items: center; gap: 8px;
                  min-width: 150px; background: ${theme.palette.primary.main};
                  border-radius: 8px; white-space: normal; word-break: break-word;">
              <div style="color: #fff; flex: 1;">${params.name}</div>
              <div style="background: #fff; height: 20px; padding: 4px 8px;
                          border-radius: 8px; display: flex; align-items: center; justify-content: center;">
                  <strong style="color: #1B2C41">${params.value}</strong>
              </div>
            </div>`;
        },
      },
      series: [
        {
          type: "gauge",
          startAngle: 270,
          endAngle: -90,
          pointer: { show: false },
          max: data?.leftParameter?.value,
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              color: theme.palette.secondary.main,
              borderWidth: 3,
              borderColor: "#FBF2F0",
            },
          },
          emphasis: {
            itemStyle: {
              color: hexToRgba(theme.palette.secondary.main, 0.8),
            },
          },

          axisLine: {
            lineStyle: {
              width: 40,
              color: [[1, hexToRgba(theme.palette.secondary.main, 0.08)]],
            },
          },
          splitLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          data: data?.leftSubParameters,
          title: { show: false },
          detail: {
            show: true,
            color: theme.palette.secondary.main,
            fontSize: "24px",
            fontWeight: 700,
            formatter: `${data?.leftParameter?.value}`,
            offsetCenter: [0, "0%"],
          },
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [uniqueId, theme, data]);

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      <div
        id={`gaugeChart${uniqueId}`}
        style={{
          width: "180px",
          height: "180px",
          marginTop: "-8px",
        }}
      />
      {data?.leftParameter && (
        <Typography
          variant="body3"
          fontWeight={600}
          color={theme.palette.text.secondary}
          sx={{ opacity: 0.7, mt: "-12px" }}
        >
          {t(`charts_status.${data.leftParameter?.name}`)}
        </Typography>
      )}
    </Stack>
  );
};
