import React, { useEffect, useState } from "react";

import { Grid, Stack, Typography } from "@mui/material";
import { IChartDataTypeC } from "@types";

export const MultipleNumbersChart = ({ data }: { data: IChartDataTypeC }) => {
  const [animatedValues, setAnimatedValues] = useState<number[]>(
    () => data?.leftSubParameters?.map(() => 0) || []
  );

  useEffect(() => {
    const intervals = data?.leftSubParameters?.map((item, index) => {
      return setInterval(() => {
        setAnimatedValues((prevValues) => {
          const newValues = [...prevValues];
          if (newValues[index] < item.value) {
            newValues[index] += Math.ceil(item.value / 50);
          } else {
            newValues[index] = item.value;
            clearInterval(intervals[index]);
          }
          return [...newValues];
        });
      }, 20);
    });

    return () => {
      intervals?.forEach(clearInterval);
    };
  }, [data]);

  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="270px"
      gap={2}
    >
      <Grid container gap={2}>
        {data?.leftSubParameters?.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Stack
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              width="100%"
            >
              <Typography
                variant="body2"
                fontWeight={600}
                color="text.secondary"
                sx={{ opacity: 0.7 }}
              >
                {item.name}
              </Typography>
              <Typography variant="h8_bold" color="text.primary">
                {animatedValues[index]}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
