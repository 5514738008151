import React from "react";
import { useTranslation } from "react-i18next";

import { Stack, Typography, useTheme } from "@mui/material";
import { IChartDataTypeA } from "@types";

export const CustomBarChart = ({ data }: { data: IChartDataTypeA }) => {
  const theme = useTheme();
  const { t } = useTranslation("project");

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      <Stack
        height={160}
        width={160}
        borderRadius={2}
        bgcolor={theme.palette.text.disabled}
        marginY={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3" color={theme.palette.text.secondary}>
          {data?.rightParameter.value}
        </Typography>
      </Stack>
      {data?.rightParameter.name && (
        <Typography
          variant="body2"
          fontWeight={600}
          color={theme.palette.text.secondary}
          sx={{ opacity: 0.7, borderRadius: "8px" }}
        >
          {t(`charts_status.${data.rightParameter?.name}`)}
        </Typography>
      )}
    </Stack>
  );
};
