import React, { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { SnackbarMessages } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { IGenerateDocumentForm } from "@types";

import { axiosService } from "../../../../services";

interface Props {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      onSuccess: () => void;
      correspondenceId: number;
      requestPayload: IGenerateDocumentForm;
    };
  };
}

export const GenerateFileForOutgoingDocumentPopup: React.FC<Props> = ({
  open,
  onClose,
  data: { data: payloadData },
}) => {
  const { t } = useTranslation("correspondence");
  const { t: tProjects } = useTranslation("project");

  const [loading, setLoading] = useState(false);

  if (!payloadData) return null;

  const { requestPayload, correspondenceId, onSuccess } = payloadData;

  const handleSave = async () => {
    setLoading(true);

    try {
      await axiosService({
        endpoint: `correspondence/${correspondenceId}/generate`,
        method: "POST",
        body: requestPayload,
      });

      onSuccess();
      toast.success(SnackbarMessages.success);
    } catch (e) {
      toast.error(SnackbarMessages.error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const content = {
    title: t("generate_file_drawer.generate_confirmation_text", {
      fileName: requestPayload.fileName,
    }),
    secondaryButton: (
      <Button variant="outlined" color="secondary" onClick={onClose}>
        {tProjects("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        color="secondary"
        onClick={handleSave}
        loading={loading}
        disabled={loading}
      >
        {tProjects("ok")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
