import { useGetGanttCharPlannedDates } from "api";
import { useAppSelector } from "app";

import { DrawerWithDataVariantsEnum } from "@types";

import { AssignmentPlannedDatesForm } from "./AssignmentPlannedDatesForm";

export const AssignmentPlannedDatesContainer = () => {
  const { drawerWithData } = useAppSelector((state) => state.global);

  const chartId = drawerWithData?.content?.data?.chartId;

  const { data } = useGetGanttCharPlannedDates(Number(chartId));

  if (
    drawerWithData?.variant !==
    DrawerWithDataVariantsEnum.assignmentPlannedDates
  )
    return null;

  return <AssignmentPlannedDatesForm data={data} chartId={chartId} />;
};
