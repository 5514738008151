import React from "react";
import { Control, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { Input } from "@sbm/ui-components";
import { IRegisterSupportRequestPayload, ITaskById } from "@types";

import { RequestDateScheduler } from "./ResuestDateSceduler";

interface Props {
  executionRequirements: ITaskById["executionRequirements"];
  registration: ITaskById["registration"];
  register: UseFormRegister<IRegisterSupportRequestPayload>;
  control: Control<IRegisterSupportRequestPayload>;
  setValue: UseFormSetValue<IRegisterSupportRequestPayload>;
}

export const RequestContentForm: React.FC<Props> = ({
  executionRequirements,
  registration,
  register,
  control,
  setValue,
}) => {
  const { t } = useTranslation("tasks");

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
        <Typography variant="h9_semiBold" color="text.disabled">
          {t("feedback.request")}
        </Typography>

        <Input
          multiline
          disabled
          label={t("feedback.request_text")}
          variant="outlined"
          size="medium"
          linesCount={5}
          {...register("textOfTheTask")}
        />

        <RequestDateScheduler control={control} setValue={setValue} />

        <FormControlLabel
          disabled
          label={t("personal_control")}
          control={
            <Switch
              color="secondary"
              defaultChecked={executionRequirements.personalControl}
            />
          }
        />

        <FormControlLabel
          disabled
          label={t("higher_priority")}
          control={
            <Switch color="secondary" defaultChecked={registration.priority} />
          }
        />
      </CardContent>
    </Card>
  );
};
