import {
  ContractStatusEnum,
  CustomFile,
  IAttachLink,
  ILink,
  ILinkedEntity,
  StatusForCorrespondenceEnum,
  OperatesOnBasisEnum,
  CorrespondenceTypeEnum,
  CorrespondenceSubtypeEnum,
  DeliveryMethodEnum,
  StorageOrderForOriginalEnum,
  StructuralUnitTypesEnum,
  StructuralUnitTypesForCorrespondenceEnum,
} from "@types";

export interface IProjectById {
  fullTextConstructionComplexName: string;
  id: number;
}

export enum StandartParameterNameEnum {
  senderOrganization = "sender_organization",
  recipientOrganization = "recipient_organization",
  fullNameOfTheSigner = "full_name_of_the_signer",
  recipientName = "recipient_name",
}

export enum StandartTypeOfTheDocumentEnum {
  contract = "contract",
  letter = "letter",
  order = "order",
  serviceNote = "service_note",
  protocol = "protocol",
  powerOfAttorney = "power_of_attorney",
  manual = "manual",
}

export interface IProjectProjectParticipants {
  id: number;
  briefTextOrganizationName: string;
  roleForParticipant: RoleForParticipantEnum;
  roleNames: string[];
  shortName: string;
  organizationId?: number;
}

export interface IProjectStakeholder {
  participantOfProjectStakeholderId: number;
}
export interface IShareDocument {
  documentId: number;
  participantOfProjectStakeholderId: number;
  constructionComplexId: number;
}

export interface IProjectParameter {
  valueOfParameter: string;
  standartParameterName: StandartParameterNameEnum; // 200 symbol
  customParameterName: string; // 1000 symbol
}

export enum RandomDocumentTypeOfAttachmentEnum {
  mainDocument = "main_document",
  appendix = "appendix",
  deliveryConfirmation = "delivery_confirmation",
  supportingFile = "supporting_file",
}

export interface IProjectForm {
  parameters: IProjectParameter[];
  links?: IAttachLink[] | number[];
  files?: CustomFile[];
  fileIds?: number[];
  typeOfAttachment: RandomDocumentTypeOfAttachmentEnum[];
  constructionComplexId: number | string;
  organizationId?: number;
  participantOfProjectStakeholderId?: string | number;
  documentName: string;
  context?: string;
  selectedParameters?: {
    index?: number;
    name?: string;
    value?: string;
  }[];
  standartTypeOfTheDocument?: StandartTypeOfTheDocumentEnum;
  customTypeOfTheDocument?: string;
  customParameter?: string;
  fileAttachments?: {
    fileId: number;
    typeOfAttachment: RandomDocumentTypeOfAttachmentEnum;
  }[];
}

export enum GanttChartTypeOfAttachmentEnum {
  main_document = "main_document",
  appendix = "appendix",
  supporting_file = "supporting_file",
}

export enum FileAttachedToGanttChartFileStatusEnum {
  draftDocument = "draft_document",
  signedDocument = "signed_document",
}

export enum TypeOfDocumentEnum {
  contract = "contract",
  incomingDocument = "incoming_document",
  outgoingDocument = "outgoing_document",
  serviceNote = "service_note",
  randomArchiveDocument = "random_archive_document",
  supplementaryAgreement = "supplementary_agreement",
  oad = "oad",
  workplan = "workplan",
  minutesInternalMeeting = "minutes_internal_meeting",
  message = "message",
  task = "task",
  regularTask = "regular_task",
  externalLink = "external_link",
  letter = "letter",
  order = "order",
  protocol = "protocol",
  powerOfAttorney = "power_of_attorney",
  manual = "manual",
}

export interface Rubric {
  rubricId: number;
  nameOfTheRubric: string;
}

export interface IProjectArchive {
  documentId?: number;
  constructionComplexId?: number;
  contractId?: number;
  randomArchiveDocumentId?: number;
  correspondenceId?: number;
  longNameOfTheDocument?: string;
  outerNameOfTheDocument?: string;
  status?: ContractStatusEnum | StatusForCorrespondenceEnum;
  typeOfTheDocument?: TypeOfDocumentEnum;
  typeOfRandomArchiveDocument?: string;
  registrationNumber: string;
  registrationDate: Date;
  contextOfTheDocument: string;
  createdAt: Date;
  rubrics?: Rubric[];
  participantShortName?: string;
  participantBriefTextOrganizationName?: string;
  isInExternalArchive?: boolean;
  id: number;
}

export enum RoleNameProjectStakeholderEnum {
  investor = "investor",
  customer = "customer",
  technicalCustomer = "technical_customer",
  generalDesigner = "general_designer",
  subdesigner = "subdesigner",
  generalContractor = "general_contractor",
  subcontractor = "subcontractor",
  supplier = "supplier",
  supervisoryAgency = "supervisory_agency",
  prospector = "prospector",
  externalExpert = "external_expert",
}

export enum RoleForParticipantEnum {
  projectManager = "project_manager",
  constructionSupervision = "construction_supervision",
  technologicalSupport = "technological_support",
  pricing = "pricing",
  supply = "supply",
  accountingSupport = "accounting_support",
  chiefProjectEngineer = "chief_project_engineer",
  design = "design",
  projectImplementationControl = "project_implementation_control",
}

export interface IParticipant {
  id: number;
  longName: string;
  roleForParticipant: RoleForParticipantEnum[];
  chiefProjectManager: boolean;
  parentId: number | null;
  personalDataId: number;
  shortName: string;
}

export enum FunctionalDirectionEnum {
  projectManagement = "project_management",
  design = "design",
  construction = "construction",
  supply = "supply",
}

export interface IRoles {
  id: number;
  roleNameProjectStakeholder: RoleNameProjectStakeholderEnum;
  functionalDirection: FunctionalDirectionEnum;
  description: string;
}

export interface IProjectStructure {
  id: number;
  roles: IRoles[];
  participants: IParticipant[];
  organizationId: number;
  organizationName: string;
  parentId: number | null;
}

export enum StandartTypeOfGanttChartEnum {
  projectImplementationSchedule = "project_implementation_schedule",
  workSchedule = "work_schedule",
  designSchedule = "design_schedule",
  deliverySchedule = "delivery_schedule",
}

export type ICreateChart = {
  ganttChartName: string;
  description?: string;
  participantOfProjectStakeholderId: number | string | undefined;
  typeOfGanttChart?: string | undefined;
  links?: ILink[] | number[];
  files?: CustomFile[];
  fileIds?: number[];
  typeOfAttachment?: GanttChartTypeOfAttachmentEnum[] | undefined;
  ganttChartPlannedStartDate: Date | string;
  ganttChartPlannedCompletionDate: Date | string;
  standartTypeOfGanttChart?: ParameterForRandomArchiveDocumentEnum | string;
  customTypeOfGanttChart?: string;
  fileAttachments?: {
    fileId: number;
    typeOfAttachment: GanttChartTypeOfAttachmentEnum;
  }[];
};

export interface IParameters {
  name: string;
  value: string;
}

export enum ParameterForRandomArchiveDocumentEnum {
  senderOrganization = "sender_organization",
  recipientOrganization = "recipient_organization",
  fullNameOfTheSigner = "full_name_of_the_signer",
  recipientName = "recipient_name",
}

export interface IRandomArchiveDocument {
  id: number;
  context: string;
  creationDate: string;
  documentName: string;
  documentType: StandartTypeOfTheDocumentEnum;
  isClassifiedDocument: boolean;
  parameters: IParameters[];
  registeredBy: string;
  registrationNumber: string;
}

export interface IRandomArchiveDocumentFiles {
  id: number;
  files: IFiles[];
}

export enum FileAttachedToRandomDocumentTypeOfAttachmentEnum {
  mainDocument = "main_document",
  appendix = "appendix",
  deliveryConfirmation = "delivery_confirmation",
  supporting_file = "supporting_file",
}

export interface IFileAttachments {
  fileId: number;
  typeOfAttachment: FileAttachedToRandomDocumentTypeOfAttachmentEnum;
  fileName?: string;
}

export enum LinkAttachedToRandomDocumentTypeOfAttachmentEnum {
  auxiliaryDocument = "auxiliary_document",
}

export interface ILinkAttachments {
  links: number[];
  linkAssignment?: LinkAttachedToRandomDocumentTypeOfAttachmentEnum;
  descriptionForAttachment?: string;
}

export interface IProjectParticipant {
  briefTextOrganizationName: string;
  id: number;
  organizationId: number;
  roleForParticipant: RoleForParticipantEnum;
  roleNames: RoleNameProjectStakeholderEnum[];
  shortName: string;
}

export enum StatusOfGanttChartEnum {
  pending = "pending",
  performance = "performance",
  completed = "completed",
}

export interface IGanttChart {
  actualCompletionDate: Date | string | null;
  actualStartDate: Date | string | null;
  chartType: StandartTypeOfGanttChartEnum | string;
  description: string;
  id: number;
  name: string;
  organizationName: string;
  plannedCompletionDate: Date | string | null;
  plannedStartDate: Date | string | null;
  registartionDate: Date | string | null;
  registrationNumber: string;
  status: StatusOfGanttChartEnum;
}

interface IFileAttachedBy {
  id: number;
  longName: string;
  personalDataId: number;
  shortName: string;
  userId: number;
}

export interface IFiles {
  id: number;
  typeOfAttachment: FileAttachedToRandomDocumentTypeOfAttachmentEnum;
  fileAttachedBy: IFileAttachedBy;
  additionalInformation: string | null;
  convertedPdfFileName?: string;
  fileFormat: string;
  fileNameForSystem: string;
  fileNameForUsers: string;
  fileUploadDate: string;
  fileLink: string;
}

type TEmployee = Omit<IFileAttachedBy, "userId">;

interface ILinks {
  id: number;
  createdAt: string;
  descriptionForAttachment: string;
  employee: TEmployee;
  linkedEntities: ILinkedEntity;
  shortNameOfTheDocument: string;
  typeOfAttachment: LinkAttachedToRandomDocumentTypeOfAttachmentEnum;
}

export interface IDocumentFiles {
  files: IFiles[];
}

export interface IRandomDocumentFiles {
  id: number;
  files: IFiles[];
}

export interface IRandomDocumentLinks {
  id: number;
  links: ILinks[];
}

export interface IAssignmentPlannedDatesForm {
  startPlannedDate: Date | string;
  endPlannedDate: Date | string;
  workingDay: number | string;
  calendarDay: number | string;
}

export enum PerformerTypeEnum {
  organization = "organization",
  projectParticipant = "project_participant",
  department = "department",
  other = "other",
}

export interface IPerformers {
  type?: PerformerTypeEnum;
  organizationId?: number;
  projectOrganizationId?: number;
  projectOrganizationName?: string;
  participantOfProjectStakeholderId?: number;
  participantName?: string;
  structuralUnitId?: number;
  performerName?: string;
}

export interface ICreateRowGanttChart {
  ganttChartId: number;
  ganttChartLineId?: number;
  groupNumber: number;
  lineNumber: string;
  chartLine: ChartElementTypeEnum;
  nameOfLine: string;
  performers?: IPerformers[];
  cause?: string;
  additionalInformation?: string;
  isViewMode?: boolean;
  plannedStartDate: Date | string;
  plannedCompletionDate: Date | string;
  workingDay?: number | string;
  calendarDay?: number | string;
}

export interface ICreateProgressInfo {
  progressIncrement: number;
  comment: string;
}

export interface IGanttChartProgress {
  name: string;
  progress: number;
  attentionDemanding: boolean;
  comment: string;
  progressIncrement: number;
  totalValue: number;
}

export enum ChartElementTypeEnum {
  chartLine = "chart_line",
  chartSectionTitle = "chart_section_title",
}

export interface IGenerateChartGroupNumber {
  ganttChartId: number;
  ganttChartLineId?: number;
}

export interface IGenerateChartData {
  groupNumber: number;
  lineNumber: string;
}
export interface IWidgetResponse {
  id: number;
  leftSubParameters: {
    name: string;
    value: number;
  }[];
  leftParameter: {
    name: string;
    value: number;
  };
  rightParameter: {
    name: string;
    value: number;
  };
}

export interface IChartDataTypeA {
  id: number;
  leftParameter: { name: string; value: number };
  leftSubParameters: { value: number; name: string }[];
  rightParameter: { name: string; value: number };
}

export interface IChartDataTypeB {
  id: number;
  leftParameter: { name: string; value: string | Date };
  rightParameter: { name: string; value: number | string };
}

export interface IChartDataTypeC {
  id: number;
  leftSubParameters: { name: string; value: number }[];
  rightSubParameters: { name: string; value: number | string };
}

export interface IChartDataTypeE {
  id: number;
  rightSubParameters: {
    name: string;
    value: string | number | null;
  }[];
}

export interface IChartDataTypeEUpdated {
  generalProgressForConstruction: number | null;
  generalProgressForProject: number | null;
  pictureFileId: number;
  widgetName: string;
}

export interface IChartDataTypeD {
  id: number;
  rightSubParameters: [
    {
      name: string;
      value: string;
    },
    {
      name: string;
      value: string;
    }
  ];
}

export enum StatusOfWorkEnum {
  pending = "pending",
  performance = "performance",
  completed = "completed",
  draft = "draft",
}

export interface IGanttChartExecutor {
  organizationName?: string;
  structuralUnitName?: string;
  shortName?: string;
  performerName?: string;
}

export interface IGanttChartLines {
  id: number;
  lineNumber: string;
  nameOfLine: string;
  executors: IGanttChartExecutor[];
  status: StatusOfWorkEnum;
  durationPlannedWorkingDays: number | null;
  durationPlannedCalendarDays: number | null;
  durationActualWorkingDays: number | null;
  durationActualCalendarDays: number | null;
  periodPlannedStartDate: Date | null;
  periodPlannedCompletionDate: Date | null;
  periodActualStartDate: Date | null;
  periodActualCompletionDate: Date | null;
  periodExpectedStartDate: Date | null;
  periodExpectedCompletionDate: Date | null;
  node?: number[];
  reason?: string;
  note?: string;
}

export interface IGanttChartGraphic {
  id: number;
  parentGanttChartLineId: number;
  headerOfChapter: boolean;
  plannedStartDate: string | null;
  plannedCompletionDate: string | null;
  expectedStartDate?: string | null;
  expectedCompletionDate?: string | null;
  actualStartDate?: string | null;
  nameOfLine: string;
  actualCompletionDate?: string | null;
}

export interface IGanttChartPlannedDate {
  lineNumber: string;
  nameOfLine: string;
  status: StatusOfWorkEnum;
  minPlannedStartDate: Date | string | null;
  maxPlannedCompletionDate: Date | string | null;
  workingDays: number | null;
  calendarDays: number | null;
}

export interface ProgressInfos {
  id: number;
  ganttChartLineId?: number;
  userId: number;
  comment: string;
  progressIncrement: number;
  validity: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGanttChartLineData {
  actualCompletionDate: Date | string | null;
  actualStartDate: Date | string | null;
  attentionDemanding: boolean;
  expectedCompletionDate: null;
  expectedStartDate: null;
  id: number;
  lineNumber: string;
  nameOfLine: string;
  plannedCompletionDate: Date | string | null;
  plannedStartDate: Date | string | null;
  progressInfos: ProgressInfos[];
  statusOfLine: StatusOfWorkEnum;
  totalProgress: number;
}

export interface IGanttChartActualDate {
  id: number;
  actualCompletionDate: Date;
  actualStartDate: Date;
  attentionDemanding: boolean;
  expectedCompletionDate: Date;
  expectedStartDate: Date;
  lineNumber: string;
  nameOfLine: string;
  plannedCompletionDate: Date | null;
  plannedStartDate: Date | null;
  progressInfos: ProgressInfos[];
  statusOfLine: StatusOfWorkEnum;
  totalProgress: number;
}

export interface IFactDates {
  actualStartDate: Date;
  actualCompletionDate: Date;
  expectedStartDate: Date;
  expectedCompletionDate: Date;
}

export enum TypeOfTheDocument {
  contract = "contract",
  randomArchiveDocument = "random_archive_document",
  outgoingDocument = "outgoing_document",
  incomingDocument = "incoming_document",
  serviceNote = "service_note",
  supplementaryAgreement = "supplementary_agreement",
}

export interface Contract_Parties {
  id: number;
  legalEntity: boolean;
  briefTextOrganizationName?: string;
  fullTextOrganizationName?: string;
  shortName?: string;
  longName?: string;
  operatesOnBasis?: OperatesOnBasisEnum;
  nameTypicalRoleContractParty: string;
}

export interface ViewOuterProjectArchiveDocument_Contract {
  id: number;
  parties: Contract_Parties[];
  statusContract: ContractStatusEnum;
  createdAt: Date;
  dateContract?: Date;
  dateOfStatusChanging?: Date;
  dateValidityBeginningContract?: Date;
  dateValidityExpiryContract?: Date;
  dateExecutionBeginningContract?: Date;
  dateExecutionExpiryContract?: Date;
  priceContractWithoutVat?: number;
  priceContractWithVat?: number;
  internalRegistrationNumber?: string;
  numberContract?: string;
  fullTextTypicalConditionValidityBeginningContract?: string;
  briefTextTypicalConditionValidityBeginningContract?: string;
  fullTextTypicalConditionExecutionBeginningContract?: string;
  briefTextTypicalConditionExecutionBeginningContract?: string;
  fullTextTypicalConditionValidityExpiryContract?: string;
  briefTextTypicalConditionValidityExpiryContract?: string;
  fullTextTypicalConditionExecutionExpiryContract?: string;
  briefTextTypicalConditionExecutionExpiryContract?: string;
  briefTextTypePriceContract?: string;
  fullTextTypePriceContract?: string;
  fullTextPriceSettingOrderContract?: string;
  briefTextPriceSettingOrderContract?: string;
  vatPercentage?: number;
  briefTextTypicalSubjectContract: string;
  fullTextTypicalSubjectContract: string;
  subjectContractAdditionalInformation?: string;
  nameContractType: string;
  contractTypeAdditionalInfo?: string;
  nameContractSubtype: string;
  contractSubtypeAdditionalInfo?: string;
  supplementaryAgreementsCount: number;
}

export interface ViewProjectArchiveDocument_RandomArchiveDocument {
  id: number;
  documentType: StandartTypeOfTheDocumentEnum;
  documentName: string;
  context: string;
  registrationNumber: string;
  creationDate: Date;
  registeredBy: string;
  isClassifiedDocument: boolean;
  parameters: { name: string; value: string }[];
}

export interface Correspondence_StructuralUnit {
  nameOfStructuralUnit: string;
  structuralUnitType: StructuralUnitTypesEnum;
}

export interface Correspondence_PartyWithLegalEntity {
  briefTextOrganizationName?: string;
  legalEntity: boolean;
  personalDataLongName: string;
  personalDataShortName: string;
  position: string;
}

export interface Correspondence_CorrespondenceParty {
  briefTextOrganizationName: string;
  structuralUnit?: Correspondence_StructuralUnit;
  position: string;
  personalDataLongName: string;
  personalDataShortName: string;
}

export interface ViewItemOfOuterProjectArchiveDocument_Correspondence {
  id: number;
  sender: {
    legalEntity: boolean;
    organizationId?: number;
    briefTextOrganizationName?: string;
    position?: string;
    personalDataLongName: string;
    employeeId?: number;
    externalOrganizationStaffId?: number;
    personalDataId?: number;
    externalSubstitutionId?: number;
    personalDataShortName?: number | null;
    structuralUnit?: {
      id: number;
      nameOfStructuralUnit: string;
      structuralUnitType: StructuralUnitTypesForCorrespondenceEnum;
    };
  };
  recipients: {
    organizationId: number;
    briefTextOrganizationName: string;
    position: string;
    personalDataLongName: string;
    personalDataId?: number;
    externalSubstitutionId?: number;
    externalOrganizationStaffId?: number;
    personalDataShortName?: number | null;
    legalEntity?: boolean;
    employeeId?: number;
    structuralUnit?: {
      id?: number;
      nameOfStructuralUnit: string;
      structuralUnitType: StructuralUnitTypesForCorrespondenceEnum;
    };
  }[];
  internalRegistrationNumber: string;
  registrationDate: Date;
  statusForCorrespondence: StatusForCorrespondenceEnum;
  numberAtCounterparty: string;
  dateOfRegistration: Date;
  createdAt: Date;
  confidentially: boolean;
  correspondenceType: CorrespondenceTypeEnum;
  registeredByLongName: string;
  textForTopic?: string;
  correspondenceSubtype: CorrespondenceSubtypeEnum;
  constructionComplexes: {
    briefTextConstructionComplexName: string;
    fullTextConstructionComplexName: string;
  }[];
  contextForTheCorrespondence?: string;
  topicForTheCorrespondence?: string;
  initiatingCorrespondenceRegNumber?: string;
  initiatingCorrespondenceDate?: Date;
  deliveryMethod?: DeliveryMethodEnum;
  storageOrderForOriginal?: StorageOrderForOriginalEnum;
  controlTheResponse?: boolean;
  expectedDateOfTheResponse?: Date;
  initiatingDocumentOutTheSystem?: string;
  attachmentsCount: number;
}
