import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

import IMG from "../../../../../../assets/backgrounds/assignment_dates_visualization_card.png";

export const Wrapper = styled.div`
  height: 124px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
  ${FLEX_ROW_FULL}
`;

export const ImageWrapper = styled.div`
  background-image: url(${IMG});
  height: 92px;
  width: auto;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
`;
export const EllipsisTextWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height:  60px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      cursor: pointer
    },
}
`;
