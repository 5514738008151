import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const NumberBoxWrapper = styled.div<{ $bgColor: string }>`
  ${FLEX_ROW_FULL};
  height: 80px;
  width: 80px;
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: ${({ theme }) => theme.spacing()};
`;

export const ImageWrapper = styled.div`
  ${FLEX_ROW_FULL};
  background-color: ${({ theme }) =>
    hexToRgba(theme.palette.primary.main, 0.08)};
  border-radius: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing()};
  height: 176px;
  width: 100%;
`;
