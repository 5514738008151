import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_FULL } from "ui-kit";

export const HomePageInstrumentsWrapper = styled.div`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(4)};

  padding-top: ${({ theme }) => theme.spacing(6)};
  .dragged_widget {
    ${FLEX_ROW_FULL};
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }
`;
