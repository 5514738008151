import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useSetPlannedDate, useSetPlannedDateValidation } from "api";
import {
  closeDrawerWithData,
  setModal,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer } from "ui-kit";

import { Grid, Stack } from "@mui/material";
import { Button } from "@sbm/ui-components";
import {
  IAssignmentPlannedDatesForm,
  IGanttChartPlannedDate,
  ModalVariants,
} from "@types";

import { VisualizationCard, WarningSection, PlannedDate } from "./components";

interface Props {
  data?: IGanttChartPlannedDate;
  chartId?: string | number;
}

export const AssignmentPlannedDatesForm = ({ data, chartId }: Props) => {
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation("project");
  const minPlannedStartDate = data?.minPlannedStartDate || "";
  const maxPlannedCompletionDate = data?.maxPlannedCompletionDate || "";
  const nameOfLine = data?.nameOfLine || "";
  const status = data?.status || "";
  const lineNumber = data?.lineNumber || "";

  const { drawerWithData } = useAppSelector((state) => state.global);

  const [workingDays, setWorkingDays] = useState<string>("");
  const [calendarDays, setCalendarDays] = useState<string>("");
  const [showPrompt, setShowPrompt] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { dirtyFields },
  } = useForm<IAssignmentPlannedDatesForm>({
    defaultValues: {
      startPlannedDate: "",
      endPlannedDate: "",
      calendarDay: "",
      workingDay: "",
    },
  });
  const fields = useWatch({ control });

  const { startPlannedDate, endPlannedDate, calendarDay, workingDay } = fields;

  useEffect(() => {
    if (data) {
      setValue("startPlannedDate", data.minPlannedStartDate || "");
      setValue("endPlannedDate", data.maxPlannedCompletionDate || "");
      setValue("calendarDay", data.calendarDays ?? 0);
      setValue("workingDay", data.workingDays ?? 0);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (calendarDay) {
      setCalendarDays(`${calendarDay}`);
    }
    if (workingDay) {
      setWorkingDays(`${workingDay}`);
    }
  }, [calendarDay, workingDay]);

  const handleCloseDrawer = () => {
    reset();
    dispatch(closeDrawerWithData());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (
      Object.keys(dirtyFields).length > 0 ||
      (data &&
        data.workingDays &&
        data.workingDays !== workingDay &&
        data.calendarDays &&
        data.calendarDays !== calendarDay)
    ) {
      setShowPrompt(true);
      return;
    }

    handleClose();
  };

  const handleConfirmTransition = () => {
    reset();
    handleClose();
  };

  const { mutate: assignmentPlannedDate } = useSetPlannedDate(
    handleConfirmTransition,
    handleConfirmTransition
  );

  const handleValidateSuccess = (res: boolean) => {
    const requestBody = {
      plannedStartDate: startPlannedDate || "",
      plannedCompletionDate: endPlannedDate || "",
    };

    if (res) {
      assignmentPlannedDate({ id: chartId as number, requestBody });
    } else {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.plannedDateWarningPopUp,
          content: {
            data: {
              requestBody,
              chartId: chartId as number,
              closeDrawer: handleClose,
            },
          },
        })
      );
    }
  };

  const { mutate: validatePlannedDate, isLoading } =
    useSetPlannedDateValidation(handleValidateSuccess);

  useEffect(() => {
    if (minPlannedStartDate && maxPlannedCompletionDate) {
      const startPlannedDateObj = new Date(startPlannedDate ?? "");
      const endPlannedDateObj = new Date(endPlannedDate ?? "");

      if (
        startPlannedDateObj > new Date(minPlannedStartDate) ||
        endPlannedDateObj < new Date(maxPlannedCompletionDate) ||
        startPlannedDateObj >= endPlannedDateObj
      ) {
        setIsDisabledButton(true);
        return;
      } else {
        setIsDisabledButton(false);
      }
    } else {
      if (!startPlannedDate || !endPlannedDate || !calendarDay || !workingDay) {
        setIsDisabledButton(true);
      } else {
        setIsDisabledButton(false);
      }
    }
  }, [
    startPlannedDate,
    endPlannedDate,
    minPlannedStartDate,
    maxPlannedCompletionDate,
    calendarDay,
    workingDay,
  ]);

  const formatDateToISOString = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  };

  const onSubmit = handleSubmit(async (body) => {
    const requestBody = {
      plannedStartDate: formatDateToISOString(new Date(body.startPlannedDate)),
      plannedCompletionDate: formatDateToISOString(
        new Date(body.endPlannedDate)
      ),
    };

    validatePlannedDate({ id: chartId as number, requestBody });
  });

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("assignmentDate.set_planned_dates")}
        open={Boolean(drawerWithData)}
        onClose={handleCancel}
        actions={
          <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="secondary"
              size="large"
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={onSubmit}
              loading={isLoading}
              disabled={isDisabledButton}
            >
              {t("save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit} noValidate ref={ref}>
          <Grid container spacing={4} sx={{ mb: 6 }}>
            <Grid item xs={12}>
              <VisualizationCard
                number={lineNumber}
                title={nameOfLine}
                status={status}
              />
            </Grid>
            <Grid item xs={12}>
              {maxPlannedCompletionDate && minPlannedStartDate && (
                <WarningSection
                  startDate={minPlannedStartDate}
                  endDate={maxPlannedCompletionDate}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <PlannedDate control={control} setValue={setValue} />
            </Grid>
          </Grid>
        </form>
      </Drawer>
    </>
  );
};
