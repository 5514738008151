import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";

import { IconWrapper, Wrapper } from "./styles";

interface WarningSectionProps {
  endDate: Date | string | null;
  startDate: Date | string | null;
}

export const WarningSection = ({ endDate, startDate }: WarningSectionProps) => {
  const theme = useTheme();
  const { t } = useTranslation("project");

  return (
    <Wrapper>
      <IconWrapper>
        <Icon name="Info" size={20} color={theme.palette.secondary.main} />
      </IconWrapper>
      <Stack pl={2}>
        {startDate && endDate ? (
          <Typography variant="caption">
            {t("assignmentDate.warning_text")}
            <Typography variant="caption" fontWeight="bold" sx={{ px: "4px" }}>
              {getLocaleDateFormat(startDate)}
            </Typography>
            {t("assignmentDate.warning_text_middle")}
            <Typography variant="caption" fontWeight="bold" sx={{ px: "4px" }}>
              {getLocaleDateFormat(endDate)}
            </Typography>
          </Typography>
        ) : (
          <Typography variant="caption">
            {t("error_start_must_be_before_end")}
          </Typography>
        )}
      </Stack>
    </Wrapper>
  );
};
