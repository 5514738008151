import styled from "styled-components";
import { FLEX_COLUMN, FLEX_COLUMN_FULL } from "ui-kit";

import { Card } from "@mui/material";

export const ProcessInfoWrapper = styled.div`
  flex-grow: 1;
`;
export const ProcessInfoItem = styled(Card)<{
  $attentionDemanding: boolean;
  $isCompleted: boolean;
}>`
  height: 72px;
  gap: ${({ theme }) => theme.spacing(2)};
  margin: ${({ theme }) => theme.spacing(2, 0)};
  .progressIncrement {
    ${FLEX_COLUMN_FULL};
    height: 72px;
    background-color: ${({ theme, $attentionDemanding, $isCompleted }) =>
      $attentionDemanding
        ? theme.palette.error.main
        : $isCompleted
        ? theme.palette.success.main
        : theme.palette.secondary.main};
  }
`;

export const ProcessInfoCommentSection = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 4)};
  ${FLEX_COLUMN};
  height: 100%;
  justify-content: space-between;
`;

export const ProcessInfoCommentText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
