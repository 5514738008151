import React from "react";

import { Stack } from "@mui/material";

import { MaintenanceMode } from "../MaintenanceMode";

export const TypeFWidgetChart = ({ data }: { data: any }) => {
  return (
    <Stack height="100%" width="100%">
      <MaintenanceMode />
    </Stack>
  );
};
