import { axiosService } from "app";

import { WidgetModuleEnum } from "@types";

export const getWidgets = async (module: WidgetModuleEnum) => {
  const { data } = await axiosService({
    endpoint: `/widget`,
    body: {
      module,
    },
  });

  return data;
};

export const getWidgetsForHomepage = async () => {
  const { data } = await axiosService({
    endpoint: `/widget-on-home-page`,
  });

  return data;
};

export const updateWidgetSwitcher = async (id: number, validity: boolean) => {
  const { data } = await axiosService({
    endpoint: `/widget-on-home-page/${id}`,
    method: "PUT",
    body: {
      validity,
    },
  });

  return data;
};

export const orderingWidgets = async (orderedItemIds: number[]) => {
  const { data } = await axiosService({
    endpoint: `/widget-on-home-page/order`,
    method: "PUT",
    body: {
      orderedItemIds,
    },
  });

  return data;
};
