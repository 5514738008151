import { hexToRgba } from "@sbm/fe-utils";

const PRIMARY_MAIN = "#1B2C41";
const SECONDARY_MAIN = "#D26047";
const ERROR_MAIN = "#BB1E1E";
const WARNING_MAIN = "#ED6C02";
const INFO_MAIN = "#3B608C";
const SUCCESS_MAIN = "#4CAC7E";

const CONTRAST = "#FFF";

const rippleColors = (color: string) => {
  return {
    "4p": hexToRgba(color, 0.04),
    "8p": hexToRgba(color, 0.08),
    "12p": hexToRgba(color, 0.12),
    "30p": hexToRgba(color, 0.3),
    "50p": hexToRgba(color, 0.5),
    "70p": hexToRgba(color, 0.7),
  };
};

export default {
  text: {
    primary: "#1B2C41",
    secondary: hexToRgba("#1B2C41", 0.7),
    disabled: "#1B2C4173",
    ...rippleColors(CONTRAST),
  },
  primary: {
    main: PRIMARY_MAIN,
    dark: "#13202E",
    darkHover: "#0D1620",
    light: "#3B608C",
    contrastText: CONTRAST,
    ...rippleColors(PRIMARY_MAIN),
  },
  secondary: {
    main: SECONDARY_MAIN,
    dark: "#9D422B",
    light: "#E19673",
    contrastText: CONTRAST,
    ...rippleColors(SECONDARY_MAIN),
  },
  error: {
    main: ERROR_MAIN,
    dark: "#901313",
    light: "#E02424",
    contrastText: CONTRAST,

    ...rippleColors(ERROR_MAIN),
  },
  warning: {
    main: WARNING_MAIN,
    dark: "#ce8155",
    light: "#FF9800",
    contrastText: CONTRAST,
    ...rippleColors(WARNING_MAIN),
  },
  info: {
    main: INFO_MAIN,
    dark: "#304E73",
    light: "#5B94D9",
    contrastText: CONTRAST,
    ...rippleColors(INFO_MAIN),
  },
  success: {
    main: SUCCESS_MAIN,
    dark: "#40916A",
    light: "#51B886",
    contrastText: CONTRAST,
    ...rippleColors(SUCCESS_MAIN),
  },
  background: {
    paper: CONTRAST,
    default: "#F3F3F3",
    saCard: "#2b3e54",
  },
  other: {
    divider: "#1B2C411F",
    black: "#000000",
    grey: "#E4E6E8",
  },
  action: {
    active: hexToRgba(PRIMARY_MAIN, 0.54),
    hover: hexToRgba(PRIMARY_MAIN, 0.04),
    selected: hexToRgba(PRIMARY_MAIN, 0.08),
    disabled: hexToRgba(PRIMARY_MAIN, 0.26),
    disabledBackground: hexToRgba(PRIMARY_MAIN, 0.12),
    focus: hexToRgba(PRIMARY_MAIN, 0.12),
  },
  category: {
    action: "#D26047",
    inTheSpotlight: "#6173A0",
    completed: "#E4E6E8",
    default: "#D0D5DD",
    initialBorder: "#5F6B7A",
  },
  gradients: {
    orange: "linear-gradient(270deg, #E19673 0%, #D26047 100%)",
    "red-blue":
      "linear-gradient(90deg, rgba(187, 31, 30, 0.20) 0%, rgba(27, 44, 65, 0.20) 51.68%, rgba(27, 44, 65, 0.20) 101.25%)",
    "green-blue":
      "linear-gradient(90deg, rgba(76, 172, 126, 0.20) 0%, rgba(27, 44, 65, 0.20) 50%, rgba(27, 44, 65, 0.20) 100%)",
    "blue-grey":
      "linear-gradient(180deg, #1B2C41 0%, rgba(27, 44, 65, 0.20) 100%)",
  },
};
