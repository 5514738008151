import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import * as echarts from "echarts";

import { Stack, Typography, useTheme } from "@mui/material";
import { IChartDataTypeA } from "@types";

export const DoughnutChart = ({
  uniqueId,
  data,
}: {
  uniqueId?: number | string;
  data: IChartDataTypeA;
}) => {
  const theme = useTheme();
  const { t } = useTranslation("project");

  const dataAdapter = useMemo(() => {
    if (!data) {
      return [];
    }
    const arr = [];
    arr.push(data.rightParameter);
    return arr;
  }, [data]);

  useEffect(() => {
    const chartDom = document.getElementById(`gaugeChart${uniqueId}`);
    if (!chartDom) return;

    const chart = echarts.init(chartDom);

    const option: echarts.EChartsOption = {
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["40%", "75%"],
          avoidLabelOverlap: false,
          color: theme.palette.category.inTheSpotlight,
          label: {
            show: true,
            position: "center",
            formatter: `${data?.rightParameter?.value}`,
            fontSize: "24px",
            fontWeight: 700,
            color: theme.palette.category.inTheSpotlight,
          },

          labelLine: {
            show: false,
          },
          data: dataAdapter,
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [uniqueId, theme, data, dataAdapter]);

  return (
    <Stack display="flex" justifyContent="center" alignItems="center">
      <div
        id={`gaugeChart${uniqueId}`}
        style={{
          width: "180px",
          height: "180px",
          marginTop: "-8px",
        }}
      />
      {data?.rightParameter?.name && (
        <Typography
          variant="body3"
          fontWeight={600}
          color={theme.palette.text.secondary}
          sx={{ opacity: 0.7, mt: "-12px" }}
        >
          {t(`charts_status.${data.rightParameter?.name}`)}
        </Typography>
      )}
    </Stack>
  );
};
