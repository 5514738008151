import { IContractOrganization } from "./contractDirectories";
import { IPersonalData, IStructuralUnit } from "./organizations";

export interface IApprover {
  approverType?: string;
  status?: string;
  statusForAction?: string;
  queueNumber?: number;
  structuralUnitId?: number;
}

export interface IRoute {
  contractTypeId: number;
  contractSubtypeId: number;
  routeByDefault: boolean;
  id?: number;
}

export interface ICreateStandardRoute {
  organizationId?: number;
  approvers?: IApprover[];
  additionalInformation?: string;
  routes?: IRoute[];
}

export enum EnumApproverType {
  initiator_of_the_contract = "initiator_of_the_contract",
  signatory_of_the_contract = "signatory_of_the_contract",
  project_manager = "project_manager",
  structural_unit = "structural_unit",
}

export enum EnumApproverStatusType {
  under_consideration = "under_consideration",
  approved = "approved",
  rejected = "rejected",
  canceled = "canceled",
}

export enum EnumApproverDocumentStatusType {
  under_consideration = "under_consideration",
  fixing_problems = "fixing_problems",
  agreement_completed = "agreement_completed",
  agreement_interrupted = "agreement_interrupted",
}

export enum ApproverActions {
  start = "start",
  returning = "returning",
  approved = "approved",
  rejected = "rejected",
  under_consideration = "under_consideration",
}

export interface IDefaultRoute {
  id: number;
  typicalApprovalRouteForContractId: number;
  contractTypeId: number;
  contractSubtypeId: number;
  routeByDefault: boolean;
  createdAt: string;
  updatedAt: string;
  typicalApprovalRouteForContract: {
    id: number;
    organizationId: number;
    routeNumber: string;
    dateCreationRoute: string;
    usageScorer: string;
    additionalInformation: string;
    createdAt: string;
    updatedAt: string;
  };
}

export type TAssignApprovalRouteForContract = {
  id: number;
  typicalApprovalRouteForContractId: number;
  contractTypeId: number;
  contractSubtypeId: number;
  routeByDefault: boolean;
  createdAt: string;
  updatedAt: string;
  contractSubtype: {
    id: number;
    nameContractSubtype: string;
    contractTypeId: number;
    additionalInfo: string | null;
    createdAt: string;
    updatedAt: string;
  };
  contractType: {
    id: number;
    nameContractType: string;
    additionalInfo: string | null;
    createdAt: string;
    updatedAt: string;
  };
};
export type TApproverTypicalApprovalRoute = {
  id: number;
  typicalApprovalRouteForContractId: number;
  structuralUnitId: number | null;
  approverType: EnumApproverType;
  positionName?: string;
  queueNumber: number;
  createdAt: string;
  updatedAt: string;
  structuralUnit: IStructuralUnit | null;
  status?: string;
};

export interface IStandardRouteForContract {
  id: number;
  organizationId: number;
  routeNumber: string;
  dateCreationRoute: string;
  usageScorer: number;
  additionalInformation: string;
  createdAt: string;
  updatedAt: string;
  approverTypicalApprovalRoute: TApproverTypicalApprovalRoute[];
  assignApprovalRouteForContract: TAssignApprovalRouteForContract[];
  organization: IContractOrganization;
}

export interface IApproverTypicalApprovalRoute {
  approverMission: string;
  queueNumber: number;
  structuralUnitName?: string;
  positionName: string;
  employeeId?: number;
  externalSubstitutionId?: number;
  amountOfDays?: number;
  personalData: IPersonalData;
  structuralUnit?: IStructuralUnit;
  structuralUnitId?: number;
  organizationName?: string;
  termSingleApproval?: string;
  factDateIterationEnding?: string;
  planDateIterationEnding?: string;
  status?: {
    singleApprovalId: number;
    statusSingleApproval: string;
    comment: string;
  };
  longName?: string;
}

export interface IApprovalRoute {
  id: number;
  routeNumber: string;
  routeByDefault: boolean;
  additionalInformation?: string;
  approvers?: IApproverTypicalApprovalRoute[];
  planDateIterationEnding?: string;
  currentQueueNumber?: number;
  statusApprovalProcessInternalObject?: EnumApproverDocumentStatusType;
  dateApprovalBeginning?: string | null;
  planDateApprovalApprovalEnding?: string | null;
  factDateApprovalApprovalEnding?: string | null;
}

export enum TypeOfObjectInternalApprovalEnum {
  contract = "contract",
  supplementaryAgreement = "supplementary_agreement",
  oad = "oad",
  outgoingDocument = "outgoing_document",
  serviceNote = "service_note",
  workplan = "workplan",
  protocol = "protocol",
  powerOfAttorney = "power_of_attorney",
  manual = "manual",
}

export interface IConfirmRoute {
  typicalApprovalRouteForContractId?: number;
  contractId?: number;
  supplementaryAgreementId?: number;
  outgoingDocuementId?: number;
  serviceNoteId?: number;
  approvers?:
    | Partial<IApproverTypicalApprovalRoute>
    | {
        queueNumber: number;
        amountOfDays: number;
        employeeId: number | undefined;
      }[];
  type: TypeOfObjectInternalApprovalEnum;
}

export interface IAddApproverForm {
  employeeId?: number;
  objectInternalApprovalProcessId?: number;
  termSingleApproval: number;
  queueNumber?: number;
  organizationId?: number;
  positionId?: number;
}

export interface IRegisterSupportRequestPayload {
  id: number;
  employeeId?: number;
  externalSubstitutionId?: number;
  textOfTheTask?: string;
  scheduledDateOfExecution: string;
  calendarDaysForExecution: number;
  workingDaysForExecution: number;
  controlDate: string | Date;
  controlTime: string | Date;
}

export interface IContractStatusChanging {
  singleApprovalId: number;
  comment: string;
  status?: string;
}

export interface IContractFileEditing {
  documentNumber?: string;
  additionalInformation?: string;
}

export enum SingleApprovalOverdueStatusEnum {
  normal = "normal",
  closeToOverdue = "close_to_overdue",
  overdue = "overdue",
}
