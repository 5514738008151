import styled from "styled-components";

import { hexToRgba } from "@sbm/fe-utils";

const COLUMN_SIZE = 56;
const MAX_ROWS_TO_SHOW_IN_TABLE = 14;
export const Wrapper = styled.div`
  // Cells
  .TaskItemClassName {
    fill: ${({ theme }) => theme.palette.primary.contrastText};
  }

  .TaskItemClassName:nth-child(even) {
    fill: ${({ theme }) => theme.palette.primary.contrastText};
  }

  //  Header
  ._35nLX {
    fill: ${({ theme }) => theme.palette.background.default};
  }

  // Text
  ._barLabel_pu5db_1 {
    display: none;
  }

  ._ganttTableRoot_v4xjz_1,
  ._ganttTaskRoot_1sr1d_1 {
    height: ${MAX_ROWS_TO_SHOW_IN_TABLE * COLUMN_SIZE}px;
    overflow-y: auto;
  }

  // Project bar
  ._projectBackground_19i2s_6 {
    opacity: 0;
  }

  ._ganttTaskContent_1sr1d_41 {
    svg {
      background-color: ${({ theme }) =>
        hexToRgba(theme.palette.primary.contrastText, 0.1)}!important;
    }
  }

  .grid {
    .gridBody {
      .today > rect {
        fill: ${({ theme }) => hexToRgba(theme.palette.secondary.main, 0.08)};
      }

      .rowLines {
        ._3rUKi:nth-child(even) {
          display: none;
        }
      }
    }
  }
`;
