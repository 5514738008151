import React, { SyntheticEvent } from "react";
import { Autocomplete, Chip, FormControl, TextField } from "@mui/material";
import { DefaultTFuncReturn } from "i18next";
import { useDebounce } from "./hooks";
import { AvatarWrapper, ChipAvatar, Wrapper } from "./styles";

type TSize = "small" | "medium";

export interface FormAutocompleteProps {
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  multiple?: boolean;
  renderChip?: boolean;
  error?: boolean;
  freeSolo?: boolean;
  label?: string | DefaultTFuncReturn;
  id?: string;
  inputValue: string;
  size?: TSize;
  values?: { title: string }[];
  defaultSelected?: any;
  helperText?: string;
  className?: string;
  color?: "error" | "info" | "primary" | "secondary" | "success" | "warning";
  variant?: "standard" | "filled" | "outlined";
  onInputChange?: (val: string, reason: string) => void;
  onFetchDataSuccess?: (arg: any) => void;
  fetchData?: (search: string) => Promise<any>;
  disabledOptions?: (number | undefined)[];
  onChange?: any;
  limitTags?: number;
  maxLength?: number;
  /**
   * Need to set this prop to true if autocomplete default setup is not working
   */
  hasDefaultLabel?: boolean;
  showAvatar?: boolean;
  noOptionsText?: React.ReactNode;
}

export const FormAutocomplete = ({
  label,
  values,
  inputValue,
  freeSolo = true,
  required = false,
  disabled = false,
  readOnly = false,
  fullWidth = true,
  multiple = false,
  error,
  size = "medium",
  defaultSelected,
  variant = "outlined",
  color = "secondary",
  onInputChange,
  onFetchDataSuccess,
  fetchData,
  disabledOptions,
  onChange,
  limitTags,
  showAvatar = false,
  noOptionsText,
  maxLength,
}: FormAutocompleteProps) => {
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<({ title: string; id?: number; displayLabel?: JSX.Element } | string)[]>(
    []
  );

  const debouncedValue = useDebounce(inputValue);

  const handleInputChange = React.useCallback(
    async (e: SyntheticEvent, newValue: string, reason: string) => {
      onInputChange?.(newValue, reason);
    },
    [onInputChange]
  );

  const handleOptionDisabled = (option: string | { title: string; id?: number; displayLabel?: JSX.Element }) => {
    if (!disabledOptions || !disabledOptions.length) return false;

    if (typeof option !== "string" && option.id) {
      return disabledOptions.includes(option.id);
    }

    return false;
  };

  React.useEffect(() => {
    if (values) setOptions(values);
  }, [values]);

  React.useEffect(() => {
    if ((debouncedValue || debouncedValue === "") && fetchData) {
      setLoading(true);

      fetchData(debouncedValue).then((resp: { title: string }[]) => {
        setOptions(resp);
        onFetchDataSuccess?.(resp);
      });

      setLoading(false);
    }
  }, [debouncedValue, fetchData, onFetchDataSuccess]);

  return (
    <Wrapper>
      <FormControl fullWidth={fullWidth} disabled={disabled} required={required} error={error}>
        <Autocomplete
          autoSelect={false}
          handleHomeEndKeys
          size={size}
          freeSolo={freeSolo}
          loading={loading}
          readOnly={readOnly}
          disabled={disabled}
          id="select-assignee__input"
          value={defaultSelected}
          limitTags={limitTags ? limitTags : multiple ? 2 : undefined}
          options={options}
          multiple={multiple}
          inputValue={defaultSelected && typeof defaultSelected === "string" ? defaultSelected : inputValue}
          onChange={onChange}
          filterOptions={(options) => options}
          isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
          onInputChange={handleInputChange}
          getOptionLabel={(option) => {
            if (typeof option !== "string" && "displayLabel" in option) {
              return option.title;
            }
            return typeof option === "string" ? option : option?.title;
          }}
          getOptionDisabled={handleOptionDisabled}
          noOptionsText={noOptionsText || null}
          renderOption={(props, option) => {
            if (typeof option !== "string" && option.displayLabel) {
              return (
                <li {...props} key={props.id}>
                  {option.displayLabel}
                </li>
              );
            }
            return (
              <li {...props} key={props.id}>
                {typeof option === "string" ? option : option.title}
              </li>
            );
          }}
          renderTags={
            showAvatar
              ? (value: (string | { title: string; id?: number; gender?: { avatar: string } })[], getTagProps) =>
                  value.map(
                    (option: string | { title: string; id?: number; gender?: { avatar: string } }, index: number) => {
                      if (typeof option === "string") {
                        return (
                          <div className="chip_with_avatar" key={index}>
                            <Chip label={option} {...getTagProps({ index })} />
                          </div>
                        );
                      } else {
                        return (
                          <div className="chip_with_avatar" key={index}>
                            <Chip
                              label={
                                <ChipAvatar>
                                  <AvatarWrapper $avatar={option?.gender?.avatar} />
                                  <div>{option.title}</div>
                                </ChipAvatar>
                              }
                              {...getTagProps({ index })}
                            />
                          </div>
                        );
                      }
                    }
                  )
              : undefined
          }
          renderInput={(params) => (
            <TextField
              variant={variant}
              color={color}
              label={label || ""}
              required={required}
              error={error}
              {...params}
              inputProps={{
                ...params.inputProps,
                maxLength,
              }}
            />
          )}
        />
      </FormControl>
    </Wrapper>
  );
};
