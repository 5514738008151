import React, { useEffect, useRef, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useCreateProgressInfo,
  useMutateGanttChartLineAttentionDemanding,
} from "api";
import {
  closeDrawerWithData,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer } from "ui-kit";

import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { Button, Input } from "@sbm/ui-components";
import { IGanttChartLineData, IGanttChartProgress } from "@types";

import { ProgressInfo, ProgressSlider, VisualizationCard } from "./components";
import {
  ContentWrapper,
  ProgressSliderWrapper,
  SwitcherWrapper,
} from "./styles";

interface Props {
  lineId: number;
  data: IGanttChartLineData;
}

export const GanttChartProgressForm = ({ lineId, data }: Props) => {
  const { t } = useTranslation("project");
  const dispatch = useAppDispatch();

  const { drawerWithData } = useAppSelector((state) => state.global);
  const [showPrompt, setShowPrompt] = useState(false);
  const [localAttentionDemanding, setLocalAttentionDemanding] = useState(
    data.attentionDemanding || false
  );
  const form = useForm<IGanttChartProgress>({
    defaultValues: {
      progress: data.totalProgress || 0,
      attentionDemanding: data.attentionDemanding,
      progressIncrement: 0,
    },
  });

  const {
    reset,
    handleSubmit,
    setValue,
    control,
    register,
    formState: { dirtyFields },
  } = form;

  const { progress, comment, progressIncrement } = useWatch({ control });

  const totalProgressValue = data?.totalProgress
    ? Number(data.totalProgress)
    : 0;

  const { mutate: mutateAttentionDemanding } =
    useMutateGanttChartLineAttentionDemanding();

  const handleConfirmTransition = () => {
    setValue("comment", "");
    reset();
  };

  const { mutate: createProgressInfo } = useCreateProgressInfo(
    handleConfirmTransition
  );

  const handleCloseDrawer = () => {
    dispatch(closeDrawerWithData());
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (Object.keys(dirtyFields).length > 0 || progressIncrement) {
      setShowPrompt(true);
    } else {
      handleCloseDrawer();
    }
  };

  const switchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleAttentionDemandingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    setValue("attentionDemanding", newValue);
    setLocalAttentionDemanding(newValue);

    if (switchTimeoutRef.current) {
      clearTimeout(switchTimeoutRef.current);
    }

    switchTimeoutRef.current = setTimeout(() => {
      mutateAttentionDemanding({
        id: lineId,
        attentionDemanding: newValue,
      });
    }, 400);
  };

  const handleChangeProcess = (text: number) => {
    if (
      text + totalProgressValue > 100 ||
      text < 0 ||
      text + totalProgressValue < 0
    )
      return;
    setValue("progressIncrement", text);
    setValue("progress", Number(progress) + text);
  };

  const handleProgressIncrement = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.trim();

    if (/^\d+$/.test(value)) {
      value = String(Number(value));
      handleChangeProcess(Number(value));
    } else if (value === "") {
      setValue("progressIncrement", 0);
    }
  };

  const onSubmit = handleSubmit(async () => {
    const requestBody = {
      progressIncrement: progressIncrement || 0,
      comment: comment || "",
    };
    createProgressInfo({ id: lineId, requestBody });
  });

  useEffect(() => {
    setValue("attentionDemanding", data.attentionDemanding);
  }, [data, setValue]);

  const isCompleted = useMemo(() => {
    return data.totalProgress === 100;
  }, [data]);

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("increase_progress")}
        open={Boolean(drawerWithData)}
        onClose={handleCancel}
      >
        <form noValidate onSubmit={onSubmit}>
          <Stack position="relative" top={-12}>
            <Stack position="sticky" top={-12}>
              <VisualizationCard
                totalProgress={data?.totalProgress}
                name={data?.nameOfLine || ""}
                startDate={data?.plannedStartDate}
                endDate={data?.plannedCompletionDate}
                isCompleted={isCompleted}
              />
              <SwitcherWrapper $isCompleted={isCompleted}>
                {isCompleted ? null : (
                  <Stack
                    display="flex"
                    justifyContent="center"
                    flexDirection="row"
                  >
                    <Typography
                      variant="h7"
                      fontWeight={700}
                      color="text.primary"
                      mr={2}
                      sx={{ opacity: 0.45 }}
                    >
                      {t("progress").toUpperCase()}
                    </Typography>
                  </Stack>
                )}
                <FormControlLabel
                  label={t("requires_attention")}
                  control={
                    <Switch
                      color="secondary"
                      {...register("attentionDemanding")}
                      checked={localAttentionDemanding}
                      onChange={handleAttentionDemandingChange}
                    />
                  }
                />
              </SwitcherWrapper>
              {isCompleted ? null : (
                <ContentWrapper>
                  <ProgressSliderWrapper>
                    <Stack spacing={3} mt={4}>
                      <ProgressSlider
                        control={control}
                        setValue={setValue}
                        totalProgress={data.totalProgress}
                        attentionDemanding={data.attentionDemanding}
                      />

                      <Stack gap={4}>
                        <Input
                          label={`${t("add_progress")} (%)`}
                          variant="outlined"
                          type="text"
                          value={progressIncrement}
                          size="medium"
                          onChange={handleProgressIncrement}
                        />

                        <Input
                          label={t("comment")}
                          variant="outlined"
                          size="medium"
                          required
                          multiline
                          maxRows={2}
                          minRows={2}
                          disabled={totalProgressValue === 100}
                          maxLength={1500}
                          {...register("comment")}
                        />
                      </Stack>
                      <Stack
                        flexDirection="row"
                        justifyContent="flex-end"
                        gap={4}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          disabled={!comment}
                          onClick={onSubmit}
                        >
                          {t("add")}
                        </Button>
                      </Stack>
                    </Stack>
                  </ProgressSliderWrapper>
                </ContentWrapper>
              )}
            </Stack>
            <ProgressInfo data={data} isCompleted={isCompleted} />
          </Stack>
        </form>
      </Drawer>
    </>
  );
};
