import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  border-radius: ${({ theme }) => theme.spacing(4)};
  height: 100%;
`;

export const IconWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 4, 0, 0)};
  border-right: 2px solid ${({ theme }) => theme.palette.secondary.main};
  ${FLEX_ROW_ALIGN_CENTER};
  height: 100%;
`;
