import React, { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetTemplateForBlankDocument } from "api";
import {
  axiosService,
  closeDrawer,
  setModal,
  TransitionPrompt,
  UI_CONFIG,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import { Stack, useTheme } from "@mui/material";
import { Button, FormSelect, Input } from "@sbm/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import {
  DrawerTypes,
  DrawerVariantsEnum,
  IGenerateDocumentForm,
  ModalVariants,
} from "@types";

export const GenerateFileDrawer = () => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation("correspondence");
  const queryClient = useQueryClient();

  const { drawer } = useAppSelector((state) => state.global);

  const [loading, setLoading] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm<IGenerateDocumentForm>();

  const { templateId, fileName, textOfTheDocument } =
    useWatch<IGenerateDocumentForm>({ control });

  const { data: templatesForBlankDocument = [], isLoading } =
    useGetTemplateForBlankDocument();

  const templateOptions = useMemo(() => {
    if (isLoading || !templatesForBlankDocument) return [];

    return templatesForBlankDocument.map((item) => ({
      option: item.nameOfBlankForUser,
      value: item.id,
      templateLink: item.templateLink,
    }));
  }, [isLoading, templatesForBlankDocument]);

  const selectedTemplateLink = useMemo(() => {
    if (!templateOptions.length || !templateId) return;

    const selected = templateOptions.find((i) => i.value === templateId);

    if (!selected) return;

    return selected.templateLink;
  }, [templateOptions, templateId]);

  const handleClose = () => {
    reset();
    dispatch(closeDrawer());
    handleClosePrompt();
  };

  const handleGenerateFile = async () => {
    if (!id) return;

    setLoading(true);
    const { data } = await axiosService({
      endpoint: `correspondence/${id}/generate/pdf`,
      method: "POST",
      responseType: "blob",
      body: {
        fileName,
        templateId,
        textOfTheDocument,
      },
    });

    const blobUrl = URL.createObjectURL(data);

    setLoading(false);
    window.open(blobUrl, "_blank");
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (Object.values(dirtyFields).length > 0) {
      setShowPrompt(true);
      return;
    }

    handleClose();
  };

  const handlePreviewTemplate = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.previewBlankTemplate,
        content: {
          pdfUrl: selectedTemplateLink,
        },
      })
    );
  };

  const onSubmit = handleSubmit(async (body) => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.generateOutgoingDocumentFile,
        content: {
          data: {
            onSuccess: async () => {
              await queryClient.invalidateQueries(["get_document_by_id"]);
              await queryClient.invalidateQueries([
                "get_correspondence_files_by_id",
              ]);

              handleClose();
            },
            correspondenceId: id,
            requestPayload: body,
          },
        },
      })
    );
  });

  if (drawer !== DrawerVariantsEnum.generateFileForOutgoingDocument)
    return null;

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleClose}
        />
      )}

      <Drawer
        anchor="right"
        open={Boolean(drawer)}
        onClose={handleCancel}
        resizable={false}
        width={UI_CONFIG.rightDrawerWidth}
        type={DrawerTypes.createLetterDrawer}
        title={t("generate_file_drawer.file_generation")}
        actions={
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap={4}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              color="secondary"
              size="large"
              disabled={!fileName || !templateId || !textOfTheDocument}
              onClick={onSubmit}
            >
              {t("save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit} noValidate>
          <Stack justifyContent="flex-start" gap={6}>
            <Input
              required
              label={t("generate_file_drawer.file_name")}
              variant="outlined"
              size="medium"
              maxLength={300}
              {...register("fileName")}
            />

            <Stack flexDirection="row" alignItems="center" gap={4}>
              <FormSelect
                required
                fullWidth
                label={t("generate_file_drawer.template")}
                name="templateId"
                control={control}
                values={templateOptions}
              />

              <Button
                size="large"
                color="secondary"
                disabled={!templateId}
                onClick={handlePreviewTemplate}
                sx={{
                  minWidth: 40,
                  width: 40,
                  ".MuiButton-startIcon": { ml: 2 },
                  "&.Mui-disabled": {
                    backgroundColor: !templateId
                      ? palette.action.disabled
                      : palette.secondary.main,
                  },
                }}
                startIcon={
                  <Icon name="FileSearch" className="cursor-pointer" />
                }
              ></Button>
            </Stack>

            <Input
              required
              label={t("generate_file_drawer.text")}
              variant="outlined"
              size="medium"
              maxLength={1500}
              multiline
              linesCount={3}
              {...register("textOfTheDocument")}
            />

            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={handleGenerateFile}
              loading={loading}
              startIcon={
                <Icon
                  name="FileCog"
                  color={palette.secondary.main}
                  disabled={!fileName || !templateId || !textOfTheDocument}
                  className="cursor-pointer"
                />
              }
              disabled={
                !fileName || !templateId || !textOfTheDocument || loading
              }
            >
              {t("generate_file_drawer.generate_button")}
            </Button>
          </Stack>
        </form>
      </Drawer>
    </>
  );
};
