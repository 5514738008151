import React, { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCreateRequestInSBM } from "api";
import {
  closeDrawer,
  resetTaskCreationStep,
  setChangeTaskCreationStep,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import {
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, Input } from "@sbm/ui-components";
import { ICreateFeedbackRequest } from "@types";

import { orderTextMaxLength } from "./constants";
import { FeedbackStepper } from "./FeedbackStepper";
import { createRequestBodyInSBM } from "./helpers";
import { AttachmentsForFeedback } from "./steps/Step3AttachmentAndSending/AttachmentForFeedback";
import { CardTitleWrapper, CardWrapper } from "./styles";

export const CreateFeedbackRequestForm = () => {
  const { t } = useTranslation("tasks");
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { taskCreationStep } = useAppSelector((state) => state.task);

  const { drawer } = useAppSelector((state) => state.global);

  const [showPrompt, setShowPrompt] = useState(false);

  const form = useForm<ICreateFeedbackRequest>({
    defaultValues: {
      highPriority: false,
      reportRequired: false,
      personalControl: false,
    },
  });

  const { reset, handleSubmit, control, register } = form;
  const {
    personalControl,
    reportRequired,
    highPriority,
    textOfTheTask,
    files,
    links,
  } = useWatch({
    control,
  });

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCloseDrawer = () => {
    reset();
    dispatch(resetTaskCreationStep());
    dispatch(closeDrawer());
    handleClosePrompt();
  };

  const handleConfirmTransition = () => {
    reset();
    dispatch(closeDrawer());
    dispatch(resetTaskCreationStep());
    handleClosePrompt();
  };

  const handleClose = () => {
    handleConfirmTransition();
  };

  const handleCancel = () => {
    if (
      personalControl ||
      reportRequired ||
      highPriority ||
      textOfTheTask ||
      files?.length ||
      links?.length
    ) {
      setShowPrompt(true);
      return;
    }

    reset();
    handleClose();
  };

  const { mutate: createRequest, isLoading } = useCreateRequestInSBM(
    handleConfirmTransition,
    handleConfirmTransition
  );

  const onSubmit = handleSubmit(async (data: ICreateFeedbackRequest) => {
    const requestBody = createRequestBodyInSBM(data);
    createRequest(requestBody);
  });

  const handleNextStep = () => {
    dispatch(setChangeTaskCreationStep(1));
  };

  const handleBackStep = () => {
    dispatch(setChangeTaskCreationStep(-1));
  };

  const renderFormContent = () => {
    switch (taskCreationStep) {
      case 0:
        return (
          <CardWrapper>
            <CardTitleWrapper>
              <Typography variant="h9_semiBold" color="text.disabled">
                {t("feedback.request")}
              </Typography>
            </CardTitleWrapper>
            <Input
              label={t("feedback.request_text")}
              variant="outlined"
              required
              maxLength={orderTextMaxLength}
              size="medium"
              multiline
              maxRows={10}
              linesCount={5}
              {...register("textOfTheTask")}
            />
            <Stack ml={3} mt={1}>
              <Typography variant="body3" color="text.disabled">
                {t("feedback.request_text_helper")}
              </Typography>
            </Stack>

            <Grid container gap={2} mt={6}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={t("feedback.personal_control")}
                  control={
                    <Switch
                      color="secondary"
                      {...register("personalControl")}
                      checked={personalControl}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={t("higher_priority")}
                  control={
                    <Switch
                      color="secondary"
                      {...register("highPriority")}
                      checked={highPriority}
                    />
                  }
                />
              </Grid>
            </Grid>
          </CardWrapper>
        );
      case 1:
        return <AttachmentsForFeedback form={form} />;
      default:
        return null;
    }
  };

  const isNextButtonDisabled = useMemo(() => {
    if (taskCreationStep === 0) {
      return !textOfTheTask?.length;
    }
  }, [taskCreationStep, textOfTheTask]);

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("feedback.create_request")}
        open={Boolean(drawer)}
        onClose={handleCancel}
        actions={
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              {!taskCreationStep ? null : (
                <Button
                  variant="text"
                  size="medium"
                  color="secondary"
                  sx={{ fontSize: "15px" }}
                  onClick={handleBackStep}
                  startIcon={
                    <Icon
                      name="ArrowLeft"
                      color={theme.palette.secondary.main}
                    />
                  }
                >
                  {t("back")}
                </Button>
              )}
            </Stack>
            <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                color="secondary"
                size="large"
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={isNextButtonDisabled}
                loading={isLoading}
                onClick={taskCreationStep !== 1 ? handleNextStep : onSubmit}
              >
                {taskCreationStep === 1 ? t("feedback.create") : t("next")}
              </Button>
            </Stack>
          </Stack>
        }
      >
        <Stack position="relative">
          <FeedbackStepper />

          <form noValidate style={{ marginTop: "100px" }}>
            {renderFormContent()}
          </form>
        </Stack>
      </Drawer>
    </>
  );
};
