import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";

import { EllipsisTextWrapper, ImageWrapper, Wrapper } from "./styles";

interface VisualizationCardProps {
  title: string;
  number: number | string;
  status: string;
}

export const VisualizationCard = ({
  title,
  number,
  status,
}: VisualizationCardProps) => {
  const { t } = useTranslation("project");

  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={8}>
          <Grid container height="100%">
            <Stack
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
            >
              <Stack>
                <Tooltip arrow placement="top" title={`${number}. ${title}`}>
                  <EllipsisTextWrapper>
                    <Typography
                      variant="subtitle1"
                      fontWeight={700}
                      lineHeight={"20px"}
                      color="primary.contrastText"
                    >
                      {`${number} ${title}`}
                    </Typography>
                  </EllipsisTextWrapper>
                </Tooltip>
              </Stack>
              <Chip
                variant="filled"
                label={t(`gantt_chart_status.${status}`)}
                className={`status-chip status-chip-${status}`}
                sx={{
                  ".MuiChip-label": {
                    display: "block",
                  },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <ImageWrapper />
        </Grid>
      </Grid>
    </Wrapper>
  );
};
