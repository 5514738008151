import React, { useEffect, useState } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { fetchWorkingDays } from "api";
import { useDebounce } from "app";
import { DatePicker } from "ui-kit";

import { Grid, Typography } from "@mui/material";
import { Input } from "@sbm/ui-components";
import { IAssignmentPlannedDatesForm } from "@types";

export interface IWorkingDateData {
  date: string;
  endDate: string;
  startDate: string;
  workingDays: number;
  calendarDays: number;
}

interface Props {
  control: Control<IAssignmentPlannedDatesForm>;
  setValue: UseFormSetValue<IAssignmentPlannedDatesForm>;
}

export const PlannedDate = ({ control, setValue }: Props) => {
  const { t } = useTranslation("project");
  const fields = useWatch({ control });

  const { startPlannedDate, endPlannedDate, calendarDay, workingDay } = fields;

  const [workingDays, setWorkingDays] = useState<string | number>(
    workingDay || ""
  );
  const [calendarDays, setCalendarDays] = useState<string | number>(
    calendarDay || ""
  );

  useEffect(() => {
    if (calendarDay) {
      setValue("calendarDay", calendarDay);
      setCalendarDays(`${calendarDay}`);
    }
    if (workingDay) {
      setValue("workingDay", workingDay);
      setWorkingDays(`${workingDay}`);
    }
  }, [calendarDay, workingDay, setValue]);

  const debouncedWorkingDays = useDebounce(workingDays, 1000);
  const debouncedCalendarDays = useDebounce(calendarDays, 1000);

  const handleWorkingDateChanged = (data: IWorkingDateData) => {
    setValue("workingDay", data.workingDays);
    setValue("calendarDay", data.calendarDays);
  };

  const setTimeToMidnightUTC = (date: Date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    ).toISOString();
  };

  const handleStartDateChanged = async (newDate: Date) => {
    const requestParams: Record<string, any> = {};

    setValue("startPlannedDate", setTimeToMidnightUTC(newDate), {
      shouldDirty: true,
    });
    requestParams.startDate = newDate.toISOString();
    if (endPlannedDate)
      requestParams.date = new Date(endPlannedDate).toISOString();
    if (debouncedCalendarDays && !endPlannedDate)
      requestParams.calendarDays = +debouncedCalendarDays;
    if (debouncedWorkingDays && !endPlannedDate)
      requestParams.workingDays = +debouncedWorkingDays;

    if (Object.keys(requestParams).length > 1) {
      const result = await fetchWorkingDays(requestParams);
      setValue("calendarDay", result.calendarDays);
      setValue("workingDay", result.workingDays);
    }
  };

  useEffect(() => {
    if (
      Number(debouncedCalendarDays) !== calendarDay &&
      (startPlannedDate || endPlannedDate)
    ) {
      const requestParams: Record<string, any> = {
        calendarDays: +debouncedCalendarDays,
      };

      if (startPlannedDate) {
        requestParams.startDate =
          startPlannedDate instanceof Date
            ? setTimeToMidnightUTC(startPlannedDate)
            : setTimeToMidnightUTC(new Date(startPlannedDate));
      } else if (endPlannedDate) {
        requestParams.endDate =
          endPlannedDate instanceof Date
            ? setTimeToMidnightUTC(endPlannedDate)
            : setTimeToMidnightUTC(new Date(endPlannedDate));
      }

      fetchWorkingDays(requestParams).then((result) => {
        handleWorkingDateChanged(result);
        setWorkingDays(result.workingDays);
        if (startPlannedDate) {
          setValue(
            "endPlannedDate",
            setTimeToMidnightUTC(new Date(result.date))
          );
        } else if (endPlannedDate) {
          setValue(
            "startPlannedDate",
            setTimeToMidnightUTC(new Date(result.date))
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCalendarDays]);

  useEffect(() => {
    if (
      Number(debouncedWorkingDays) !== workingDay &&
      (startPlannedDate || endPlannedDate)
    ) {
      const requestParams: Record<string, any> = {
        workingDays: +debouncedWorkingDays,
      };

      if (startPlannedDate) {
        requestParams.startDate =
          startPlannedDate instanceof Date
            ? setTimeToMidnightUTC(startPlannedDate)
            : setTimeToMidnightUTC(new Date(startPlannedDate));
      } else if (endPlannedDate) {
        requestParams.endDate =
          endPlannedDate instanceof Date
            ? setTimeToMidnightUTC(endPlannedDate)
            : setTimeToMidnightUTC(new Date(endPlannedDate));
      }

      fetchWorkingDays(requestParams).then((result) => {
        handleWorkingDateChanged(result);
        setCalendarDays(result.workingDays);
        if (startPlannedDate) {
          setValue("endPlannedDate", new Date(result.date).toISOString());
        } else if (endPlannedDate) {
          setValue("startPlannedDate", new Date(result.date).toISOString());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedWorkingDays]);

  const handleEndDateChanged = async (newDate: Date) => {
    const requestParams: Record<string, any> = {};
    requestParams.date = newDate.toISOString();
    setValue("endPlannedDate", setTimeToMidnightUTC(newDate), {
      shouldDirty: true,
    });
    if (startPlannedDate)
      requestParams.startDate = new Date(startPlannedDate).toISOString();
    if (debouncedCalendarDays && !startPlannedDate)
      requestParams.calendarDays = +debouncedCalendarDays;
    if (debouncedWorkingDays && !startPlannedDate)
      requestParams.workingDays = +debouncedWorkingDays;
    if (Object.keys(requestParams).length > 1) {
      const result = await fetchWorkingDays(requestParams);
      setValue("calendarDay", result.calendarDays);
      setValue("workingDay", result.workingDays);
    }
  };

  return (
    <Grid container spacing={4} sx={{ mb: 6 }}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          fontWeight={700}
          color="primary.main"
          mb={3}
        >
          {t("assignmentDate.start_date")}
        </Typography>
        <DatePicker
          label={t("assignmentDate.date")}
          variant="outlined"
          size="medium"
          required
          value={startPlannedDate ? new Date(startPlannedDate) : null}
          onDateChange={handleStartDateChanged}
          minDate={new Date("2023-08-10")}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          fontWeight={700}
          color="primary.main"
          mb={3}
        >
          {t("assignmentDate.duration")}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Input
              label={t("assignmentDate.working_days")}
              variant="outlined"
              type="number"
              required
              size="medium"
              value={workingDays}
              disabled={!startPlannedDate}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/^0+/, "");
                value = value === "" ? "0" : value;
                setWorkingDays(value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              label={t("assignmentDate.calendar_days")}
              variant="outlined"
              type="number"
              required
              size="medium"
              value={calendarDays}
              disabled={!startPlannedDate}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                let value = e.target.value.replace(/^0+/, "");
                value = value === "" ? "0" : value;
                setCalendarDays(value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          fontWeight={700}
          color="primary.main"
          mb={3}
        >
          {t("assignmentDate.end_date")}
        </Typography>
        <DatePicker
          label={t("assignmentDate.date")}
          variant="outlined"
          required
          size="medium"
          value={endPlannedDate ? new Date(endPlannedDate) : null}
          minDate={startPlannedDate ? new Date(startPlannedDate) : undefined}
          onDateChange={handleEndDateChanged}
        />
      </Grid>
    </Grid>
  );
};
